<div #containerElement class="ax-table__container" (scroll)="onScrolled($event)">
    <table #tableElement class="ax-table">
        <colgroup>
            @if (hasMultiSelectEnabled) {
                <col class="ax-table__col__multi-select">
            }

            @for (column of columns; track column.property_name) {
                <col>
            }

            @if (hasButtonsSet()) {
                <col>
            }
        </colgroup>
        <thead>
            @if (hasMultiSelectEnabled) {
                <th>
                    <ax-checkbox [size]="'small'" [isChecked]="hasAllRowsChecked" [stopPropagationOnChange]="false" (click)="selectAllRows($event)"></ax-checkbox>
                </th>
            }

            @for (column of columns; track column.property_name) {
                <th (click)="onSort(column)" [ngClass]="setColumnCaptionClasses(column)">
                    <span>{{column.caption}}</span>
                    @if (column.is_sortable) {
                        <i class="ax-table__sort-icon fa-duotone fa-sort" [ngClass]="setSortDirectionClass(column)"></i>
                    }
                </th>
            }
            @if (hasButtonsSet()) {
                <th></th>
            }
        </thead>
        @if ((data && data.length > 0) && loaderDelay < 25) {
            <tbody>
                @for (entity of data; track entity; let dataIndex = $index) {
                    <tr (click)="selectRow(dataIndex, $event)" [ngClass]="setRowClasses(dataIndex)">
                        @if (hasMultiSelectEnabled) {
                            <td>
                                <ax-checkbox [size]="'small'" [isChecked]="isSelectedRow(dataIndex)" [stopPropagationOnChange]="false"></ax-checkbox>
                            </td>
                        }
                        @for (column of columns; track column.property_name) {
                            <td attr.data-propertyname="{{column.property_name}}" attr.data-index="{{dataIndex}}">
                                @switch (column.type) {
                                    @case (tablePropertyType.Default) {
                                        <span (click)="navigateTo(dataIndex, column, $event)" [ngClass]="setCellNavigatableClass(column)">{{entity[column.property_name]}}</span>
                                    }

                                    @case (tablePropertyType.DateTime) {
                                        <span>{{entity[column.property_name] | datetime}}</span>
                                    }

                                    @case (tablePropertyType.Date) {
                                        <span>{{entity[column.property_name] | date}}</span>
                                    }

                                    @case (tablePropertyType.Time) {
                                        <span>{{entity[column.property_name] | time}}</span>
                                    }

                                    @case (tablePropertyType.TimeAgo) {
                                        <span>{{entity[column.property_name] | timeago}}</span>
                                    }

                                    @case (tablePropertyType.Boolean) {
                                        <div class="ax-table__cell__boolean">
                                            @if (getColumnOptionValueAsBoolean(column, tableColumnOptionKey.DisplayBooleanAsIcon)) {
                                                <i [ngClass]="setCellBooleanIconClasses(entity[column.property_name])" class="fa-solid ax-table__cell__boolean__icon"></i>
                                            }

                                            @if (!getColumnOptionValueAsBoolean(column, tableColumnOptionKey.DisplayBooleanAsIcon) && entity[column.property_name]) {
                                                <span>{{getColumnOptionValueAsString(column, tableColumnOptionKey.BooleanDisplayTrueValue)}}</span>
                                            }

                                            @if (!getColumnOptionValueAsBoolean(column, tableColumnOptionKey.DisplayBooleanAsIcon) && !entity[column.property_name]) {
                                                <span>{{getColumnOptionValueAsString(column, tableColumnOptionKey.BooleanDisplayFalseValue)}}</span>
                                            }
                                        </div>
                                    }

                                    @case (tablePropertyType.Status) {
                                        <ax-table-column-status
                                            [status]="getColumnOptionPropertyPointer(dataIndex, column, tableColumnOptionKey.StatusNumber)"
                                            [statusText]="getColumnOptionPropertyPointer(dataIndex, column, tableColumnOptionKey.StatusText)">
                                        </ax-table-column-status>
                                    }

                                    @case (tablePropertyType.Count) {
                                        <ax-table-column-count
                                            [value]="entity[column.property_name]"
                                            [context]="getColumnOptionValueAsString(column, tableColumnOptionKey.CountContext)">
                                        </ax-table-column-count>
                                    }

                                    @case (tablePropertyType.NameAndAvatar) {
                                        <ax-table-column-name-and-avatar
                                            [avatarUrl]="getColumnOptionPropertyPointer(dataIndex, column, tableColumnOptionKey.PropertyPointerAvatarUrl)"
                                            [firstname]="getColumnOptionPropertyPointer(dataIndex, column, tableColumnOptionKey.PropertyPointerFirstname)"
                                            [lastname]="getColumnOptionPropertyPointer(dataIndex, column, tableColumnOptionKey.PropertyPointerLastname)"
                                            [isNavigatable]="isNavigatable(column)"
                                            (handleNavigation)="navigateTo(dataIndex, column, $event)">
                                        </ax-table-column-name-and-avatar>
                                    }

                                    @case (tablePropertyType.Url) {
                                        <span class="ax-table__cell__url" (click)="redirectToUrl(entity[column.property_name])">{{entity[column.property_name]}}</span>
                                    }

                                    @case (tablePropertyType.Action) {
                                        <ax-table-column-action
                                            [displayValue]="getColumnOptionValueAsString(column, tableColumnOptionKey.ActionDisplayValue)"
                                            [value]="entity[column.property_name]"
                                            [noDataText]="getColumnOptionValueAsString(column, tableColumnOptionKey.ActionNoDataTextValue)"
                                            [callback]="getColumnOptionValue(column, tableColumnOptionKey.ActionCallback)">
                                        </ax-table-column-action>
                                    }

                                    @default {
                                        <span>{{entity[column.property_name]}}</span>
                                    }
                                }
                            </td>
                        }
                        @if (hasButtonsSet()) {
                            <td>
                                <div class="ax-tabel__cell__button__container">
                                    @for (button of buttons; track button.type) {
                                        <button [ax-button]="button.type" [icon-only-small]="true" [transparent]="true" (click)="handleButtonClick($event, button, dataIndex, entity)">
                                            <i [ngClass]="button.icon_class"></i>
                                        </button>
                                    }
                                </div>
                            </td>
                        }
                    </tr>
                }
            </tbody>
        }
    </table>
    @if (isLoading && loaderDelay > 25) {
        <div class="ax-table__loader">
            <ax-loader class="ax-table__loader__image" [width]="164" [height]="160" [loaderText]="loaderText"></ax-loader>
        </div>
    }

    @if (!isLoading && !data.length) {
        <div class="ax-table__no-results__container">
            <p class="ax-table__no-results__container__text" [innerHTML]="noDataText"></p>
        </div>
    }
</div>
