/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonDirective } from '../directives/button.directive';
import { InputDirective } from '../directives/input.directive';
import { LoaderComponent } from '../components/loader/loader.component';
import { TableComponent } from '../components/table/table.component';
import { AvatarComponent } from '../components/avatar/avatar.component';
import { CheckboxComponent } from '../components/checkbox/checkbox.component';
import { DateTimePipe } from '../pipes/date-time.pipe';
import { DatePipe } from '../pipes/date.pipe';
import { TimePipe } from '../pipes/time.pipe';
import { TimeAgoPipe } from '../pipes/timeago.pipe';
import { ValidateDirective } from '../validator/directives/validate.directive';
import { ValidateErrorDirective } from '../validator/directives/validate-error.directive';
import { ToastComponent } from '../components/toast/toast.component';
import { DialogComponent } from '../components/dialog/dialog.component';
import { DialogDirective } from '../directives/dialog.directive';
import { SearchComponent } from '../components/search/search.component';

export const angularImports: Type<any>[] = [CommonModule, RouterModule, FormsModule];
export const componentImports: Type<any>[] = [AvatarComponent, CheckboxComponent, DialogComponent, LoaderComponent, TableComponent, ToastComponent, SearchComponent];

export const directiveImports: Type<any>[] = [ButtonDirective, DialogDirective, InputDirective, ValidateDirective, ValidateErrorDirective];
export const pipeImports: Type<any>[] = [DateTimePipe, DatePipe, TimePipe, TimeAgoPipe];
export const commonViewImports: Type<any>[] = angularImports.concat(directiveImports).concat(componentImports);
