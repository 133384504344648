<div class="update-password-dialog__container">
    <div class="update-password-dialog__header">
        <h4>Update password</h4>
        <button ax-button [icon-only]="true" [transparent]="true" (click)="cancel()">
            <i class="fa-solid fa-xmark"></i>
        </button>
    </div>
    <div class="update-password-dialog__content">
        <div class="input__block">
            <span>Password*</span>
            <ax-password-input [ax-validate]="'password'" [ax-validation-rules]="['required']" [minlength]="8" [maxlength]="50" [(value)]="password"></ax-password-input>
            <small class="input__block__error__message" [ax-validate-error]="'password'"></small>
        </div>
        <div class="input__block">
            <span>Repeat password*</span>
            <ax-password-input [ax-validate]="'password_repeat'" [ax-validate-display-property]="'password'" [ax-validation-rules]="['required']" [minlength]="8" [maxlength]="50" [(value)]="passwordRepeat" placeholder="Repeat password"></ax-password-input>
            <small class="input__block__error__message" [ax-validate-error]="'password_repeat'"></small>
        </div>
        <small class="update-password-dialog__content__info">
            You will be logged out after successfully changing your password.
        </small>
    </div>
    <div class="update-password-dialog__footer">
        <button [ax-button]="'primary'" (click)="updatePassword()">
            <span class="ax-button__label">Update</span>
        </button>
        <button ax-button (click)="cancel()">
            <span class="ax-button__label">Cancel</span>
        </button>
    </div>
</div>
