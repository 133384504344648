<div class="edit-avatar-dialog__container">
    <div class="edit-avatar-dialog__header">
        <h4>Edit avatar</h4>
        <button ax-button [icon-only]="true" [transparent]="true" (click)="closeDialog()">
            <i class="fa-solid fa-xmark"></i>
        </button>
    </div>
    <div class="edit-avatar-dialog__content">
        <ax-avatar [imageUrl]="imageUrl" [name]="name" [size]="'large'"></ax-avatar>
        <input #fileUploadInput type="file" [style]="'visibility: hidden; display: none;'" name="avatar" accept="image/png, image/jpeg, image/jpg" (change)="afterFileSelect($event)">
    </div>
    <div class="edit-avatar-dialog__actions">
        <button [ax-button]="'primary'" (click)="openFileSelect()">
            Upload image
            <i class="fa-regular fa-upload"></i>
        </button>
        @if (hasImageSet()) {
            <button [ax-button]="'primary'" (click)="removeAvatar()">
                Remove avatar
                <i class="fa-regular fa-trash-can-xmark"></i>
            </button>
        }
    </div>
</div>