import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import numeral from 'numeral';

export class Converters {
    static toTimeAgo(value: string): string {
        dayjs.extend(relativeTime);
        dayjs.extend(utc);
        dayjs.extend(timezone);

        return dayjs.utc(value).utcOffset(dayjs().utcOffset()).fromNow();
    }

    static toDateTime(value: string, format: string = 'DD-MM-YYYY HH:mm'): string {
        dayjs.extend(relativeTime);
        dayjs.extend(utc);
        dayjs.extend(timezone);

        return dayjs.utc(value).utcOffset(dayjs().utcOffset()).format(format);
    }

    static toDate(value: string, format: string = 'DD-MM-YYYY'): string {
        dayjs.extend(relativeTime);
        dayjs.extend(utc);
        dayjs.extend(timezone);

        return dayjs.utc(value).utcOffset(dayjs().utcOffset()).format(format);
    }

    static toTime(value: string, useNumeral: boolean = false): string {
        if (!useNumeral) {
            dayjs.extend(relativeTime);
            dayjs.extend(utc);
            dayjs.extend(timezone);

            return dayjs.utc(value).utcOffset(dayjs().utcOffset()).format('HH:mm:ss');
        } else {
            return numeral(value).format('00:00:00');
        }
    }

    static toDateObject(value: string, format: string = 'DD-MM-YYYY'): Date {
        dayjs.extend(customParseFormat);

        return dayjs(value, format).toDate();
    }

    static toValidDate(value: string): Date | null {
        dayjs.extend(customParseFormat);

        if (dayjs(value, 'DD-MM-YYYY', true).isValid()) {
            return dayjs(value, 'DD-MM-YYYY').toDate();
        }

        return null;
    }

    static formatDate(date: Date, format: string = 'DD-MM-YYYY'): string {
        dayjs.extend(relativeTime);
        dayjs.extend(utc);
        dayjs.extend(timezone);

        return dayjs.utc(date).utcOffset(dayjs().utcOffset()).format(format);
    }
}
