import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationSetting } from '../models/user/notification-setting';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NotificationSettingService {
    constructor(private readonly httpClient: HttpClient) {}

    async get(): Promise<NotificationSetting[]> {
        return firstValueFrom(this.httpClient.get<NotificationSetting[]>('notificationSetting'));
    }
}
