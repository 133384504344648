import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import _ from 'lodash';

@Directive({
    selector: '[ax-button]',
    standalone: true,
})
export class ButtonDirective implements OnInit {
    @Input('ax-button') public variant: string | undefined;
    @Input('icon-only') public isIconOnly: boolean = false;
    @Input('transparent') public isTransparent: boolean = false;
    @Input('icon-only-small') public isIconOnlySmall: boolean = false;

    constructor(private readonly element: ElementRef) {}

    ngOnInit(): void {
        this.setStyles();
        this.wrapTextNodes();
        this.setIconClass();
    }

    private setStyles(): void {
        this.element.nativeElement.classList.add('ax-button');

        if (!_.isNil(this.variant) && !_.isEmpty(this.variant)) {
            this.element.nativeElement.classList.add(`ax-button--${this.variant}`);
        }

        if (this.isIconOnly) {
            this.element.nativeElement.classList.add('ax-button--icon-only');
        }

        if (this.isTransparent) {
            this.element.nativeElement.classList.add('ax-button--transparent');
        }

        if (this.isIconOnlySmall) {
            if (!this.element.nativeElement.classList.contains('ax-button--icon-only')) {
                this.element.nativeElement.classList.add('ax-button--icon-only');
            }

            this.element.nativeElement.classList.add('ax-button--small');
        }
    }

    private wrapTextNodes(): void {
        const textNodes: Node[] = this.getTextNodes();

        textNodes.forEach((node: Node) => {
            const label: HTMLSpanElement = document.createElement('span');

            (node as HTMLElement).after(label);

            label.classList.add('ax-button__label');
            label.appendChild(node);
        });
    }

    private getTextNodes(): Node[] {
        return Array.from(this.element.nativeElement.childNodes).filter((node) => {
            const nodeObject: Node = node as Node;
            return !_.isNil(nodeObject) && nodeObject.nodeType === 3 && !_.isNil(nodeObject.textContent?.trim().length) && nodeObject.textContent?.trim().length > 1;
        }) as Node[];
    }

    private setIconClass(): void {
        Array.from(this.element.nativeElement.childNodes).filter((node) => {
            const nodeObject: Node = node as Node;
            const element: HTMLElement = node as HTMLElement;

            if (nodeObject.nodeType === 1 && element.nodeName === 'I') {
                element.classList.add('ax-button__icon');
            }
        });
    }
}
