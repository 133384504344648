import { Component, OnInit, ViewChild } from '@angular/core';
import { commonViewImports } from '../../../utilities/global-import';
import { TableComponent } from '../../../components/table/table.component';
import { ButtonDirective } from '../../../directives/button.directive';
import { SidePanelComponent } from '../../../components/side-panel/side-panel.component';
import { TableColumn } from '../../../components/table/interfaces/table-column';
import { UserService } from '../../../services/user.service';
import { ToastService } from '../../../components/toast/toast.service';
import _ from 'lodash';
import { SelectOption } from '../../../components/select/interfaces/select-option';
import { UserFilterDto } from '../../../dto/user/user-filter-dto';
import { SortDirection } from '../../../components/table/enums/sort-direction';
import { PagedResult } from '../../../models/paged-result';
import { User } from '../../../models/user/user';
import { FilterDto } from '../../../dto/filter-dto';
import { Converters } from '../../../utilities/converters';
import { SelectComponent } from '../../../components/select/select.component';
import { DatePickerComponent } from '../../../components/date-picker/date-picker.component';
import { TableColumnType } from '../../../components/table/enums/table-column-property-type';
import { TableColumnOptionKey } from '../../../components/table/enums/table-column-option-key';

@Component({
    standalone: true,
    selector: 'ax-user-overview-page',
    templateUrl: './user-overview-page.component.html',
    styleUrl: './user-overview-page.component.scss',
    imports: [commonViewImports, TableComponent, ButtonDirective, SidePanelComponent, SelectComponent, DatePickerComponent],
})
export class UserOverviewPageComponent implements OnInit {
    @ViewChild(SidePanelComponent) private readonly filterSidePanel: SidePanelComponent;
    @ViewChild(TableComponent) private readonly tableComponent: TableComponent;

    protected isLoading: boolean = true;
    protected tableData: User[] = [];
    protected activeFilterCount: number = 0;
    protected totalRecordCount: number = 0;
    protected tableScrollPosition: number = 0;

    protected tableColumns: TableColumn[] = [
        {
            caption: 'Name',
            property_name: '',
            type: TableColumnType.NameAndAvatar,
            is_sortable: true,
            options: [
                {
                    key: TableColumnOptionKey.PropertyPointerAvatarUrl,
                    value: 'avatar_url',
                },
                {
                    key: TableColumnOptionKey.PropertyPointerFirstname,
                    value: 'firstname',
                },
                {
                    key: TableColumnOptionKey.PropertyPointerLastname,
                    value: 'lastname',
                },
            ],
            route_url: 'user',
            route_property_name: 'id',
        },
        {
            caption: 'Email Address',
            property_name: 'email_address',
            type: TableColumnType.Default,
            is_sortable: true,
        },
        {
            caption: 'Is Admin',
            property_name: 'is_admin',
            type: TableColumnType.Boolean,
            is_sortable: false,
            options: [
                {
                    key: TableColumnOptionKey.DisplayBooleanAsIcon,
                    value: 'true',
                },
            ],
        },
        {
            caption: 'Created',
            property_name: 'created_at',
            type: TableColumnType.TimeAgo,
            is_sortable: true,
        },
        {
            caption: 'Modified',
            property_name: 'modified_at',
            type: TableColumnType.TimeAgo,
            is_sortable: true,
        },
    ];

    protected propertyOptions: SelectOption[] = [
        {
            display_value: 'Firstname',
            value: 'firstname',
        },
        {
            display_value: 'Lastname',
            value: 'lastname',
        },
        {
            display_value: 'Email Address',
            value: 'email_address',
        },
        {
            display_value: 'Is Admin',
            value: 'is_admin',
        },
        {
            display_value: 'Created',
            value: 'created_at',
        },
        {
            display_value: 'Modified',
            value: 'modified_at',
        },
    ];

    protected userFilterDto: UserFilterDto = {
        sort_property: null,
        sort_order: null,
        skip: 0,
        take: 50,
        filters: [],
    };

    protected booleanOptions: SelectOption[] = [
        {
            display_value: 'True',
            value: 'true',
        },
        {
            display_value: 'False',
            value: 'false',
        },
    ];

    private dateConditions: SelectOption[] = [
        {
            display_value: 'Greater than',
            value: 'greater_than',
        },
        {
            display_value: 'Greater than or equals',
            value: 'greater_than_equals',
        },
        {
            display_value: 'Equals',
            value: 'equals',
        },
        {
            display_value: 'Not equals',
            value: 'not_equals',
        },
        {
            display_value: 'Less than',
            value: 'less_than',
        },
        {
            display_value: 'Less than or equals',
            value: 'less_than_equals',
        },
    ];

    private stringTypeConditionOptions: SelectOption[] = [
        {
            display_value: 'Starts with',
            value: 'starts_with',
        },
        {
            display_value: 'Ends with',
            value: 'ends_with',
        },
        {
            display_value: 'Contains',
            value: 'contains',
        },
        {
            display_value: 'Not contains',
            value: 'not_contains',
        },
    ];

    private equalsConditionOptions: SelectOption[] = [
        {
            display_value: 'Equals',
            value: 'equals',
        },
        {
            display_value: 'Not equals',
            value: 'not_equals',
        },
    ];

    private hasMoreData: boolean = false;

    constructor(
        private readonly userService: UserService,
        private readonly toastService: ToastService
    ) {}

    async ngOnInit(): Promise<void> {
        try {
            this.isLoading = true;
            const result: PagedResult<User> = await this.userService.filter(this.userFilterDto);

            this.tableData = result.data;
            this.totalRecordCount = result.total_count;
            this.hasMoreData = result.has_more_data;
        } catch {
            this.toastService.danger('Fetch error', 'Unable to fetch users');
        } finally {
            _.delay(() => {
                this.isLoading = false;
            }, 100);
        }
    }

    async onSort(column: TableColumn): Promise<void> {
        switch (column.sort_direction) {
            case SortDirection.Ascending:
                this.userFilterDto.sort_order = 'asc';
                this.userFilterDto.sort_property = column.property_name;
                break;

            case SortDirection.Descending:
                this.userFilterDto.sort_order = 'desc';
                this.userFilterDto.sort_property = column.property_name;
                break;

            default:
                this.userFilterDto.sort_order = null;
                this.userFilterDto.sort_property = null;
                break;
        }

        try {
            this.clearInvalidFilters();
            this.userFilterDto.skip = 0;

            this.isLoading = true;
            const result: PagedResult<User> = await this.userService.filter(this.userFilterDto);
            this.hasMoreData = result.has_more_data;
            this.tableData = result.data;
        } catch {
            this.toastService.danger('Fetch error', 'Unable to fetch tenants');
        } finally {
            _.delay(() => {
                this.isLoading = false;
            }, 100);

            this.tableComponent.scrollToTop();
        }
    }

    async onScroll(): Promise<void> {
        if (this.hasMoreData) {
            try {
                this.clearInvalidFilters();

                this.userFilterDto.skip += this.userFilterDto.take;
                const result: PagedResult<User> = await this.userService.filter(this.userFilterDto);

                this.hasMoreData = result.has_more_data;
                this.tableData = this.tableData.concat(result.data);
            } catch {
                this.toastService.danger('Fetch error', 'Unable to fetch tenants');
            }
        }
    }

    async applyFilters(event: Event): Promise<void> {
        event.stopPropagation();

        this.filterSidePanel.toggle();

        this.clearInvalidFilters();

        this.userFilterDto.skip = 0;

        try {
            this.isLoading = true;
            this.activeFilterCount = this.userFilterDto.filters.length;

            const result: PagedResult<User> = await this.userService.filter(this.userFilterDto);
            this.totalRecordCount = result.total_count;
            this.hasMoreData = result.has_more_data;
            this.tableData = result.data;
        } catch {
            this.toastService.danger('Fetch error', 'Unable to fetch tenants');
        } finally {
            _.delay(() => {
                this.isLoading = false;
            }, 100);

            this.tableComponent.scrollToTop();
        }
    }

    hasValidFilters(): boolean {
        if (this.userFilterDto.filters.length === 0) {
            return true;
        } else {
            let isValid: boolean = true;

            this.userFilterDto.filters.forEach((filter: FilterDto) => {
                if (_.isEmpty(filter.property_name) || _.isEmpty(filter.condition) || _.isEmpty(filter.value)) {
                    isValid = false;
                }
            });

            return isValid;
        }
    }

    toggleFilterPanel(event: Event): void {
        event.stopPropagation();
        this.filterSidePanel.toggle();
    }

    setValueType(propertyName: string, filter: FilterDto): void {
        switch (propertyName) {
            case 'firstname':
                filter.value_type = 'string';
                break;

            case 'lastname':
                filter.value_type = 'string';
                break;

            case 'email_address':
                filter.value_type = 'string';
                break;

            case 'is_admin':
                filter.value_type = 'boolean';
                break;

            case 'created_at':
                filter.value_type = 'date';
                break;

            case 'modified_at':
                filter.value_type = 'date';
                break;

            default:
                filter.value_type = '';
                break;
        }

        filter.condition = '';
        filter.value = '';
    }

    getPropertyOptions(filter: FilterDto): SelectOption[] {
        const options: SelectOption[] = [];

        this.propertyOptions.forEach((option: SelectOption) => {
            const existingOption: FilterDto | undefined = this.userFilterDto.filters.find((x: FilterDto) => x.property_name === option.value);

            if (existingOption === undefined) {
                options.push(option);
            } else if (filter.property_name === option.value) {
                options.push(option);
            }
        });

        return options;
    }

    getConditionOptions(filter: FilterDto): SelectOption[] {
        switch (filter.value_type) {
            case 'string':
                return this.stringTypeConditionOptions.concat(this.equalsConditionOptions);

            case 'boolean':
                return this.equalsConditionOptions;

            case 'date':
                return this.dateConditions;

            default:
                return [];
        }
    }

    hasValidPropertyNameSet(filter: FilterDto): boolean {
        return !_.isNil(filter.property_name) && !_.isEmpty(filter.property_name);
    }

    hasValidConditionSet(filter: FilterDto): boolean {
        return !_.isNil(filter.condition) && !_.isEmpty(filter.condition);
    }

    addFilterRow(event: Event): void {
        event.stopPropagation();

        this.userFilterDto.filters.push({
            property_name: '',
            condition: '',
            value: '',
            value_type: '',
        });
    }

    removeFitler(event: Event, index: number): void {
        event.stopPropagation();
        this.userFilterDto.filters.splice(index, 1);
    }

    reset(event: Event): void {
        event.stopPropagation();

        this.userFilterDto.filters = [];
    }

    dateChanged(dates: Date[], filter: FilterDto): void {
        if (!_.isEmpty(dates)) {
            filter.value = Converters.toDate(dates[0].toString(), 'YYYY-MM-DD HH:mm:ss');
        } else {
            filter.value = '';
        }
    }

    displayDate(value: string): string {
        if (!_.isEmpty(value)) {
            return Converters.toDate(value);
        } else {
            return '';
        }
    }

    getRecordCountText(): string {
        if (this.totalRecordCount > 1) {
            return `${this.totalRecordCount} records`;
        } else if (this.totalRecordCount === 0) {
            return '';
        } else {
            return `${this.totalRecordCount} record`;
        }
    }

    private clearInvalidFilters(): void {
        const filterCopy: FilterDto[] = _.cloneDeep(this.userFilterDto.filters);

        filterCopy.forEach((filter: FilterDto) => {
            const foundFilter: FilterDto | undefined = this.userFilterDto.filters.find((x: FilterDto) => x.property_name === filter.property_name);

            if (foundFilter !== undefined) {
                if (_.isEmpty(foundFilter.property_name) || _.isEmpty(foundFilter.condition) || _.isEmpty(foundFilter.value)) {
                    const index: number = this.userFilterDto.filters.indexOf(foundFilter);

                    if (index > -1) {
                        this.userFilterDto.filters.splice(index, 1);
                    }
                }
            }
        });
    }
}
