<div class="table-column-status__container">
    @switch (status) {
        @case (0) {
            <i class="fa-duotone fa-circle-check status-icon--ready"></i>
            <span>{{statusText}}</span>
        }

        @case (1) {
            <i class="fa-duotone fa-triangle-exclamation status-icon--warning"></i>
            <span>{{statusText}}</span>
        }

        @case (2) {
            <i class="fa-duotone fa-circle-exclamation status-icon--error"></i>
            <span>{{statusText}}</span>
        }
    }
</div>