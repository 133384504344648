import { ContentChildren, Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { ScrollSpyItemDirective } from './scrollspy-item.directive';
import * as _ from 'lodash';

@Directive({
    standalone: true,
    selector: '[ax-scroll-spy]',
})
export class ScrollSpyDirective {
    @Output() public sectionChange: EventEmitter<string> = new EventEmitter<string>();
    @ContentChildren(ScrollSpyItemDirective) private readonly scrollSpyItems: ScrollSpyItemDirective[];

    @HostListener('scroll', ['$event'])
    onScroll(event: Event) {
        let currentSection: string;
        const targetElement: HTMLElement = event.target as HTMLElement;

        const scrollTop: number = targetElement.scrollTop + 80; // +80 to scroll top to trigger section change earlier
        const parentOffset: number = targetElement.offsetTop;
        const parentPadding: string = window.getComputedStyle(targetElement, null).getPropertyValue('padding-top');
        let parentPaddingNum: number = 0;

        if (!_.isNil(parentPadding)) {
            parentPaddingNum = parseInt(parentPadding);
        }

        this.scrollSpyItems.forEach((child: ScrollSpyItemDirective, index: number) => {
            if (index === 0) {
                if (child.elementRef.nativeElement.offsetTop - (parentOffset + parentPaddingNum) <= scrollTop) {
                    currentSection = child.scrollSpyItem;
                }
            } else {
                if (child.elementRef.nativeElement.offsetTop - parentOffset <= scrollTop) {
                    currentSection = child.scrollSpyItem;
                }
            }
        });

        if (this.currentSection !== currentSection) {
            this.currentSection = currentSection;
            this.sectionChange.emit(this.currentSection);
        }

        if (targetElement.scrollTop + targetElement.clientHeight + 60 >= targetElement.scrollHeight) {
            const elem: ScrollSpyItemDirective = this.scrollSpyItems.find((x: ScrollSpyItemDirective, index: number) => index === this.scrollSpyItems.length - 1);

            this.currentSection = elem.scrollSpyItem;
            this.sectionChange.emit(this.currentSection);
        }
    }

    private currentSection: string;

    goToSection(sectionKey: string): void {
        const foundSection: ScrollSpyItemDirective = this.scrollSpyItems.find((x: ScrollSpyItemDirective) => x.scrollSpyItem === sectionKey);

        if (!_.isNil(foundSection)) {
            // TODO: Disable scroll trigger when scrolling into view
            foundSection.elementRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
        }
    }
}
