export class ComponentState {
    public readonly layer: number; // TODO: Create enum for better readability?
    public readonly component: any;
    public readonly insertedAt: Date;

    constructor(component: any, layer: number) {
        this.component = component;
        this.layer = layer;
        this.insertedAt = new Date();
    }
}
