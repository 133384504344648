import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { angularImports, commonViewImports } from '../../../utilities/global-import';
import { TableComponent } from '../../../components/table/table.component';
import { ButtonDirective } from '../../../directives/button.directive';
import { TenantService } from '../../../services/tenant.service';
import { ToastService } from '../../../components/toast/toast.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Tenant } from '../../../models/tenant/tenant';
import { CollapseContainerComponent } from '../../../components/collapse-container/collapse-container.component';
import { InputDirective } from '../../../directives/input.directive';
import { Converters } from '../../../utilities/converters';
import { TableColumn } from '../../../components/table/interfaces/table-column';
import { TableColumnType } from '../../../components/table/enums/table-column-property-type';
import { TableButton } from '../../../components/table/interfaces/table-button';
import { CanDeactivate } from '../../../middleware/can-deactivate.guard';
import { DialogComponentRef } from '../../../components/dialog/models/dialog-component-ref';
import { ConfirmationDialogComponent } from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { DialogService } from '../../../components/dialog/dialog.service';
import { ScrollSpyDirective } from '../../../directives/scrollspy.directive';
import { ScrollSpyItemDirective } from '../../../directives/scrollspy-item.directive';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { TenantAccount } from '../../../models/tenant/tenant-account';
import { ValidationHost } from '../../../validator/validation-host';
import { ValidateErrorDirective } from '../../../validator/directives/validate-error.directive';
import { ValidateDirective } from '../../../validator/directives/validate.directive';
import { Validator } from '../../../validator/validator';
import { TenantUserDto } from '../../../dto/tenant/tenant-user-dto';
import { DtoState } from '../../../dto/dto-state';
import { TenantResourceDto } from '../../../dto/tenant/tenant-resource-dto';
import { TenantVersionDto } from '../../../dto/tenant/tenant-version-dto';
import { AuthorizationService } from '../../../services/authorization.service';
import { SidePanelComponent } from '../../../components/side-panel/side-panel.component';
import { SelectOption } from '../../../components/select/interfaces/select-option';
import { ApiResourceService } from '../../../services/api-resource.service';
import { ApiResourceList } from '../../../models/api-resource/api-resource-list';
import { CustomValidationRule, CustomValidationRuleValidateResult } from '../../../validator/rules/custom-validation-rule';
import { ValidationRule } from '../../../validator/rules/validation-rule';
import { SelectComponent } from '../../../components/select/select.component';
import { ApplicationVersion } from '../../../models/application-version/application-version';
import { ApplicationVersionService } from '../../../services/application-version.service';
import { AxerrioAccountsService } from '../../../services/axerrio-accounts.service';
import { AxerrioAccountsUser } from '../../../models/user/axerrio-accounts-user';
import _ from 'lodash';
import { TenantApiResource } from '../../../models/tenant/tenant-api-resource';
import { TenantApplicationVersion } from '../../../models/tenant/tenant-application-version';
import { TooltipDirective } from '../../../directives/tooltip.directive';
import { StateService } from '../../../state/state.service';
import { SearchComponent } from '../../../components/search/search.component';
import { TableColumnOptionKey } from '../../../components/table/enums/table-column-option-key';
import { Mutation } from '../../../models/mutation/mutation';
import { MutationFilterDto } from '../../../dto/mutation/mutation-filter-dto';
import { PagedResult } from '../../../models/paged-result';
import { TenantApiResourceStatus } from './interfaces/tenant-api-resource-status';
import { Toolkit } from '../../../utilities/toolkit';
import { SplitButtonComponent } from '../../../components/split-button/split-button.component';
import { SplitButtonOption } from '../../../components/split-button/interfaces/split-button-options';
import { MetricsService } from '../../../services/metrics.service';
import { ApiResourceMetric, ServiceStatus } from '../../../models/metrics/api-resource-metric';
import dayjs from 'dayjs';
import { StatusMetricComponent } from '../../../components/status-metric/status-metric.component';
import { StatusMetric, StatusMetricState } from '../../../components/status-metric/interfaces/status-metric';

@Component({
    standalone: true,
    templateUrl: './tenant-details-page.component.html',
    styleUrl: './tenant-details-page.component.scss',
    imports: [
        commonViewImports,
        angularImports,
        TableComponent,
        ButtonDirective,
        InputDirective,
        ValidateDirective,
        ValidateErrorDirective,
        CollapseContainerComponent,
        ScrollSpyDirective,
        ScrollSpyItemDirective,
        PaginationComponent,
        SelectComponent,
        SearchComponent,
        SidePanelComponent,
        TooltipDirective,
        SplitButtonComponent,
        StatusMetricComponent,
    ],
})
export class TenantDetailPageComponent implements OnInit, ValidationHost, CanDeactivate {
    public title: string = '';
    public sidePanelHeader: string = '';

    protected isLoading: boolean = false;
    protected isMutationsLoading: boolean = false;
    protected isMetricsLoading: boolean = false;
    protected skipDeactivateCheck: boolean = false;
    protected hasEditPermission: boolean = false;
    protected hasDeletePermission: boolean = false;
    protected hasAdminPermission: boolean = false;
    protected isUpdateState: boolean = false;
    protected isMutationsLoaded: boolean = false;
    protected hasNewMutationsToFetch: boolean = false;
    protected showSearchBar: boolean = false;
    protected isApiResourcesLoading: boolean = false;
    protected currentScrollSpyElementKey: string = 'general-information';
    protected activePanel: string = '';
    protected selectedUserId: string = '';
    protected selectedResourceId: string = '';
    protected selectedVersionId: string = '';
    protected selectedTenantAccountPage: number = 1;
    protected selectedMutationPage: number = 1;
    protected userRecordsPerPage: number = 10;
    protected mutationRecordsPerPage: number = 10;
    protected tenant: Tenant;
    protected mutations: Mutation[] = [];
    protected tenantApiResouceStatuses: TenantApiResourceStatus[] = [];
    protected totalMutaionRecordCount: number = 0;
    protected resourceValidationRules: ValidationRule[] = [];
    protected userValidationRules: ValidationRule[] = [];
    protected rawTenantMutationData: string;
    protected isMaintenanceEnabled: boolean = false;

    protected tenantUserDto: TenantUserDto = {
        user_id: 0,
        abs_user_id: 0,
        state: DtoState.Unchanged,
    };

    protected tenantResourceDto: TenantResourceDto = {
        id: 0,
        resource_id: 0,
        url: '',
        state: DtoState.Unchanged,
    };

    protected tenantVersionDto: TenantVersionDto = {
        application_version_id: 0,
        state: DtoState.Unchanged,
    };

    protected userTableColumns: TableColumn[] = [
        {
            caption: 'ID',
            property_name: 'id',
            type: TableColumnType.Default,
        },
        {
            caption: 'ABS ID',
            property_name: 'abs_user_id',
            type: TableColumnType.Default,
        },
        {
            caption: 'Name',
            property_name: 'name',
            type: TableColumnType.Default,
        },
        {
            caption: 'Email Address',
            property_name: 'email_address',
            type: TableColumnType.Default,
        },
    ];

    protected apiResourceTableColumns: TableColumn[] = [
        {
            caption: 'ID',
            property_name: 'id',
            type: TableColumnType.Default,
        },
        {
            caption: 'Resource ID',
            property_name: 'resource_id',
            type: TableColumnType.Default,
        },
        {
            caption: 'Name',
            property_name: 'name',
            type: TableColumnType.Default,
        },
        {
            caption: 'URL',
            property_name: 'url',
            type: TableColumnType.Url,
        },
        {
            caption: 'Status',
            property_name: '',
            type: TableColumnType.Status,
            options: [
                {
                    key: TableColumnOptionKey.StatusNumber,
                    value: 'status',
                },
                {
                    key: TableColumnOptionKey.StatusText,
                    value: 'status_text',
                },
            ],
        },
        {
            caption: 'Version',
            property_name: 'version',
            type: TableColumnType.Default,
        },
        {
            caption: 'Last Check',
            property_name: 'created_at',
            type: TableColumnType.TimeAgo,
        },
    ];

    protected applicationVersionsTableColumns: TableColumn[] = [
        {
            caption: 'ID',
            property_name: 'id',
            type: TableColumnType.Default,
        },
        {
            caption: 'Application',
            property_name: 'client_name',
            type: TableColumnType.Default,
        },
        {
            caption: 'Version',
            property_name: 'version',
            type: TableColumnType.Default,
        },
    ];

    protected mutationTableColumns: TableColumn[] = [
        {
            caption: 'State',
            property_name: 'state',
            type: TableColumnType.Default,
        },
        {
            caption: 'Old value',
            property_name: 'old_value',
            type: TableColumnType.Action,
            options: [
                {
                    key: TableColumnOptionKey.ActionDisplayValue,
                    value: 'Show raw data',
                },
                {
                    key: TableColumnOptionKey.ActionNoDataTextValue,
                    value: 'No old value data present',
                },
                {
                    key: TableColumnOptionKey.ActionCallback,
                    value: this.showRawMutationData.bind(this),
                },
            ],
        },
        {
            caption: 'New value',
            property_name: 'new_value',
            type: TableColumnType.Action,
            options: [
                {
                    key: TableColumnOptionKey.ActionDisplayValue,
                    value: 'Show raw data',
                },
                {
                    key: TableColumnOptionKey.ActionNoDataTextValue,
                    value: 'No new value data present',
                },
                {
                    key: TableColumnOptionKey.ActionCallback,
                    value: this.showRawMutationData.bind(this),
                },
            ],
        },
        {
            caption: 'Mutated By',
            property_name: 'mutated_by',
            type: TableColumnType.NameAndAvatar,
            options: [
                {
                    key: TableColumnOptionKey.PropertyPointerAvatarUrl,
                    value: 'avatar_url',
                },
                {
                    key: TableColumnOptionKey.PropertyPointerFirstname,
                    value: 'firstname',
                },
                {
                    key: TableColumnOptionKey.PropertyPointerLastname,
                    value: 'lastname',
                },
            ],
        },
        {
            caption: 'Mutated At',
            property_name: 'mutated_at',
            type: TableColumnType.TimeAgo,
        },
    ];

    protected mutationFilterDto: MutationFilterDto = {
        skip: 0,
        take: this.mutationRecordsPerPage,
        sort_order: null,
        sort_property: null,
    };

    protected userTableButtons: TableButton[] = [];
    protected apiResourceTableButtons: TableButton[] = [];
    protected applicationVersionTableButtons: TableButton[] = [];

    protected userOptions: SelectOption[] = [];
    protected resourceOptions: SelectOption[] = [];
    protected versionOptions: SelectOption[] = [];

    protected metricRangeOptions: SelectOption[] = [
        {
            display_value: 'Day',
            value: 'day',
        },
        {
            display_value: 'Hour',
            value: 'hour',
        },
    ];

    protected selectedMetricRange: string = 'hour';

    protected splitButtonCopyOptions: SplitButtonOption[] = [
        {
            display_name: 'Copy ID',
            icon_class: null,
            callback: this.copyTenantId.bind(this),
        },
        {
            display_name: 'Copy Name',
            icon_class: null,
            callback: this.copyTenantName.bind(this),
        },
        {
            display_name: 'Copy Code',
            icon_class: null,
            callback: this.copyTenantCode.bind(this),
        },
    ];

    protected apiResourceMetricsMap: Map<string, StatusMetric[]> = new Map<string, StatusMetric[]>();

    private tenantClone: Tenant;
    private validator: Validator;

    @ViewChildren(ValidateDirective) private readonly validateDirectives: ValidateDirective[];
    @ViewChildren(ValidateErrorDirective) private readonly validateErrorDirectives: ValidateErrorDirective[];

    @ViewChild('contentPanel') protected readonly sidePanel: SidePanelComponent;
    @ViewChild('mutationPanel') protected readonly mutationSidePanel: SidePanelComponent;
    @ViewChild('metricsPanel') protected readonly metricsPanel: SidePanelComponent;
    @ViewChild(ScrollSpyDirective) protected readonly scrollSpyDirective: ScrollSpyDirective;
    @ViewChild('splitButton') private readonly splitButton: SplitButtonComponent;
    @ViewChild('userTypeaheadSelect') private readonly userTypeaheadSelect: SelectComponent;
    @ViewChild('userPagination') private readonly userPagination: PaginationComponent;
    @ViewChild('mutationsPagination') private readonly mutationsPagination: PaginationComponent;

    constructor(
        private readonly tenantService: TenantService,
        private readonly toastService: ToastService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly dialogService: DialogService,
        private readonly authorizationService: AuthorizationService,
        private readonly resourceService: ApiResourceService,
        private readonly versionService: ApplicationVersionService,
        private readonly accountService: AxerrioAccountsService,
        private readonly router: Router,
        private readonly stateService: StateService,
        private readonly metricsService: MetricsService
    ) {}

    async ngOnInit(): Promise<void> {
        this.isLoading = true;
        this.isMetricsLoading = true;

        try {
            let tenantId: number = 0;

            this.activatedRoute.params.subscribe((params: Params) => {
                const parsedTenantId: number = Number(params['id']);

                if (_.isNaN(parsedTenantId)) {
                    tenantId = 0;
                } else {
                    tenantId = parsedTenantId;
                }
            });

            if (tenantId === 0 && this.router.url !== '/tenant') {
                this.router.navigateByUrl('/tenants');
                return;
            } else {
                this.tenant = await this.tenantService.get(tenantId);
                this.title = this.tenant.name;
                this.tenantClone = _.cloneDeep(this.tenant);

                if (this.tenant.users.length > 10) {
                    this.showSearchBar = true;
                }
            }

            this.isMaintenanceEnabled = this.tenant.is_maintenance_mode;

            await this.getUsers();
            await this.getResources();
            await this.getVersions();

            if (!_.isNil(this.tenant.resources) && !_.isEmpty(this.tenant.resources)) {
                await this.buildTenantApiResourceStatusModels();

                for (const tenantApiResourceStatus of this.tenantApiResouceStatuses) {
                    this.apiResourceMetricsMap.set(tenantApiResourceStatus.name, await this.getApiResourceMetrics(tenantApiResourceStatus.resource_id, this.selectedMetricRange));
                }
            }

            this.hasEditPermission = await this.authorizationService.hasPermission('Tenants.Write');
            this.hasDeletePermission = await this.authorizationService.hasPermission('Tenants.Archive');
            this.hasAdminPermission = await this.authorizationService.isAdmin();

            if (this.hasEditPermission) {
                this.userTableButtons.push(
                    {
                        type: 'default',
                        icon_class: 'fa-solid fa-pen-to-square',
                        callback: this.setUpdateUserPanel.bind(this),
                    },
                    {
                        type: 'danger',
                        icon_class: 'fa-solid fa-circle-minus',
                        callback: this.deleteUser.bind(this),
                    }
                );

                this.apiResourceTableButtons.push(
                    {
                        type: 'default',
                        icon_class: 'fa-solid fa-pen-to-square',
                        callback: this.setUpdateResourcePanel.bind(this),
                    },
                    {
                        type: 'danger',
                        icon_class: 'fa-solid fa-circle-minus',
                        callback: this.deleteResource.bind(this),
                    }
                );

                this.applicationVersionTableButtons.push({
                    type: 'danger',
                    icon_class: 'fa-solid fa-circle-minus',
                    callback: this.deleteVersion.bind(this),
                });
            }
        } catch (err) {
            this.toastService.danger('Error', 'Unable to fetch tenant');
        } finally {
            _.delay(() => {
                this.isLoading = false;
                this.isMetricsLoading = false;
                this.validator = new Validator(this);
                this.setValidationRules();
            }, 100);
        }
    }

    canDeactivate(): Promise<boolean> {
        if (this.hasChanges() && !this.skipDeactivateCheck) {
            const dialogComponentRef: DialogComponentRef<ConfirmationDialogComponent> = this.dialogService.createDialog(ConfirmationDialogComponent, {
                title: 'Unsaved changes',
                text: `You have unsaved changes, do you want to discard these changes?`,
                confirmation_button_text: 'Discard',
                cancel_button_text: 'Cancel',
                button_type: 'danger',
            });

            return new Promise<boolean>((resolve) => {
                dialogComponentRef.afterClose.subscribe((hasConfirmation: boolean) => {
                    resolve(hasConfirmation);
                });
            });
        } else {
            return Promise.resolve(true);
        }
    }

    getValidationItems(): ValidateDirective[] {
        return this.validateDirectives;
    }

    getValidationErrorItems(): ValidateErrorDirective[] {
        return this.validateErrorDirectives;
    }

    protected async deleteTenant(event: Event): Promise<void> {
        event.stopPropagation();

        const dialogComponentRef: DialogComponentRef<ConfirmationDialogComponent> = this.dialogService.createDialog(ConfirmationDialogComponent, {
            title: 'Delete tenant',
            text: `Are you sure you want to delete <b>${this.title}</b>?`,
            confirmation_button_text: 'Delete',
            cancel_button_text: 'Cancel',
            button_type: 'danger',
        });

        dialogComponentRef.afterClose.subscribe(async (hasConfirmation: boolean) => {
            if (hasConfirmation) {
                this.stateService.invalidateObjectState('tenant-overview');
                await this.tenantService.delete(this.tenant.id);
                this.skipDeactivateCheck = true;
                this.router.navigateByUrl('/tenants');
                this.toastService.success('Success', 'Tenant deleted');
            }
        });
    }

    protected async updateTenant(event: Event): Promise<void> {
        event.stopPropagation();

        this.validator.resetValidationStates();
        const isValid: boolean = this.validator.validate(false, 'tenant');

        if (!isValid) {
            this.toastService.danger('Validation error', `Missing required fields on tenant`);
            return;
        }

        try {
            this.tenant = await this.tenantService.update(this.tenant.id, this.tenant);
            this.tenantClone = _.cloneDeep(this.tenant);
            this.title = this.tenant.name;
            this.toastService.success('Success', 'Tenant is updated');
            this.hasNewMutationsToFetch = true;
            this.stateService.invalidateObjectState('tenant-overview');
        } catch (err) {
            Toolkit.handleApiValidationErrors(err, this.validator, this.toastService);
        }
    }

    protected async getUsers(): Promise<void> {
        const users: AxerrioAccountsUser[] = await this.accountService.get();
        this.userOptions = [];

        users.forEach((user: AxerrioAccountsUser) => {
            this.userOptions.push({
                value: user.id.toString(),
                display_value: user.email,
            });
        });
    }

    protected async getResources(): Promise<void> {
        const resources: ApiResourceList[] = await this.resourceService.get();
        this.resourceOptions = [];

        resources.forEach((resource: ApiResourceList) => {
            this.resourceOptions.push({
                value: resource.id.toString(),
                display_value: resource.name,
            });
        });
    }

    protected async getVersions(): Promise<void> {
        const versions: ApplicationVersion[] = await this.versionService.get();
        this.versionOptions = [];

        versions.reverse().forEach((version: ApplicationVersion) => {
            const existingVersion: TenantApplicationVersion | undefined = this.tenant.versions.find(
                (applicationVersion: TenantApplicationVersion) => applicationVersion.version === version.version.id && applicationVersion.client_name === version.application.name
            );

            if (_.isNil(existingVersion)) {
                this.versionOptions.push({
                    value: version.id.toString(),
                    display_value: `${version.version.name} - ${version.application.name}`,
                });
            }
        });
    }

    protected async fetchMutations(): Promise<void> {
        this.hasNewMutationsToFetch = false;
        this.isMutationsLoaded = true;
        this.scrollSpyDirective.goToSection('mutations');

        try {
            this.isMutationsLoading = true;

            const result: PagedResult<Mutation> = await this.tenantService.filterMutations(this.tenant.id, this.mutationFilterDto);

            this.totalMutaionRecordCount = result.total_count;
            this.mutations = result.data;
        } catch {
            this.toastService.danger('Error', 'Unable to fetch mutations');
        } finally {
            _.delay(() => {
                this.isMutationsLoading = false;
                this.mutationsPagination.buildPageNumberOptions();

                _.delay(() => {
                    this.scrollSpyDirective.goToSection('mutations');
                }, 20);
            }, 100);
        }
    }

    protected async fetchPagedMutations(): Promise<void> {
        if (this.selectedMutationPage === 1) {
            this.mutationFilterDto.skip = 0;
        } else {
            this.mutationFilterDto.skip = (this.selectedMutationPage - 1) * this.mutationRecordsPerPage;
        }

        try {
            this.isMutationsLoading = true;

            const result: PagedResult<Mutation> = await this.tenantService.filterMutations(this.tenant.id, this.mutationFilterDto);

            this.mutations = result.data;
        } catch {
            this.toastService.danger('Error', 'Unable to fetch mutations');
        } finally {
            _.delay(() => {
                this.isMutationsLoading = false;

                _.delay(() => {
                    this.scrollSpyDirective.goToSection('mutations');
                }, 20);
            }, 100);
        }
    }

    protected async resetMutationPagination(): Promise<void> {
        this.selectedMutationPage = 1;
        this.mutationFilterDto.take = this.mutationRecordsPerPage;
        await this.fetchPagedMutations();
    }

    protected async reloadMutations(): Promise<void> {
        this.selectedMutationPage = 1;
        this.mutationRecordsPerPage = 10;

        this.mutationFilterDto.skip = 0;
        this.mutationFilterDto.take = 10;
        this.mutationFilterDto.sort_order = null;
        this.mutationFilterDto.sort_property = null;

        await this.fetchMutations();

        this.scrollSpyDirective.goToSection('mutations');
    }

    protected async onMetricRangeChange(): Promise<void> {
        try {
            this.isMetricsLoading = true;

            this.apiResourceMetricsMap.clear();

            for (const tenantApiResourceStatus of this.tenantApiResouceStatuses) {
                this.apiResourceMetricsMap.set(tenantApiResourceStatus.name, await this.getApiResourceMetrics(tenantApiResourceStatus.resource_id, this.selectedMetricRange));
            }
        } finally {
            _.delay(() => {
                this.isMetricsLoading = false;
            }, 100);
        }
    }

    protected async toggleMaintenaceMode(): Promise<void> {
        const titleText: string = this.tenant.is_maintenance_mode ? 'Disable Maintenance mode' : 'Enable Maintenance mode';
        const infoText: string = this.tenant.is_maintenance_mode
            ? 'By disabling maintenance mode, API Resources will be checked periodically for their version and uptime.<br/><br/> <b>Are you sure you want to disable maintenance mode?</b>'
            : 'By enabling maintenance mode, API Resources will not be checked for their version and uptime.<br/><br/> <b>Are you sure you want to enable maintenance mode?</b>';

        const dialogComponentRef: DialogComponentRef<ConfirmationDialogComponent> = this.dialogService.createDialog(ConfirmationDialogComponent, {
            title: titleText,
            text: infoText,
            confirmation_button_text: this.tenant.is_maintenance_mode ? 'Disable' : 'Enable',
            cancel_button_text: 'Cancel',
            button_type: 'danger',
        });

        dialogComponentRef.afterClose.subscribe(async (hasConfirmation: boolean) => {
            if (hasConfirmation) {
                await this.tenantService.setMaintenanceMode(this.tenant.id, this.isMaintenanceEnabled);

                this.toastService.success('Success', `Maintenance mode ${this.isMaintenanceEnabled ? 'enabled' : 'disabled'}`);
            } else {
                this.isMaintenanceEnabled = this.tenant.is_maintenance_mode;
            }
        });
    }

    protected showCreateEntityPanel(panel: string): void {
        this.isUpdateState = false;

        switch (panel) {
            case 'user':
                this.setCreateUserState();
                break;
            case 'resource':
                this.setCreateResourceState();
                break;
            case 'version':
                this.setCreateApplicationVersionState();
                break;
            default:
                throw new Error(`${panel} is not implemented`);
        }

        this.activePanel = panel;
        this.sidePanel.toggle();
    }

    protected normalizeDate(date: Date): string {
        return Converters.formatDate(date, 'DD-MM-YYYY HH:mm');
    }

    protected hasChanges(): boolean {
        return !_.isEqual(this.tenant, this.tenantClone);
    }

    protected scrollSpySectionChanged(key: string): void {
        this.currentScrollSpyElementKey = key;
    }

    protected goToSection(event: Event, key: string): void {
        event.stopPropagation();
        this.scrollSpyDirective.goToSection(key);
    }

    protected getTenantAccountData(): TenantAccount[] {
        let skipAmount: number = 0;
        if (this.selectedTenantAccountPage === 1) {
            skipAmount = 0;
        } else {
            skipAmount = this.userRecordsPerPage * this.selectedTenantAccountPage - this.userRecordsPerPage;
        }

        return this.tenant.users.slice(skipAmount, skipAmount + this.userRecordsPerPage);
    }

    protected resetUserPagination(): void {
        this.selectedTenantAccountPage = 1;
        this.getTenantAccountData();
    }

    protected copyTenant(): void {
        const jsonTenant = {
            id: this.tenant.id,
            name: this.tenant.name,
            code: this.tenant.code,
        };

        navigator.clipboard.writeText(JSON.stringify(jsonTenant));
        this.toastService.feedback('Tenant copied to clipboard!', this.splitButton.getContainerElement(), 'left');
    }

    protected saveObject(): void {
        switch (this.activePanel) {
            case 'user':
                this.addUser();
                break;
            case 'resource':
                this.addResource();
                break;
            case 'version':
                this.addVersion();
                break;
            default:
                throw new Error(`Save ${this.activePanel} is not implemented`);
        }
    }

    protected updateObject(): void {
        switch (this.activePanel) {
            case 'user':
                this.updateUser();
                break;
            case 'resource':
                this.updateResource();
                break;
            default:
                throw new Error(`Update ${this.activePanel} is not implemented`);
        }
    }

    protected setUpdateUserPanel(index: number, data: TenantAccount): void {
        this.sidePanelHeader = 'User';
        this.isUpdateState = true;
        this.selectedUserId = data.id.toString();

        this.tenantUserDto = {
            user_id: data.id,
            abs_user_id: data.abs_user_id,
            state: DtoState.Unchanged,
        };

        this.activePanel = 'user';
        this.sidePanel.toggle();
        // Added this function to force the select / typeahead to update it's selected value,
        // This prevents the bug where opening the same user panel in edit mode twice results in the second time having no visual value.
        // Altho the value is there and the select component has a selected state (the clear button is there).
        this.userTypeaheadSelect.updateSelectedState();
    }

    protected setUpdateResourcePanel(index: number, data: TenantApiResource): void {
        this.sidePanelHeader = 'API Resource';
        this.isUpdateState = true;
        this.selectedResourceId = data.resource_id.toString();

        this.tenantResourceDto = {
            id: data.id,
            resource_id: data.resource_id,
            url: data.url,
            state: DtoState.Unchanged,
        };

        this.activePanel = 'resource';
        this.sidePanel.toggle();
    }

    protected resetValidationState(isVisible: boolean): void {
        if (!isVisible) {
            this.validator.resetValidationStates();

            if (!_.isNil(this.userTypeaheadSelect)) {
                this.userTypeaheadSelect.resetTypeahead();
            }
        }
    }

    protected backToOverview(): void {
        this.router.navigateByUrl('/tenants');
    }

    protected searchUsers(value: string): void {
        if (value === '') {
            this.tenant.users = this.tenantClone.users;
        } else {
            this.tenant.users = this.tenantClone.users.filter((user: TenantAccount) => {
                return user.name.includes(value) || user.email_address.includes(value);
            });
        }

        _.delay(() => {
            this.userPagination.buildPageNumberOptions();
        }, 100);
    }

    protected openMutationsContainerAndScroll(isCollapsed: boolean): void {
        if (!isCollapsed) {
            _.delay(() => {
                this.scrollSpyDirective.goToSection('mutations');
            }, 150);
        }
    }

    private async addUser(): Promise<void> {
        this.validator.resetValidationStates();
        const isValid: boolean = this.validator.validate(false, 'user');

        if (isValid) {
            try {
                this.tenantUserDto.state = DtoState.Added;
                this.tenantUserDto.user_id = parseInt(this.selectedUserId);
                this.tenant = await this.tenantService.handleTenantUser(this.tenant.id, this.tenantUserDto);
                this.tenantClone = _.cloneDeep(this.tenant);
                this.toastService.success('Success', 'User is added to tenant');
                this.sidePanel.toggle();
                this.hasNewMutationsToFetch = true;
                await this.getUsers();

                if (this.tenant.users.length > 10) {
                    this.showSearchBar = true;
                } else {
                    this.showSearchBar = false;

                    if (this.selectedTenantAccountPage > 1) {
                        this.resetUserPagination();
                    }
                }

                this.userPagination.buildPageNumberOptions();
            } catch (err) {
                Toolkit.handleApiValidationErrors(err, this.validator, this.toastService);
            }
        } else {
            this.toastService.danger('Validation error', 'Some fields did not pass validation checks');
        }
    }

    private async updateUser(): Promise<void> {
        this.validator.resetValidationStates();
        const isValid: boolean = this.validator.validate(false, 'user');

        if (isValid) {
            try {
                this.tenantUserDto.state = DtoState.Modified;
                this.tenantUserDto.user_id = parseInt(this.selectedUserId);
                this.tenant = await this.tenantService.handleTenantUser(this.tenant.id, this.tenantUserDto);
                this.tenantClone = _.cloneDeep(this.tenant);
                this.toastService.success('Success', 'User is updated to tenant');
                this.sidePanel.toggle();
                this.hasNewMutationsToFetch = true;
            } catch (err) {
                Toolkit.handleApiValidationErrors(err, this.validator, this.toastService);
            }
        } else {
            this.toastService.danger('Validation error', 'Some fields did not pass validation checks');
        }
    }

    private async deleteUser(index: number, data: TenantAccount): Promise<void> {
        try {
            this.tenantUserDto.state = DtoState.Deleted;
            this.tenantUserDto.user_id = data.id;
            this.tenantUserDto.abs_user_id = data.abs_user_id;
            this.tenant = await this.tenantService.handleTenantUser(this.tenant.id, this.tenantUserDto);
            this.tenantClone = _.cloneDeep(this.tenant);
            this.toastService.success('Success', 'User is deleted from tenant');
            this.hasNewMutationsToFetch = true;
            await this.getUsers();

            if (this.tenant.users.length > 10) {
                this.showSearchBar = true;
            } else {
                this.showSearchBar = false;

                if (this.selectedTenantAccountPage > 1) {
                    this.resetUserPagination();
                }
            }

            this.userPagination.buildPageNumberOptions();
        } catch (err) {
            this.toastService.danger('Error', 'Error when removing user from tenant');
        }
    }

    private async addResource(): Promise<void> {
        this.validator.resetValidationStates();
        const isValid: boolean = this.validator.validate(false, 'resource');

        if (isValid) {
            try {
                this.tenantResourceDto.state = DtoState.Added;
                this.tenantResourceDto.resource_id = parseInt(this.selectedResourceId);
                if (await this.IsNotDuplicate('resource', this.selectedResourceId)) {
                    this.tenant = await this.tenantService.handleTenantResource(this.tenant.id, this.tenantResourceDto);
                    this.tenantClone = _.cloneDeep(this.tenant);
                    this.toastService.success('Success', 'Resource is added to tenant');
                    this.sidePanel.toggle();
                    this.hasNewMutationsToFetch = true;
                    await this.buildTenantApiResourceStatusModels();
                }
            } catch (err) {
                Toolkit.handleApiValidationErrors(err, this.validator, this.toastService);
            }
        } else {
            this.toastService.danger('Validation error', 'Some fields did not pass validation checks');
        }
    }

    private async updateResource(): Promise<void> {
        this.validator.resetValidationStates();
        const isValid: boolean = this.validator.validate(false, 'resource');

        if (isValid) {
            try {
                this.tenantResourceDto.state = DtoState.Modified;
                this.tenantResourceDto.resource_id = parseInt(this.selectedResourceId);
                this.tenant = await this.tenantService.handleTenantResource(this.tenant.id, this.tenantResourceDto);
                this.tenantClone = _.cloneDeep(this.tenant);
                this.toastService.success('Success', 'Resource is updated from tenant');
                this.sidePanel.toggle();
                this.hasNewMutationsToFetch = true;

                await this.buildTenantApiResourceStatusModels();
            } catch (err) {
                Toolkit.handleApiValidationErrors(err, this.validator, this.toastService);
            }
        } else {
            this.toastService.danger('Validation error', 'Some fields did not pass validation checks');
        }
    }

    private async deleteResource(index: number, data: TenantApiResource): Promise<void> {
        try {
            this.tenantResourceDto.state = DtoState.Deleted;
            this.tenantResourceDto.id = data.id;
            this.tenantResourceDto.resource_id = data.resource_id;
            this.tenantResourceDto.url = data.url;
            this.tenant = await this.tenantService.handleTenantResource(this.tenant.id, this.tenantResourceDto);
            this.tenantClone = _.cloneDeep(this.tenant);
            this.toastService.success('Success', 'Resource is removed from tenant');
            this.hasNewMutationsToFetch = true;

            await this.buildTenantApiResourceStatusModels();
        } catch (err) {
            this.toastService.danger('Error', 'Error when removing resource from tenant');
        }
    }

    private async addVersion(): Promise<void> {
        this.validator.resetValidationStates();
        const isValid: boolean = this.validator.validate(false, 'version');

        if (isValid) {
            try {
                this.tenantVersionDto.state = DtoState.Added;
                this.tenantVersionDto.application_version_id = parseInt(this.selectedVersionId);
                this.tenant = await this.tenantService.handleTenantVersion(this.tenant.id, this.tenantVersionDto);
                this.tenantClone = _.cloneDeep(this.tenant);
                this.toastService.success('Success', 'Version is added to tenant');
                this.sidePanel.toggle();
                this.hasNewMutationsToFetch = true;
                await this.getVersions();
            } catch (err) {
                Toolkit.handleApiValidationErrors(err, this.validator, this.toastService);
            }
        } else {
            this.toastService.danger('Validation error', 'Some fields did not pass validation checks');
        }
    }

    private async deleteVersion(index: number, data: TenantApplicationVersion): Promise<void> {
        try {
            this.tenantVersionDto.state = DtoState.Deleted;
            this.tenantVersionDto.application_version_id = data.id;
            this.tenant = await this.tenantService.handleTenantVersion(this.tenant.id, this.tenantVersionDto);
            this.tenantClone = _.cloneDeep(this.tenant);
            this.toastService.success('Success', 'Version is removed from tenant');
            this.hasNewMutationsToFetch = true;
            await this.getVersions();
        } catch (err) {
            this.toastService.danger('Error', 'Error when removing version from tenant');
        }
    }

    private async buildTenantApiResourceStatusModels(): Promise<void> {
        this.isApiResourcesLoading = true;
        this.tenantApiResouceStatuses = [];
        let resources: TenantApiResourceStatus[] = [];

        for (const resource of this.tenant.resources) {
            const tenantApiResourceStatus: TenantApiResourceStatus = {
                id: resource.id,
                resource_id: resource.resource_id,
                name: resource.name,
                url: resource.url,
                status: 2,
                status_text: 'Unavailable',
                version: '',
                created_at: null,
            };

            const metric: ApiResourceMetric = await this.metricsService.getLatestApiResourceMetric(this.tenant.id, resource.resource_id);

            if (!_.isNil(metric)) {
                switch (metric.status) {
                    case ServiceStatus.Down:
                        tenantApiResourceStatus.status = 2;
                        tenantApiResourceStatus.status_text = 'Down';
                        break;

                    case ServiceStatus.Maintance:
                        tenantApiResourceStatus.status = 1;
                        tenantApiResourceStatus.status_text = 'Maintenance';
                        break;

                    case ServiceStatus.Running:
                        tenantApiResourceStatus.status = 0;
                        tenantApiResourceStatus.status_text = 'Running';
                }

                tenantApiResourceStatus.version = metric.version;
                tenantApiResourceStatus.created_at = metric.created_at;
            }

            resources.push(tenantApiResourceStatus);
        }

        resources = resources.sort((a: TenantApiResourceStatus, b: TenantApiResourceStatus) => {
            return a.id - b.id;
        });

        this.tenantApiResouceStatuses = resources;

        _.delay(() => {
            this.isApiResourcesLoading = false;
        }, 100);
    }

    private async getApiResourceMetrics(resourceId: number, rangeType: string): Promise<StatusMetric[]> {
        const apiResourceMetrics: ApiResourceMetric[] = await this.metricsService.getApiResourceMetrics(this.tenant.id, resourceId, rangeType);
        const metrics: StatusMetric[] = [];

        apiResourceMetrics.forEach((resourceMetric: ApiResourceMetric) => {
            let statusState: StatusMetricState;
            let statusMessage: string = resourceMetric.error_message;

            switch (resourceMetric.status) {
                case ServiceStatus.Down:
                    statusState = StatusMetricState.Down;
                    statusMessage = `[${Converters.formatDate(resourceMetric.error_date_time_offset, 'DD-MM-YYYY HH:mm:ss')}] ${resourceMetric.error_message}`;
                    break;

                case ServiceStatus.Maintance:
                    statusState = StatusMetricState.Maintenance;
                    break;

                case ServiceStatus.Running:
                    statusState = StatusMetricState.Running;
                    break;
            }

            metrics.push({
                time: dayjs(resourceMetric.created_at),
                status: statusState,
                status_text: statusMessage,
            });
        });

        return metrics;
    }

    private setCreateUserState(): void {
        this.sidePanelHeader = 'User';
        this.selectedUserId = '';

        this.tenantUserDto = {
            user_id: 0,
            abs_user_id: 0,
            state: DtoState.Unchanged,
        };
    }

    private setCreateResourceState(): void {
        this.sidePanelHeader = 'API Resource';
        this.selectedResourceId = '';

        this.tenantResourceDto = {
            id: 0,
            resource_id: 0,
            url: '',
            state: DtoState.Unchanged,
        };
    }

    private setCreateApplicationVersionState(): void {
        this.sidePanelHeader = 'Application Version';
        this.selectedVersionId = '';

        this.tenantVersionDto = {
            application_version_id: 0,
            state: DtoState.Unchanged,
        };
    }

    private setValidationRules(): void {
        const urlRegex = <T>(value: T): CustomValidationRuleValidateResult => {
            const val = value as string;
            const urlPattern = new RegExp(/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&\\/\\/=]*)$/);
            const isValid = urlPattern.test(val);
            const message = isValid ? '' : 'is not valid';

            return new CustomValidationRuleValidateResult(isValid, message);
        };

        this.resourceValidationRules.push(new CustomValidationRule('url', urlRegex));

        const absID = <T>(value: T): CustomValidationRuleValidateResult => {
            const val: number = value as number;
            if (val < 0) return new CustomValidationRuleValidateResult(false, 'must be a positive number');
            else return new CustomValidationRuleValidateResult(true, '');
        };

        this.userValidationRules.push(new CustomValidationRule('abs_id', absID));
    }

    private showRawMutationData(value: string): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const parsedValue: any = JSON.parse(value);
        this.rawTenantMutationData = JSON.stringify(parsedValue, null, 4);
        this.mutationSidePanel.toggle();
    }

    private IsNotDuplicate(object: string, id: string): Promise<boolean> {
        let showDialog: boolean = false;

        switch (object) {
            case 'resource':
                showDialog = this.tenant.resources.some((resource: TenantApiResource) => resource.resource_id === parseInt(id));
                break;
            default:
                break;
        }

        if (showDialog) {
            const dialogComponentRef: DialogComponentRef<ConfirmationDialogComponent> = this.dialogService.createDialog(ConfirmationDialogComponent, {
                title: `Duplicate ${object} detected`,
                text: `Are you sure you want to continue?`,
                confirmation_button_text: 'Yes',
                cancel_button_text: 'No',
                button_type: 'primary',
            });

            return new Promise<boolean>((resolve) => {
                dialogComponentRef.afterClose.subscribe((hasConfirmation: boolean) => {
                    resolve(hasConfirmation);
                });
            });
        } else {
            return Promise.resolve(true);
        }
    }

    private copyTenantName(): void {
        navigator.clipboard.writeText(this.tenant.name);
        this.toastService.feedback('Tenant name copied to clipboard!', this.splitButton.getContainerElement(), 'left');
    }

    private copyTenantCode(): void {
        navigator.clipboard.writeText(this.tenant.code);
        this.toastService.feedback('Tenant code copied to clipboard!', this.splitButton.getContainerElement(), 'left');
    }

    private copyTenantId(): void {
        navigator.clipboard.writeText(this.tenant.id.toString());
        this.toastService.feedback('Tenant ID copied to clipboard!', this.splitButton.getContainerElement(), 'left');
    }
}
