/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ComponentRef, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { angularImports } from '../../utilities/global-import';
import { AvatarComponent } from '../../components/avatar/avatar.component';
import { ContextMenuService } from '../../menus/context-menu.service';
import _ from 'lodash';
import { Router } from '@angular/router';
import { UserContextMenuComponent } from '../../menus/user-context-menu/user-context-menu.component';
import { AuthorizationService } from '../../services/authorization.service';
import { User } from '../../models/user/user';
import { SidePanelComponent } from '../../components/side-panel/side-panel.component';
import { ButtonDirective } from '../../directives/button.directive';
import { NavigationMenuItem } from './interfaces/navigation-menu-item';
import { InputDirective } from '../../directives/input.directive';
import { SearchComponent } from '../../components/search/search.component';
import { SearchContextMenuComponent } from '../../menus/search-context-menu/search-context-menu.component';
import { BaseContextMenuComponent } from '../../menus/base-context-menu/base-context-menu.component';
import { SearchService } from '../../services/search.service';
import { SearchResult } from '../../models/search/search-result';
import { NotificationService } from '../../services/notification.service';
import { Notification } from '../../models/notification/notification';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { TimeAgoPipe } from '../../pipes/timeago.pipe';

@Component({
    standalone: true,
    selector: 'ax-page-template',
    templateUrl: './page-template.component.html',
    styleUrl: './page-template.component.scss',
    imports: [angularImports, AvatarComponent, SidePanelComponent, SearchComponent, ButtonDirective, InputDirective, TooltipDirective, TimeAgoPipe],
})
export class PageTemplateComponent implements OnInit {
    private user: User;
    private menu: ComponentRef<BaseContextMenuComponent>;

    protected navigationItems: NavigationMenuItem[] = [];
    protected notifications: Notification[] = [];
    protected isUserContextSet: boolean = false;
    protected userName: string = '';
    protected avatarUrl: string | null = null;
    protected searchValue: string = '';

    @ViewChild('navigationPanel') private readonly navigationPanel: SidePanelComponent;
    @ViewChild('notificationPanel') private readonly notificationPanel: SidePanelComponent;
    @ViewChild(SearchComponent) private readonly searchComponent: SearchComponent;
    @ViewChild('searchElement') private readonly searchElement: ElementRef<HTMLDivElement>;
    @ViewChildren(TooltipDirective) private readonly tooltips: TooltipDirective[];

    constructor(
        private readonly contextMenuService: ContextMenuService,
        private readonly router: Router,
        private readonly authorizationService: AuthorizationService,
        private readonly searchService: SearchService,
        private readonly notificationService: NotificationService
    ) {}

    async ngOnInit(): Promise<void> {
        this.authorizationService.onAuthorizationSuccess.subscribe(async () => {
            await this.setUserContext();
            await this.buildNavigationMenuItems();
            await this.getNotifications();
        });

        this.authorizationService.onUserReloaded.subscribe(async () => {
            await this.setUserContext();
        });

        if (this.isAuthenticated()) {
            await this.setUserContext();
            await this.buildNavigationMenuItems();
            await this.getNotifications();
        }
    }

    public async toggleSearchMenu(event: Event): Promise<void> {
        event.stopPropagation();

        if (_.isNil(this.menu)) {
            this.contextMenuService.closeAllMenus();

            const searchResults: SearchResult[] = await this.searchService.get(this.searchValue);
            this.searchComponent.setFocus();

            this.menu = this.contextMenuService.toggleContextMenu('page-search-menu', SearchContextMenuComponent, searchResults, this.searchElement.nativeElement, {
                position: 'bottom',
                elementSpacing: 8,
            });
        }

        this.menu.instance.onDispose.subscribe(() => {
            this.menu = undefined;
        });
    }

    protected isAuthenticated(): boolean {
        return this.authorizationService.isAuthenticated();
    }

    protected toggleUsercontextMenu(event: Event, menuElement: HTMLElement): void {
        event.stopPropagation();

        this.contextMenuService.closeAllMenus();

        this.contextMenuService.toggleContextMenu('user-context-menu', UserContextMenuComponent, null, menuElement, {
            elementSpacing: 8,
            position: 'bottom',
        });
    }

    protected hasPermission(permissionToCheck: string): boolean {
        if (this.isAdmin()) {
            return true;
        }

        const foundPermission: string | undefined = this.user.permissions.find((perm: string) => perm === permissionToCheck);

        if (!_.isNil(foundPermission) && !_.isEmpty(foundPermission)) {
            return true;
        }

        return false;
    }

    protected isAdmin(): boolean {
        return this.user.is_admin;
    }

    protected toggleNavigationMenu(event: Event): void {
        event.stopPropagation();

        this.contextMenuService.closeAllMenus();

        this.navigationPanel.toggle();
    }

    protected toggleNotificationPanel(event: Event): void {
        event.stopPropagation();

        this.contextMenuService.closeAllMenus();

        this.notificationPanel.toggle();
    }

    protected handleNavigation(url: string): void {
        this.navigationPanel.toggle();
        this.router.navigateByUrl(url);
    }

    protected constructNotificationMessage(notification: Notification): string {
        let message: string = '';

        if (notification.description.includes('|')) {
            message = notification.description.split('|')[0];
        } else {
            message = notification.description;
        }

        if (!_.isNil(notification.type) && !_.isEmpty(notification.type)) {
            switch (notification.type) {
                case 'Tenant':
                    if (notification.description.includes('[deep_link_display_name]')) {
                        const tenantName: string = notification.description.split('|')[1].trim();
                        return `<span>${message.replace('[deep_link_display_name]', `<a href='tenant/${notification.entity_id}'>${tenantName}</a>`)}</span>`;
                    } else {
                        return message;
                    }

                default:
                    return message;
            }
        } else {
            return message;
        }
    }

    protected getUnreadNotificationCount(): number {
        return this.notifications.filter((x: Notification) => x.read_at === null).length;
    }

    protected async markNotificationAsRead(notification: Notification): Promise<void> {
        this.tooltips.forEach((tooltip: TooltipDirective) => {
            tooltip.deleteTooltip();
        });

        await this.notificationService.markAsRead(notification.id);
        notification.read_at = new Date();
    }

    protected async markAllNotificationsAsRead(): Promise<void> {
        await this.notificationService.markAllAsRead();

        this.notifications.forEach((notification: Notification) => {
            notification.read_at = new Date();
        });
    }

    protected async deleteAllNotifications(): Promise<void> {
        await this.notificationService.deleteAll();
        await this.getNotifications();
    }

    protected async onSearch(searchValue: string): Promise<void> {
        const searchResults: SearchResult[] = await this.searchService.get(searchValue);

        if (this.menu?.instance?.getChildComponent<SearchContextMenuComponent>() !== undefined) {
            this.menu.instance.getChildComponent<SearchContextMenuComponent>().updateSearchResults(searchResults);
        } else {
            this.menu = this.contextMenuService.toggleContextMenu('page-search-menu', SearchContextMenuComponent, searchResults, this.searchElement.nativeElement, {
                position: 'bottom',
                elementSpacing: 8,
            });

            this.menu.instance.onDispose.subscribe(() => {
                this.menu = undefined;
            });
        }
    }

    private async setUserContext(): Promise<void> {
        this.user = await this.authorizationService.getUser();
        this.userName = `${this.user.firstname} ${this.user.lastname}`;
        this.avatarUrl = this.user.avatar_url;

        this.isUserContextSet = true;
    }

    private async buildNavigationMenuItems(): Promise<void> {
        this.navigationItems = [];

        if (await this.authorizationService.hasPermission('Tenants.Read')) {
            this.navigationItems.push({
                route: '/tenants',
                title: 'Tenants',
                icon_class: 'fa-regular fa-buildings',
                child_routes: [],
            });
        }

        if (this.isAdmin()) {
            this.navigationItems.push({
                route: '/users',
                title: 'Users',
                icon_class: 'fa-regular fa-users',
                child_routes: [],
            });
        }
    }

    private async getNotifications(): Promise<void> {
        this.notifications = await this.notificationService.get();
    }
}
