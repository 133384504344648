import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import _ from 'lodash';
import { AuthorizationService } from '../services/authorization.service';
import { inject } from '@angular/core';

export const permissionGuard: CanActivateFn = async (route: ActivatedRouteSnapshot) => {
    const permission: string | undefined | null = route.data['permission'] as string;

    if (_.isNil(permission) || _.isEmpty(permission)) {
        throw new Error('Permission is required');
    }

    const authorizationService: AuthorizationService = inject(AuthorizationService);
    const router: Router = inject(Router);

    const hasPermission: boolean = await authorizationService.hasPermission(permission);

    if (!hasPermission) {
        router.navigateByUrl('/forbidden');
        return false;
    }

    return true;
};
