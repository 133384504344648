/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { angularImports } from '../../../../utilities/global-import';
import _ from 'lodash';

@Component({
    standalone: true,
    selector: 'ax-table-column-action',
    templateUrl: './table-column-action.component.html',
    styleUrl: 'table-column-action.component.scss',
    imports: [angularImports],
})
export class TableColumnActionComponent implements OnInit {
    @Input() public displayValue: string = '';
    @Input() public noDataText: string = 'No data';
    @Input() public value: any | undefined = undefined;
    @Input() public callback: (value: any) => void;

    protected isValidCallback: boolean = false;

    ngOnInit(): void {
        if (!_.isNil(this.callback) && _.isFunction(this.callback)) {
            this.isValidCallback = true;
        }
    }

    protected handleCallback(event: Event): void {
        event.stopPropagation();

        this.callback(this.value);
    }
}
