import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { angularImports } from '../../utilities/global-import';
import { SelectComponent } from '../select/select.component';
import { SelectOption } from '../select/interfaces/select-option';

@Component({
    standalone: true,
    selector: 'ax-pagination',
    templateUrl: './pagination.component.html',
    styleUrl: './pagination.component.scss',
    imports: [angularImports, SelectComponent],
})
export class PaginationComponent implements OnInit {
    @Input() public totalRecordCount: number = 0;
    @Input() public recordsPerPageOptions: number[] = [10, 25, 50, 100];
    @Input() public selectedPage: number = 1;
    @Input() public selectedRecordsPerPageOption: number = 10;
    @Output() public selectedPageChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() public selectedRecordsPerPageOptionChange: EventEmitter<number> = new EventEmitter<number>();

    protected pageSizeOptions: SelectOption[] = [];
    protected pages: number[] = [];

    ngOnInit(): void {
        this.recordsPerPageOptions.forEach((option: number) => {
            this.pageSizeOptions.push({
                display_value: option.toString(),
                value: option.toString(),
            });
        });

        this.buildPageNumberOptions();
    }

    public buildPageNumberOptions(): void {
        let amountOfPages: number = Math.ceil(this.totalRecordCount / this.selectedRecordsPerPageOption);

        if (amountOfPages === 0) {
            amountOfPages = 1;
        }

        this.pages = [];

        for (let i: number = 0; i < amountOfPages; i++) {
            this.pages.push(i + 1);
        }
    }

    protected getSelectedRecordsPerPageOption(): string {
        return this.selectedRecordsPerPageOption.toString();
    }

    protected setSelectedRecordsPerPageOption(value: string): void {
        this.selectedRecordsPerPageOption = parseInt(value);
        this.selectedRecordsPerPageOptionChange.emit(this.selectedRecordsPerPageOption);
        this.buildPageNumberOptions();
    }

    protected next(): void {
        const lastPageNumber: number = this.pages[this.pages.length - 1];

        if (this.selectedPage === lastPageNumber) {
            return;
        } else {
            this.selectedPage = this.selectedPage + 1;
            this.selectedPageChange.emit(this.selectedPage);
        }
    }

    protected previous(): void {
        const firstPageNumber: number = this.pages[0];

        if (this.selectedPage === firstPageNumber) {
            return;
        } else {
            this.selectedPage = this.selectedPage - 1;
            this.selectedPageChange.emit(this.selectedPage);
        }
    }

    protected selectPage(page: number): void {
        if (this.selectedPage === page) {
            return;
        } else {
            this.selectedPage = page;
            this.selectedPageChange.emit(this.selectedPage);
        }
    }
}
