import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { SearchResult } from "../models/search/search-result";
import _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class SearchService{
    constructor(
        private readonly httpClient: HttpClient,
    ) {
    }

    async get(searchValue?: string): Promise<SearchResult[]>
    {
        let value: string = '';
        if(!_.isNil(searchValue) && !_.isEmpty(searchValue)){
            value = searchValue;
        }

        const params: HttpParams = new HttpParams().set('searchValue', value);

        return firstValueFrom(this.httpClient.get<SearchResult[]>(`search`, {params}));
    }
}