import { Component, EventEmitter, Input, Output } from '@angular/core';
import { angularImports } from '../../../../utilities/global-import';
import { AvatarComponent } from '../../../avatar/avatar.component';

@Component({
    standalone: true,
    selector: 'ax-table-column-name-and-avatar',
    templateUrl: './table-column-name-and-avatar.component.html',
    styleUrl: './table-column-name-and-avatar.component.scss',
    imports: [angularImports, AvatarComponent],
})
export class TableColumnNameAndAvatarComponent {
    @Input() public avatarUrl: string | null = null;
    @Input() public firstname: string = '';
    @Input() public lastname: string = '';
    @Input() public isNavigatable: boolean = false;
    @Output() public handleNavigation: EventEmitter<Event> = new EventEmitter<Event>();

    protected getName(): string {
        return `${this.firstname} ${this.lastname}`;
    }

    protected navigate(event: Event): void {
        event.stopPropagation();

        if (this.isNavigatable) {
            this.handleNavigation.next(event);
        }
    }
}
