/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { Observable, Subject } from 'rxjs';

export class StateObject {
    private readonly key: string;
    private readonly evictionCallback: Subject<string> = new Subject<string>();
    private readonly expirationInMinutes: number = -1;
    private readonly absoluteExpirationInMinutes: number = -1;
    private readonly isSlidingExpirationEnabled: boolean = false;

    private stateItems: Map<string, any>;
    private slidingExpirationTimer: number = -1;
    private absoluteExpirationTimer: number = -1;

    public readonly onEviction: Observable<string> = this.evictionCallback.asObservable();

    constructor(key: string, items: Map<string, any>, expirationInMinutes: number, aboluteExpirationInMinutes: number, isSlidingExpirationEnabled: boolean) {
        if (_.isNil(key) || _.isEmpty(key)) {
            throw new Error('Key cannot be null, undefined or empty');
        }

        if (_.isNil(items) || _.isEmpty(items)) {
            throw new Error('Items cannot be null, undefined or empty');
        }

        if (isSlidingExpirationEnabled) {
            if (!_.isFinite(expirationInMinutes) || expirationInMinutes <= 0) {
                throw new Error('Expiration must have a value greater than 0');
            }
        }

        if (!_.isFinite(aboluteExpirationInMinutes) || aboluteExpirationInMinutes <= 0) {
            throw new Error('Absolute expiration must have a value greater than 0');
        }

        this.key = key;
        this.stateItems = items;
        this.expirationInMinutes = expirationInMinutes;
        this.absoluteExpirationInMinutes = aboluteExpirationInMinutes;
        this.isSlidingExpirationEnabled = isSlidingExpirationEnabled;

        // Absolute expiration
        this.absoluteExpirationTimer = _.delay(() => {
            this.evictionCallback.next(this.key);
            this.dispose();
        }, this.absoluteExpirationInMinutes * 60000);

        // Sliding expiration
        if (isSlidingExpirationEnabled) {
            this.slidingExpirationTimer = _.delay(() => {
                this.evictionCallback.next(this.key);
                this.dispose();
            }, this.expirationInMinutes * 60000);
        }
    }

    getValue(key: string): any | null {
        const stateItem: any | undefined = this.stateItems.get(key);

        if (_.isNil(stateItem)) {
            return null;
        } else {
            return _.cloneDeep(stateItem);
        }
    }

    update(items: Map<string, any>): void {
        if (_.isNil(items) || _.isEmpty(items)) {
            throw new Error('Items cannot be null, undefined or empty');
        }

        this.stateItems = items;
        this.slideExpiration();
    }

    private slideExpiration(): void {
        if (this.isSlidingExpirationEnabled) {
            clearTimeout(this.slidingExpirationTimer);

            this.slidingExpirationTimer = _.delay(() => {
                this.evictionCallback.next(this.key);
                this.dispose();
            }, this.expirationInMinutes * 60000);
        }
    }

    private dispose(): void {
        clearTimeout(this.slidingExpirationTimer);
        clearTimeout(this.absoluteExpirationTimer);
        this.stateItems.clear();
    }
}
