import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class PageTitleStrategy extends TitleStrategy {
    constructor(private readonly title: Title) {
        super();
    }

    override updateTitle(snapshot: RouterStateSnapshot): void {
        const constructedTitle: string | undefined = this.buildTitle(snapshot);

        if (!_.isNil(constructedTitle) && !_.isEmpty(constructedTitle)) {
            this.title.setTitle(`Axerrio Internal | ${constructedTitle}`);
        } else {
            this.title.setTitle('Axerrio Internal');
        }
    }
}
