<div class="tenant-page-overview__container">
    <div class="action-bar">
        <div class="action-bar__title">
            <h3>Tenants</h3>
            <small>{{ getRecordCountText() }}</small>
        </div>
        <div class="action-bar__actions">
            @if (hasCreatePermission) {
                <button [ax-button]="'primary'" (click)="toggleTenantCreatePanel()">
                    Create
                    <i class="fa-light fa-plus"></i>
                </button>
            }
            <button [ax-button]="'primary'" (click)="toggleFilterPanel()">
                @if (activeFilterCount !== 0) {
                    <span class="ax-button__label">Filters ({{ activeFilterCount }})</span>
                } @else {
                    <span class="ax-button__label">Filters</span>
                }
                <i class="fa-solid fa-filters"></i>
            </button>
        </div>
    </div>
    <ax-table
        [isLoading]="isLoading"
        [columns]="tableColums"
        [data]="tableData"
        [loaderText]="'Fetching tenant data'"
        [noDataText]="'No tenants found'"
        [isInfiniteScrollEnabled]="true"
        [(scrollPosition)]="tableScrollPosition"
        (sort)="onSort($event)"
        (scrollTrigger)="onScroll()"
    >
    </ax-table>
    <ax-side-panel #filterSidePanel [orientation]="'right'">
        <div class="ax-filter-panel__container">
            <div class="filter-panel__header">
                <h3>Filters</h3>
                <button [ax-button] [icon-only-small]="true" [transparent]="true" (click)="toggleFilterPanel()">
                    <i class="fa-regular fa-xmark"></i>
                </button>
            </div>
            <div class="filter-panel__content">
                <div class="filter-panel__content__headers">
                    <div class="filter-panel__content__headers__header">
                        <span>Property</span>
                    </div>
                    <div class="filter-panel__content__headers__header">
                        <span>Condition</span>
                    </div>
                    <div class="filter-panel__content__headers__header">
                        <span>Value</span>
                    </div>
                </div>
                @for (filter of tenantFilterDto.filters; track filter.property_name) {
                    <div class="filter-panel__content__filter-row" [ngClass]="$index === tenantFilterDto.filters.length - 1 ? 'filter-panel__content__filter-row--last' : ''">
                        <div class="filter-panel__content__filter-row__input">
                            <ax-select
                                [isInline]="true"
                                [placeholder]="'Select a property'"
                                [options]="getPropertyOptions(filter)"
                                [(value)]="filter.property_name"
                                (valueChange)="setValueType($event, filter)"
                                [noOptionsText]="'No properties available'"
                            >
                            </ax-select>
                        </div>
                        <div class="filter-panel__content__filter-row__input" [ngClass]="hasValidPropertyNameSet(filter) ? '' : 'filter-panel__content__filter-row__input--disabled'">
                            <ax-select [isInline]="true" [placeholder]="'Select a condition'" [options]="getConditionOptions(filter)" [(value)]="filter.condition" [noOptionsText]="'No condition available'"> </ax-select>
                        </div>
                        <div class="filter-panel__content__filter-row__input" [ngClass]="hasValidConditionSet(filter) ? '' : 'filter-panel__content__filter-row__input--disabled'">
                            @switch (filter.value_type) {
                                @case ('string') {
                                    <input type="text" placeholder="Enter a value" class="ax-inline-input" [(ngModel)]="filter.value" />
                                }

                                @case ('state_enum') {
                                    <ax-select [isInline]="true" [placeholder]="'Select a state'" [options]="stateOptions" [(value)]="filter.value"></ax-select>
                                }

                                @case ('date') {
                                    <ax-date-picker [isInline]="true" [placeholder]="'Select a date'" [firstDateValue]="displayDate(filter.value)" (dateSelected)="dateChanged($event, filter)"></ax-date-picker>
                                }

                                @default {
                                    <input type="text" placeholder="Enter a value" class="ax-inline-input" [(ngModel)]="filter.value" />
                                }
                            }
                        </div>
                        <div class="filter-panel__content__filter-row__input filter-panel__content__filter-row__input--remove" (click)="removeFitler($index)">
                            <i class="fa-regular fa-trash-can-xmark"></i>
                        </div>
                    </div>
                } @empty {
                    <div class="filter-panel__content-no-filters">
                        <h6>No filters are added</h6>
                    </div>
                }
                <div class="filter-panel__content__actions">
                    <button [ax-button]="'primary'" (click)="addFilterRow()" [disabled]="tenantFilterDto.filters.length === propertyOptions.length">
                        Add filter
                        <i class="fa-light fa-plus"></i>
                    </button>
                </div>
            </div>
            <div class="filter-panel__actions">
                <button [ax-button]="'primary'" (click)="applyFilters()" [disabled]="!hasValidFilters()">Apply filters</button>
                <button ax-button (click)="reset()">Reset</button>
            </div>
        </div>
    </ax-side-panel>
    <ax-side-panel #tenantCreateSidePanel [orientation]="'right'" (isPanelVisibleChange)="resetValidationState()">
        <div class="ax-tenant-create__container">
            <div class="ax-tenant-create__header">
                <h3>Create Tenant</h3>
                <button [ax-button] [icon-only-small]="true" [transparent]="true" (click)="toggleTenantCreatePanel()">
                    <i class="fa-regular fa-xmark"></i>
                </button>
            </div>
            <div class="ax-tenant-create__content">
                <div class="ax-tenant-create__content__block">
                    <span>Name</span>
                    <input
                        ax-input
                        type="text"
                        [placeholder]="'Axerrio Business Solutions'"
                        required
                        ax-validate="name"
                        minlength="3"
                        maxlength="80"
                        [(ngModel)]="tenantDto.name">
                    <small class="ax-tenant-create__content__block__error" ax-validate-error="name"></small>
                </div>
                <div class="ax-tenant-create__content__block">
                    <span>Code</span>
                    <input
                        ax-input
                        type="text"
                        [placeholder]="'AXE'"
                        required
                        ax-validate="code"
                        minlength="3"
                        maxlength="3"
                        [(ngModel)]="tenantDto.code">
                    <small class="ax-tenant-create__content__block__error" ax-validate-error="code"></small>
                </div>
            </div>
            <div class="ax-tenant-create__actions">
                <button [ax-button]="'primary'" (click)="createTenant()">Create</button>
                <button ax-button (click)="toggleTenantCreatePanel()">Cancel</button>
            </div>
        </div>
    </ax-side-panel>
</div>
