import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Notification } from '../models/notification/notification';
import { firstValueFrom } from 'rxjs';
import _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly router: Router
    ) {}

    async get(): Promise<Notification[]> {
        return firstValueFrom(this.httpClient.get<Notification[]>('notification'));
    }

    async markAsRead(id: number): Promise<void> {
        firstValueFrom(this.httpClient.put<void>(`notification/read/${id}`, null));
    }

    async markAllAsRead(): Promise<void> {
        firstValueFrom(this.httpClient.put<void>('notification/read', null));
    }

    async delete(id: number): Promise<void> {
        firstValueFrom(this.httpClient.delete<void>(`notification/${id}`));
    }

    async deleteAll(): Promise<void> {
        firstValueFrom(this.httpClient.delete<void>('notification'));
    }

    sendNotification(title: string, body: string, url: string | null): void {
        if (this.isPermissionGranted()) {
            if (!_.isNil(url) && !_.isEmpty(url)) {
                new Notification(title, {
                    body: body,
                    icon: 'assets/icons/platform/windows11/Square150x150Logo.scale-100.png',
                }).onclick = (event: any) => {
                    event.preventDefault();
                    this.router.navigateByUrl(url);
                };
            } else {
                new Notification(title, {
                    body: body,
                    icon: 'assets/icons/platform/windows11/Square150x150Logo.scale-100.png',
                });
            }
        }
    }

    private isPermissionGranted(): boolean {
        if (Notification.permission === 'granted') {
            return true;
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    return true;
                }
                return false;
            });
        }
        return false;
    }
}
