<div #splitButtonContainer class="ax-split-button__container">
    <button [ax-button]="'primary'" class="ax-split-button__primary" (click)="handlePrimaryButtonClick($event)">
        <span class="ax-button__label">{{primaryButtonText}}</span>
        @if (hasIconClassSet()) {
            <i class="ax-button__icon" [ngClass]="primaryButtonIconClass"></i>
        }
    </button>
    <button [ax-button]="'primary'" [icon-only]="true" class="ax-split-button__toggle" (click)="toggleSplitContextMenu($event)">
        <i class="fa-solid fa-caret-down"></i>
    </button>
</div>