import { Component } from '@angular/core';

@Component({
    standalone: true,
    selector: 'ax-home-page',
    templateUrl: './home-page.component.html',
    styleUrl: './home-page.component.scss',
    imports: [],
})
export class HomePageComponent {}
