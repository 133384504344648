import { Component, OnInit, Inject } from '@angular/core';
import { angularImports } from '../../utilities/global-import';
import { BaseContextMenuComponent } from '../base-context-menu/base-context-menu.component';
import { ButtonDirective } from '../../directives/button.directive';
import { DatePickerType } from './interfaces/date-picker-type';
import { Converters } from '../../utilities/converters';
import { CONTEXT_MENU_DATA } from '../interfaces/contex-menu-config';
import { DatePickerData } from './interfaces/date-picker-data';
import _ from 'lodash';

@Component({
    selector: 'ax-date-picker-menu',
    templateUrl: './date-picker-menu.component.html',
    styleUrl: './date-picker-menu.component.scss',
    standalone: true,
    imports: [angularImports, ButtonDirective],
})
export class DatePickerMenuComponent implements OnInit {
    protected visibleSection: string = 'dates';
    protected days: number[] = [];
    protected months: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    protected years: number[] = [];

    protected displayMonths: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    protected displayedYear: number = -1;
    protected displayedMonth: number = -1;

    protected hoveredDate: Date | undefined;
    protected type: DatePickerType = DatePickerType.Default;

    protected dateOfToday: Date = new Date();

    private hasStartDateSet: boolean = false;

    private startYear: number = 2022;

    private selectedFirstDate: Date | undefined;
    private selectedSecondDate: Date | undefined;

    private firstDateValue: string = '';
    private secondDateValue: string = '';

    private dateValues: string[] = [];

    constructor(
        @Inject(BaseContextMenuComponent) private readonly baseContextMenu: BaseContextMenuComponent,
        @Inject(CONTEXT_MENU_DATA) private readonly data: DatePickerData
    ) {
        this.type = data.type;
        this.firstDateValue = data.first_date_value;
        this.secondDateValue = data.second_date_value;
    }

    ngOnInit(): void {
        this.setNextYears();

        let hasDatesSet: boolean = false;

        if (!_.isEmpty(this.firstDateValue)) {
            this.selectedFirstDate = Converters.toDateObject(this.firstDateValue);
            hasDatesSet = true;
        }

        if (!_.isEmpty(this.secondDateValue)) {
            this.selectedSecondDate = Converters.toDateObject(this.secondDateValue);
            hasDatesSet = true;
        }

        let currentDate: Date = new Date();

        if (hasDatesSet) {
            currentDate = this.selectedFirstDate;
        }

        this.displayedYear = currentDate.getFullYear();
        this.displayedMonth = this.months[currentDate.getMonth()];

        this.setDayStartPosition();
    }

    protected handlePrevious(event: Event): void {
        event.stopPropagation();

        switch (this.visibleSection) {
            case 'dates':
                this.setPreviousMonth();
                break;

            case 'months':
                this.setPreviousYear();
                break;

            case 'years':
                this.setPreviousYears();
                break;

            default:
                throw new Error(`${this.visibleSection} is not implemented`);
        }
    }

    protected handleNext(event: Event): void {
        event.stopPropagation();

        switch (this.visibleSection) {
            case 'dates':
                this.setNextMonth();
                break;

            case 'months':
                this.setNextYear();
                break;

            case 'years':
                this.setNextYears();
                break;

            default:
                throw new Error(`${this.visibleSection} is not implemented`);
        }
    }

    protected setVisibleSection(section: string): void {
        this.visibleSection = section;
    }

    protected setDayClasses(day: number): string {
        let classList: string = '';

        if (this.dateOfToday.getDate() === day && this.dateOfToday.getMonth() === this.displayedMonth && this.dateOfToday.getFullYear() === this.displayedYear) {
            classList = 'ax-date-picker-menu__dates__days__day--today';
        }

        if (this.isDaySelected(day)) {
            classList = `${classList} ax-date-picker-menu__dates__days__day--selected`;
        }

        if (this.isDaySelectedInRangeOnly(day)) {
            classList = `${classList} ax-date-picker-menu__dates__days__day--selected-range`;
        }

        if (this.isFirstSelectedDayInRangeOnly(day)) {
            classList = `${classList} ax-date-picker-menu__dates__days__day--first-selected`;
        }

        if (this.isLastSelectedDayInRangeOnly(day)) {
            classList = `${classList} ax-date-picker-menu__dates__days__day--last-selected`;
        }

        if (this.type === DatePickerType.Range && this.selectedSecondDate === undefined && this.isDaySelected(day) && this.hoveredDate !== undefined && this.hoveredDate.getTime() > this.selectedFirstDate.getTime()) {
            classList = `${classList} ax-date-picker-menu__dates__days__day--first-selected`;
        }

        if (
            this.type === DatePickerType.Range &&
            this.selectedSecondDate === undefined &&
            this.selectedFirstDate !== undefined &&
            this.hoveredDate !== undefined &&
            this.hoveredDate.getDate() === day &&
            this.hoveredDate.getDate() !== this.selectedFirstDate.getDate()
        ) {
            classList = `${classList} ax-date-picker-menu__dates__days__day--last-pre-selected`;
        }

        if (this.isDayInRange(day)) {
            classList = `${classList} ax-date-picker-menu__dates__days__day--pre-selected`;
        }

        if (this.isDayDisabled(day)) {
            classList = `${classList} ax-date-picker-menu__dates__days__day--disabled`;
        }

        return classList;
    }

    protected setMonthClasses(month: number): string {
        let classList: string = '';

        if (this.dateOfToday.getMonth() === month) {
            classList = 'ax-date-picker-menu__months__month--current';
        }

        return classList;
    }

    protected setYearClasses(year: number): string {
        let classList: string = '';

        if (this.dateOfToday.getFullYear() === year) {
            classList = 'ax-date-picker-menu__years__year--current';
        }

        return classList;
    }

    protected setHoveredDate(day: number): void {
        this.hoveredDate = new Date(this.displayedYear, this.displayedMonth, day);
    }

    protected clearHoveredDate(): void {
        this.hoveredDate = undefined;
    }

    protected selectDay(event: Event, day: number): void {
        event.stopPropagation();

        if (this.type === DatePickerType.Default) {
            this.selectedFirstDate = new Date(this.displayedYear, this.displayedMonth, day);
            this.firstDateValue = Converters.formatDate(this.selectedFirstDate);

            this.dateValues = [];
            this.dateValues.push(this.firstDateValue);
            this.baseContextMenu.close(this.dateValues);
        }

        if (this.type === DatePickerType.Range) {
            if (!this.hasStartDateSet) {
                this.selectedSecondDate = undefined;
                this.secondDateValue = '';

                this.selectedFirstDate = new Date(this.displayedYear, this.displayedMonth, day);
                this.firstDateValue = Converters.formatDate(this.selectedFirstDate);
                this.hasStartDateSet = true;

                this.dateValues = [];
                this.dateValues.push(this.firstDateValue);
            } else {
                this.selectedSecondDate = new Date(this.displayedYear, this.displayedMonth, day);
                this.secondDateValue = Converters.formatDate(this.selectedSecondDate);
                this.hasStartDateSet = false;

                this.dateValues.push(this.secondDateValue);
                this.baseContextMenu.close(this.dateValues);
            }
        }
    }

    protected selectMonth(month: number): void {
        this.displayedMonth = month;
        this.visibleSection = 'dates';

        this.setDayStartPosition();
    }

    protected selectYear(year: number): void {
        this.displayedYear = year;
        this.visibleSection = 'months';

        this.setDayStartPosition();
    }

    private isDaySelected(day: number): boolean {
        const constructedDate: Date = new Date(this.displayedYear, this.displayedMonth, day);

        if (this.type === DatePickerType.Default) {
            if (this.selectedFirstDate !== undefined && this.selectedFirstDate.getTime() === constructedDate.getTime()) {
                return true;
            }
        }

        if (this.type === DatePickerType.Range) {
            const isInRange: boolean =
                this.selectedFirstDate !== undefined && this.selectedSecondDate !== undefined && constructedDate.getTime() > this.selectedFirstDate.getTime() && constructedDate.getTime() < this.selectedSecondDate.getTime();

            const isFirstDateMatch: boolean = this.selectedFirstDate !== undefined && this.selectedFirstDate.getTime() === constructedDate.getTime();
            const isSecondDateMatch: boolean = this.selectedSecondDate !== undefined && this.selectedSecondDate.getTime() === constructedDate.getTime();

            if (isFirstDateMatch || isSecondDateMatch || isInRange) {
                return true;
            }
        }

        return false;
    }

    private isDaySelectedInRangeOnly(day: number): boolean {
        if (this.type === DatePickerType.Range) {
            const constructedDate: Date = new Date(this.displayedYear, this.displayedMonth, day);
            return this.selectedFirstDate !== undefined && this.selectedSecondDate !== undefined && constructedDate.getTime() > this.selectedFirstDate.getTime() && constructedDate.getTime() < this.selectedSecondDate.getTime();
        }

        return false;
    }

    private isFirstSelectedDayInRangeOnly(day: number): boolean {
        if (this.type === DatePickerType.Range) {
            const constructedDate: Date = new Date(this.displayedYear, this.displayedMonth, day);
            return this.selectedFirstDate !== undefined && this.selectedFirstDate.getTime() === constructedDate.getTime();
        }

        return false;
    }

    private isLastSelectedDayInRangeOnly(day: number): boolean {
        if (this.type === DatePickerType.Range) {
            const constructedDate: Date = new Date(this.displayedYear, this.displayedMonth, day);
            return this.selectedSecondDate !== undefined && this.selectedSecondDate.getTime() === constructedDate.getTime();
        }

        return false;
    }

    private isDayInRange(day: number): boolean {
        if (this.type === DatePickerType.Range && this.selectedFirstDate !== undefined && this.selectedSecondDate === undefined && this.hoveredDate !== undefined) {
            const constrcutedDate: Date = new Date(this.displayedYear, this.displayedMonth, day);

            if (this.hoveredDate.getTime() > constrcutedDate.getTime() && constrcutedDate.getTime() > this.selectedFirstDate.getTime()) {
                return true;
            }
        }

        return false;
    }

    private isDayDisabled(day: number): boolean {
        if (this.type === DatePickerType.Range && this.selectedFirstDate !== undefined && this.selectedSecondDate === undefined) {
            const constrcutedDate: Date = new Date(this.displayedYear, this.displayedMonth, day);

            if (constrcutedDate.getTime() < this.selectedFirstDate.getTime()) {
                return true;
            }
        }

        return false;
    }

    private setNextMonth(): void {
        const monthIndex: number = this.months.indexOf(this.displayedMonth);

        if (monthIndex === 11) {
            this.displayedYear++;
            this.displayedMonth = this.months[0];

            if (this.displayedYear > this.startYear) {
                this.setNextYears();
            }
        } else {
            this.displayedMonth = this.months[monthIndex + 1];
        }

        this.setDayStartPosition();
    }

    private setPreviousMonth(): void {
        const monthIndex: number = this.months.indexOf(this.displayedMonth);

        if (monthIndex === 0) {
            this.displayedYear--;
            this.displayedMonth = this.months[11];

            if (this.displayedYear < this.years[0]) {
                this.setPreviousYears();
            }
        } else {
            this.displayedMonth = this.months[monthIndex - 1];
        }

        this.setDayStartPosition();
    }

    private setNextYear(): void {
        this.displayedYear++;

        if (this.displayedYear > this.startYear) {
            this.setNextYears();
        }
    }

    private setPreviousYear(): void {
        this.displayedYear--;

        if (this.displayedYear < this.years[0]) {
            this.setPreviousYears();
        }
    }

    private setNextYears(): void {
        let newYear: number = this.startYear;
        this.years = [];

        for (let i = 0; i < 24; i++) {
            newYear++;
            this.years.push(newYear);
        }

        this.startYear = newYear;
    }

    private setPreviousYears(): void {
        let newYear: number = this.years[0];
        this.years = [];

        for (let i = 0; i < 24; i++) {
            newYear--;
            this.years.unshift(newYear);
        }

        this.startYear = this.years[23];
    }

    private setDayStartPosition(): void {
        const month: number = this.months.indexOf(this.displayedMonth);
        const newDate: Date = new Date(this.displayedYear, month);
        const curentDateForDays: Date = new Date(this.displayedYear, month + 1, 0);

        this.days = [];

        for (let i = 1; i < curentDateForDays.getDate() + 1; i++) {
            this.days.push(i);
        }

        const startPosition: number = newDate.getDay() === 0 ? 7 : newDate.getDay();

        window.document.documentElement.style.setProperty('--ax__date__picker__day__start', `${startPosition} / ${startPosition + 1}`);
    }
}
