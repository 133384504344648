import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { angularImports } from '../../utilities/global-import';

@Component({
    standalone: true,
    selector: 'ax-collapse-container',
    templateUrl: './collapse-container.component.html',
    styleUrl: './collapse-container.component.scss',
    imports: [angularImports],
})
export class CollapseContainerComponent {
    @Input() public headerText: string = 'Header';
    @Input() public isCollapsed: boolean = true;
    @Output() public isCollapsedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('collapseContentContainer') private readonly collapseContentContainer: ElementRef<HTMLDivElement>;

    protected toggleCollapse(): void {
        this.isCollapsed = !this.isCollapsed;

        this.isCollapsedChange.emit(this.isCollapsed);
    }
}
