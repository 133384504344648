import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { angularImports } from '../../utilities/global-import';
import { BaseContextMenuComponent } from '../base-context-menu/base-context-menu.component';
import { CONTEXT_MENU_DATA } from '../interfaces/contex-menu-config';
import { SelectOption } from '../../components/select/interfaces/select-option';
import { SelectOptionsMenuData } from './interfaces/select-options-menu-data';

@Component({
    standalone: true,
    selector: 'ax-select-options-menu',
    templateUrl: './select-options-menu.component.html',
    styleUrl: './select-options-menu.component.scss',
    imports: [angularImports],
})
export class SelectOptionsMenuComponent implements OnInit {
    protected options: SelectOption[] = [];
    protected noOptionsText: string;

    constructor(
        @Inject(BaseContextMenuComponent) private readonly baseContextMenu: BaseContextMenuComponent,
        @Inject(CONTEXT_MENU_DATA) private readonly data: SelectOptionsMenuData,
        private readonly containerElement: ElementRef
    ) {}

    ngOnInit(): void {
        this.options = this.data.options;
        this.containerElement.nativeElement.style.width = `${this.data.container_width}px`;
        this.noOptionsText = this.data.no_options_text;
    }

    selectOption(event: Event, option: SelectOption): void {
        event.stopPropagation();

        this.baseContextMenu.close(option);
    }

    updateOptions(options: SelectOption[]): void {
        this.options = options;
    }
}
