import { Component, HostListener, OnInit, ViewChildren } from '@angular/core';
import { AuthorizeDto } from '../../dto/user/authorize-dto';
import { AuthorizationService } from '../../services/authorization.service';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { angularImports } from '../../utilities/global-import';
import { ButtonDirective } from '../../directives/button.directive';
import { InputDirective } from '../../directives/input.directive';
import { ToastService } from '../../components/toast/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import _ from 'lodash';
import { CheckboxComponent } from '../../components/checkbox/checkbox.component';
import { Validator } from '../../validator/validator';
import { ValidationHost } from '../../validator/validation-host';
import { ValidateErrorDirective } from '../../validator/directives/validate-error.directive';
import { ValidateDirective } from '../../validator/directives/validate.directive';
import { PasswordInputComponent } from '../../components/password-input/password-input.component';
import { ForgotPasswordDto } from '../../dto/user/forgot-password-dto';
import { ResetPasswordDto } from '../../dto/user/reset-password-dto';
import { ValidationErrorMessage } from '../../models/validation-error-message';
import { LoaderComponent } from '../../components/loader/loader.component';

@Component({
    standalone: true,
    selector: 'ax-login-page',
    templateUrl: './login-page.component.html',
    styleUrl: './login-page.component.scss',
    imports: [angularImports, ButtonDirective, InputDirective, CheckboxComponent, ValidateDirective, ValidateErrorDirective, PasswordInputComponent, LoaderComponent],
})
export class LoginPageComponent implements OnInit, ValidationHost {
    protected authorizationDto: AuthorizeDto = {
        email_address: '',
        password: '',
    };

    protected forgotPasswordDto: ForgotPasswordDto = {
        email_address: '',
    };

    protected resetPasswordDto: ResetPasswordDto = {
        token: '',
        password: '',
    };

    protected rememberEmail: boolean = false;
    protected isPasswordLinkSent: boolean = true;
    protected isPasswordResetTokenValid: boolean = false;
    protected isPasswordResetLoading: boolean = false;
    protected isLoading: boolean = false;

    protected passwordRepeat: string = '';
    protected theme: string = '';
    protected title: string = 'Login';
    protected view: string = 'login';

    private delayTimerId: number;
    private validator: Validator;

    @HostListener('keydown.enter', ['$event'])
    public async onEnterKey(event: KeyboardEvent): Promise<void> {
        switch (this.view) {
            case 'login':
                await this.authorize(event);
                break;

            case 'forgot-password':
                await this.sendPasswordResetLink(event);
                break;

            case 'reset-password':
                if (this.isPasswordResetTokenValid) {
                    await this.resetPassword(event);
                } else {
                    return;
                }
                break;

            default:
                throw new Error('View not implemented');
        }
    }

    @ViewChildren(ValidateDirective) private readonly validateDirectives: ValidateDirective[];
    @ViewChildren(ValidateErrorDirective) private readonly validateErrorDirectives: ValidateErrorDirective[];

    constructor(
        private readonly authorizationService: AuthorizationService,
        private readonly userService: UserService,
        private readonly router: Router,
        private readonly toastService: ToastService,
        private readonly activatedRoute: ActivatedRoute
    ) {
        this.setTheme();
    }

    getValidationItems(): ValidateDirective[] {
        return this.validateDirectives;
    }

    getValidationErrorItems(): ValidateErrorDirective[] {
        return this.validateErrorDirectives;
    }

    async ngOnInit(): Promise<void> {
        if (this.authorizationService.isAuthenticated()) {
            this.router.navigateByUrl('');
        } else {
            let isPasswordForgetLink: boolean = false;

            if (this.router.url.startsWith('/passwordreset')) {
                this.activatedRoute.params.subscribe(async (params: Params) => {
                    this.isPasswordResetLoading = true;
                    this.resetPasswordDto.token = params['token'];
                    this.isPasswordResetTokenValid = await this.userService.validatePasswordResetToken(this.resetPasswordDto.token);

                    _.delay(() => {
                        this.isPasswordResetLoading = false;
                    }, 1000);
                });

                isPasswordForgetLink = true;
            }

            const rememberedEmail: string | null = localStorage.getItem('login_email_address');

            if (!_.isNil(rememberedEmail) && !_.isEmpty(rememberedEmail)) {
                this.authorizationDto.email_address = rememberedEmail;
                this.rememberEmail = true;
            }

            this.validator = new Validator(this);

            if (isPasswordForgetLink) {
                this.switchView('reset-password');
            } else {
                const storedViewKey: string | null = sessionStorage.getItem('login-view');

                if (!_.isNil(storedViewKey) && !_.isEmpty(storedViewKey)) {
                    this.switchView(storedViewKey);
                    sessionStorage.removeItem('login-view');
                }
            }
        }
    }

    protected async authorize(event: Event): Promise<void> {
        event.stopPropagation();

        this.validator.resetValidationStates();
        const isValid: boolean = this.validator.validate();

        if (!isValid) {
            this.toastService.danger('Validation error', 'Some fields did not pass validation checks');
        } else {
            try {
                this.delayTimerId = _.delay(() => {
                    this.isLoading = true;
                }, 1000);

                const token: string = await this.userService.authorize(this.authorizationDto);

                if (!_.isNil(token) && !_.isEmpty(token)) {
                    if (this.rememberEmail) {
                        localStorage.setItem('login_email_address', this.authorizationDto.email_address);
                    } else {
                        localStorage.removeItem('login_email_address');
                    }

                    localStorage.setItem('access_token', token);
                    this.authorizationService.onAuthorizationSuccess.next();
                    this.router.navigateByUrl('');
                }
            } catch (err) {
                const httpError: HttpErrorResponse = err as HttpErrorResponse;
                this.toastService.danger('Authorization Error', httpError.error as string);
            } finally {
                this.isLoading = false;
                clearTimeout(this.delayTimerId);
            }
        }
    }

    protected async sendPasswordResetLink(event: Event): Promise<void> {
        event.stopPropagation();

        this.validator.resetValidationStates();
        const isValid: boolean = this.validator.validate();

        if (!isValid) {
            this.toastService.danger('Validation error', 'Some fields did not pass validation checks');
        } else {
            try {
                _.delay(() => {
                    this.isLoading = true;
                }, 1000);

                await this.userService.sendPasswordResetLink(this.forgotPasswordDto);
                this.isPasswordLinkSent = true;
            } catch (err) {
                const httpError: HttpErrorResponse = err as HttpErrorResponse;
                this.toastService.danger('Password reset error', httpError.error as string);
            } finally {
                this.isLoading = false;
                clearTimeout(this.delayTimerId);
            }
        }
    }

    protected async resetPassword(event: Event): Promise<void> {
        event.stopPropagation();

        this.validator.resetValidationStates();
        let isValid: boolean = this.validator.validate();

        // We need to manually check passowrd
        if (!_.isEqual(this.resetPasswordDto.password, this.passwordRepeat)) {
            isValid = false;
            this.validator.setInvalidState('password', 'Passwords do not match');
            this.validator.setInvalidState('password_repeat', 'Passwords do not match');
        }

        if (!isValid) {
            this.toastService.danger('Validation error', 'Some fields did not pass validation checks');
        } else {
            try {
                _.delay(() => {
                    this.isLoading = true;
                }, 1000);

                await this.userService.resetPassword(this.resetPasswordDto);
                this.toastService.success('Success', 'Password sucessfully reset');
                this.switchView('login');
            } catch (err) {
                const httpError: HttpErrorResponse = err as HttpErrorResponse;
                const errorMessages: ValidationErrorMessage[] = httpError.error as ValidationErrorMessage[];

                if (!_.isNil(errorMessages) && !_.isEmpty(errorMessages)) {
                    errorMessages.forEach((errorMessage: ValidationErrorMessage) => {
                        // Since the validator does not yet have the capability to have matching keys we invalidate the password repeat property manually for now.
                        if (errorMessage.property_name === 'password') {
                            this.validator.setInvalidState('password_repeat', errorMessage.error_message);
                        }
                        this.validator.setInvalidState(errorMessage.property_name, errorMessage.error_message);
                    });
                }

                this.toastService.danger('Validation error', 'Some fields did not pass validation checks');
            } finally {
                this.isLoading = false;
                clearTimeout(this.delayTimerId);
            }
        }
    }

    protected redirectToLogin(): void {
        this.router.navigateByUrl('/login');
    }

    protected rediretToForgotPassword(): void {
        sessionStorage.setItem('login-view', 'forgot-password');
        this.router.navigateByUrl('/login');
    }

    protected switchTheme(event: Event): void {
        event.stopPropagation();

        switch (this.theme) {
            case 'dark':
                this.theme = '';
                document.body.classList.remove('theme-dark');
                localStorage.setItem('theme', '');
                break;

            case '':
                this.theme = 'dark';
                document.body.classList.add('theme-dark');
                localStorage.setItem('theme', 'dark');
                break;

            default:
                this.theme = '';
                document.body.classList.remove('theme-dark');
                localStorage.setItem('theme', '');
                break;
        }
    }

    protected switchView(view: string): void {
        switch (view) {
            case 'login':
                this.title = 'Login';
                this.view = 'login';
                break;

            case 'forgot-password':
                this.title = 'Forgot password';
                this.view = 'forgot-password';
                this.isPasswordLinkSent = false;
                break;

            case 'reset-password':
                this.title = 'Reset password';
                this.view = 'reset-password';
                break;

            default:
                throw new Error('View not implemented');
        }
    }

    private setTheme(): void {
        const themeString: string | null = localStorage.getItem('theme');

        if (_.isNil(themeString)) {
            this.theme = '';
        } else if (themeString === 'dark') {
            this.theme = 'dark';
        } else {
            this.theme = '';
        }
    }
}
