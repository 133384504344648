import { Component, HostListener, Inject } from '@angular/core';
import { angularImports } from '../../utilities/global-import';
import { CONTEXT_MENU_DATA } from '../interfaces/contex-menu-config';
import { SearchResult } from '../../models/search/search-result';
import { Router } from '@angular/router';
import { BaseContextMenuComponent } from '../base-context-menu/base-context-menu.component';

@Component({
    selector: 'ax-search-context-menu',
    styleUrls: ['search-context-menu.component.scss'],
    templateUrl: 'search-context-menu.component.html',
    standalone: true,
    imports: [angularImports],
})
export class SearchContextMenuComponent {
    public searchResults: SearchResult[] = [];

    constructor(
        @Inject(BaseContextMenuComponent) private readonly baseContextMenu: BaseContextMenuComponent,
        @Inject(CONTEXT_MENU_DATA) private readonly data: SearchResult[],
        private router: Router
    ) {
        this.searchResults = data;
    }

    updateSearchResults(results: SearchResult[]): void {
        this.searchResults = results;
    }

    navigateTo(id: number, type: string, event: Event): void {
        event.stopPropagation();
        this.baseContextMenu.close();

        this.router.navigateByUrl(`/${type.toLowerCase()}/${id}`);
    }
}
