import { Component, Input, Output, forwardRef, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { angularImports } from '../../utilities/global-import';
import { InputDirective } from '../../directives/input.directive';
import { ValidationContext } from '../../validator/validation-context';
import { ButtonDirective } from '../../directives/button.directive';

@Component({
    standalone: true,
    selector: 'ax-password-input',
    templateUrl: 'password-input.component.html',
    styleUrl: 'password-input.component.scss',
    imports: [angularImports, InputDirective, ButtonDirective],
    providers: [{ provide: ValidationContext, useExisting: forwardRef(() => this) }],
})
export class PasswordInputComponent extends ValidationContext {
    @Input() public value: string;
    @Input() public tabIndex: number = 0;
    @Input() public isValid: boolean = true;
    @Input() public isDisabled: boolean = false;
    @Input() public isInline: boolean = false;
    @Input() public placeholder: string = 'Password';
    @Input() public minlength: number = 0;
    @Input() public maxlength: number = 50;
    @Output() public valueChange: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('containerElement') private readonly containerElement: ElementRef<HTMLDivElement>;
    @ViewChild('inputElement') private readonly inputElement: ElementRef<HTMLInputElement>;

    protected showPassword: boolean = false;
    protected skipInputFocus: boolean = false;

    override getValue<T>(): T {
        return this.value as T;
    }

    override setIsInvalidState(): void {
        this.isValid = false;
    }

    override setIsValidState(): void {
        this.isValid = true;
    }

    protected setContainerClasses(): string {
        let classList: string = '';

        if (this.isInline) {
            classList = `${classList} ax-password-input__container--inline`;
        }

        if (!this.isValid) {
            classList = `${classList} ax-password-input__container--invalid`;
        }

        if (this.isDisabled) {
            classList = `${classList} ax-password-input__container--disabled`;
        }

        return classList;
    }

    protected setInputFocus(): void {
        if (!this.skipInputFocus) {
            this.inputElement.nativeElement.focus();
            this.inputElement.nativeElement.setSelectionRange(this.value.length, this.value.length);
        } else {
            this.skipInputFocus = false;
        }
    }

    protected onPasswordInputChange(value: string): void {
        this.valueChange.emit(value);
    }

    protected toggleVisibility(): void {
        this.showPassword = !this.showPassword;
        this.skipInputFocus = true;
        this.containerElement.nativeElement.focus();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected onFocusLost(event: any): void {
        if (event.relatedTarget === null || event.currentTarget.__ngContext__ !== event.relatedTarget.__ngContext__) {
            this.showPassword = false;
        }
    }
}
