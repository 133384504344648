<div class="ax-search-context-menu__container">
    @for(searchResult of searchResults; track searchResult.displayname){
        <div class="search-result" (click)="navigateTo(searchResult.id, searchResult.type, $event)" [tabIndex]="0">  
            @switch(searchResult.type){          
                @case('Tenant'){
                    <i class="fa-light fa-building search-result__icon"></i>
                } 
                @case('User') {
                    <i class="fa-light fa-user search-result__icon"></i>
                }
                @default {
                    <i class="fa-light fa-question search-result__icon"></i>
                }
            }
            <div class="search-result__block">
                <span class="search-result__block__title">{{searchResult.displayname}}</span>
                <small class="search-result__block__description">{{searchResult.description}}</small>
            </div>
        </div>
    }
    @empty {
        <div class="search-no-result">
            <i class="fa-duotone fa-solid fa-face-thinking search-no-result__icon"></i>
            <h3>No results</h3>
        </div>
    }
</div>