export interface ApiResourceMetric {
    endpoint: string;
    version: string;
    status: ServiceStatus;
    created_at: Date;
    error_message?: string;
    error_date_time_offset?: Date;
}

export enum ServiceStatus {
    Down = 0,
    Maintance = 1,
    Running = 2,
}
