import { HttpErrorResponse } from '@angular/common/http';
import { ValidationErrorMessage } from '../models/validation-error-message';
import { Validator } from '../validator/validator';
import { ToastService } from '../components/toast/toast.service';
import _ from 'lodash';

export class Toolkit {
    static generateId(length: number): string {
        let result = '';
        const numbers = '0123456789';
        const charactersLength = numbers.length;

        for (let i = 0; i < length; i++) result += numbers.charAt(Math.floor(Math.random() * charactersLength));

        return result;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static handleApiValidationErrors(error: any, validator: Validator, toastService: ToastService): void {
        const httpError: HttpErrorResponse = error as HttpErrorResponse;
        const errorMessages: ValidationErrorMessage[] = httpError.error as ValidationErrorMessage[];

        // TODO: Find a way to pass password_repeat kind of keys to this function
        if (!_.isNil(errorMessages) && !_.isEmpty(errorMessages) && _.isArray(errorMessages)) {
            errorMessages.forEach((errorMessage: ValidationErrorMessage) => {
                validator.setInvalidState(errorMessage.property_name, errorMessage.error_message);
                toastService.danger('Validation error', 'Some fields did not pass validation checks');
            });
        } else {
            toastService.danger('Error', errorMessages as unknown as string);
        }
    }
}
