import { Component, Inject } from '@angular/core';
import { angularImports } from '../../utilities/global-import';
import { BaseContextMenuComponent } from '../base-context-menu/base-context-menu.component';
import _ from 'lodash';
import { AuthorizationService } from '../../services/authorization.service';
import { Router } from '@angular/router';

@Component({
    selector: 'ax-user-context-menu',
    templateUrl: './user-context-menu.component.html',
    styleUrl: './user-context-menu.component.scss',
    standalone: true,
    imports: [angularImports],
})
export class UserContextMenuComponent {
    protected theme: string = '';

    constructor(
        @Inject(BaseContextMenuComponent) private readonly baseContextMenu: BaseContextMenuComponent,
        private readonly authorizationService: AuthorizationService,
        private readonly router: Router
    ) {
        this.setTheme();
    }

    switchTheme(event: Event): void {
        event.stopPropagation();

        switch (this.theme) {
            case 'dark':
                this.theme = '';
                document.body.classList.remove('theme-dark');
                localStorage.setItem('theme', '');
                break;

            case '':
                this.theme = 'dark';
                document.body.classList.add('theme-dark');
                localStorage.setItem('theme', 'dark');
                break;

            default:
                this.theme = '';
                document.body.classList.remove('theme-dark');
                localStorage.setItem('theme', '');
                break;
        }

        this.baseContextMenu.close();
    }

    logout(event: Event): void {
        event.stopPropagation();

        this.baseContextMenu.close();
        this.authorizationService.handleLogout();
        this.router.navigateByUrl('/login');
    }

    goToMyAccount(event: Event): void {
        event.stopPropagation();

        this.baseContextMenu.close();
        this.router.navigateByUrl('/my-account');
    }

    private setTheme(): void {
        const themeString: string | null = localStorage.getItem('theme');

        if (_.isNil(themeString)) {
            this.theme = '';
        } else if (themeString === 'dark') {
            this.theme = 'dark';
        } else {
            this.theme = '';
        }
    }
}
