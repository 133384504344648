<div class="tenant-detail__container">
    @if (!isLoading) {
        <div class="tenant-detail__header">
            <h3>{{title}}</h3>
            <div class="tenant-detail__header__actions">
                <button [ax-button]="'primary'" [transparent]="true" (click)="backToOverview()">
                    <i class="fa-regular fa-arrow-left"></i>
                    Back to overview
                </button>
                @if (hasEditPermission) {
                    <button [ax-button]="'primary'" (click)="updateTenant($event)" [disabled]="!hasChanges()">
                        Update
                        <i class="fa-solid fa-floppy-disk"></i>
                    </button>
                }
                @if (hasDeletePermission) {
                    <button [ax-button]="'danger'" (click)="deleteTenant($event)">
                        Delete
                        <i class="fa-solid fa-trash-can"></i>
                    </button>
                }
            </div>
        </div>
        <div class="tenant-detail__content__container">
            <div class="tenant-detail__scrollspy__container">
                <div class="scrollspy__item" [ngClass]="currentScrollSpyElementKey === 'general-information' ? 'scrollspy__item--selected' : ''" (click)="goToSection($event, 'general-information')">
                    <h5 class="scrollspy__item__text">General Information</h5>
                </div>
                <div class="scrollspy__item" [ngClass]="currentScrollSpyElementKey === 'api-resources' ? 'scrollspy__item--selected' : ''" (click)="goToSection($event, 'api-resources')">
                    <h5 class="scrollspy__item__text">API Resources</h5>
                </div>
                <div class="scrollspy__item" [ngClass]="currentScrollSpyElementKey === 'application-versions' ? 'scrollspy__item--selected' : ''" (click)="goToSection($event, 'application-versions')">
                    <h5 class="scrollspy__item__text">Application Versions</h5>
                </div>
                <div class="scrollspy__item" [ngClass]="currentScrollSpyElementKey === 'users' ? 'scrollspy__item--selected' : ''" (click)="goToSection($event, 'users')">
                    <h5 class="scrollspy__item__text">Users</h5>
                </div>
                @if (hasAdminPermission) {
                    <div class="scrollspy__item" [ngClass]="currentScrollSpyElementKey === 'mutations' ? 'scrollspy__item--selected' : ''" (click)="goToSection($event, 'mutations')">
                        <h5 class="scrollspy__item__text">Mutations</h5>
                    </div>
                }
            </div>
            <div ax-scroll-spy class="tenant-detail__contents" (sectionChange)="scrollSpySectionChanged($event)">
                <ax-collapse-container [ax-scroll-spy-item]="'general-information'" [headerText]="'General Information'" [isCollapsed]="false">
                    <div class="tenant-detail__collapse__container__general__information">
                        <div class="tenant-detail__general__information__block">
                            <div class="input__block">
                                <span>Name</span>
                                <input
                                    ax-input
                                    type="text"
                                    [placeholder]="'Axerrio Business Solutions'"
                                    required
                                    ax-validate="name"
                                    ax-validate-group="tenant"
                                    minlength="3"
                                    maxlength="80"
                                    [disabled]="!hasEditPermission"
                                    [(ngModel)]="tenant.name">
                                <small class="input__block__error" ax-validate-error="name"></small>
                            </div>
                            <div class="input__block">
                                <span>Code</span>
                                <input
                                    ax-input
                                    type="text"
                                    [placeholder]="'AXE'"
                                    required
                                    ax-validate="code"
                                    ax-validate-group="tenant"
                                    minlength="3"
                                    maxlength="3"
                                    [disabled]="!hasEditPermission"
                                    [(ngModel)]="tenant.code">
                                <small class="input__block__error" ax-validate-error="code"></small>
                            </div>
                            <div class="input__block input__block--row">
                                <ax-checkbox [(isChecked)]="isMaintenanceEnabled" (isCheckedChange)="toggleMaintenaceMode()"></ax-checkbox>
                                <span>Maintenance Mode</span>
                            </div>
                        </div>
                        <div class="tenant-detail__general__information__block">
                            <div class="input__block">
                                <span>Tenant ID</span>
                                <input ax-input type="text" [placeholder]="'Tenant ID'" [ngModel]="tenant.id" [disabled]="true">
                            </div>
                            <div class="input__block">
                                <span>Created</span>
                                <input ax-input type="text" [placeholder]="'Created At'" [ngModel]="normalizeDate(tenant.created_at)" [disabled]="true">
                            </div>
                            <div class="input__block">
                                <span>Modified</span>
                                <input ax-input type="text" [placeholder]="'Modified At'" [ngModel]="normalizeDate(tenant.modified_at)" [disabled]="true">
                            </div>
                            <div class="action__block">
                                <ax-split-button
                                    #splitButton
                                    [primaryButtonText]="'Copy tenant info'"
                                    [primaryButtonIconClass]="'fa-solid fa-copy'"
                                    [primaryButtonCallback]="copyTenant.bind(this)"
                                    [splitOptions]="splitButtonCopyOptions">
                                </ax-split-button>
                            </div>
                        </div>
                    </div>
                </ax-collapse-container>
                <ax-collapse-container [ax-scroll-spy-item]="'api-resources'" [headerText]="'API Resources'" [isCollapsed]="false">
                    <div class="tenant-detail__collapse__container__table__contents">
                        <ax-table
                            [isLoading]="isApiResourcesLoading"
                            [columns]="apiResourceTableColumns"
                            [data]="tenantApiResouceStatuses"
                            [buttons]="apiResourceTableButtons"
                            [noDataText]="'This tenant has no linked API resources'">
                        </ax-table>
                        @if (hasEditPermission && resourceOptions.length > 0) {
                            <div class="tenant-detail__collapse__container__table__contents__footer">
                                <button [ax-button]="'primary'" (click)="showCreateEntityPanel('resource')">
                                    Add API Resource
                                    <i class="fa-solid fa-plus"></i>
                                </button>
                                <button [ax-button]="'primary'" (click)="metricsPanel.toggle()">
                                    Show Metrics
                                </button>
                            </div>
                        }
                    </div>
                </ax-collapse-container>
                <ax-collapse-container [ax-scroll-spy-item]="'application-versions'" [headerText]="'Application Versions'" [isCollapsed]="false">
                    <div class="tenant-detail__collapse__container__table__contents">
                        <ax-table
                            [columns]="applicationVersionsTableColumns"
                            [data]="tenant.versions"
                            [buttons]="applicationVersionTableButtons"
                            [noDataText]="'This tenant has no linked application versions'">
                        </ax-table>
                        @if (hasEditPermission && versionOptions.length > 0) {
                            <div class="tenant-detail__collapse__container__table__contents__footer">
                                <button [ax-button]="'primary'" (click)="showCreateEntityPanel('version')">
                                    Add Application Version
                                    <i class="fa-solid fa-plus"></i>
                                </button>
                            </div>
                        }
                    </div>
                </ax-collapse-container>
                <ax-collapse-container [ax-scroll-spy-item]="'users'" [headerText]="'Users'" [isCollapsed]="false">
                    @if (showSearchBar) {
                        <div class="tenant-detail__collapse__container__search">
                            <ax-search [placeholder]="'Search users...'" (valueChange)="searchUsers($event)"></ax-search>
                        </div>
                    }
                    <div class="tenant-detail__collapse__container__table__contents">
                        <ax-table
                            [columns]="userTableColumns"
                            [data]="getTenantAccountData()"
                            [buttons]="userTableButtons"
                            [noDataText]="'This tenant has no linked users'">
                        </ax-table>
                        <div class="tenant-detail__collapse__container__table__contents__footer">
                            @if (hasEditPermission && userOptions.length > 0) {
                                <button [ax-button]="'primary'" (click)="showCreateEntityPanel('user')">
                                    Add User
                                    <i class="fa-solid fa-plus"></i>
                                </button>
                            }
                            <div class="paginations">
                                <ax-pagination
                                    #userPagination
                                    [totalRecordCount]="tenant.users.length"
                                    [(selectedPage)]="selectedTenantAccountPage"
                                    [(selectedRecordsPerPageOption)]="userRecordsPerPage"
                                    (selectedPageChange)="getTenantAccountData()"
                                    (selectedRecordsPerPageOptionChange)="resetUserPagination()">
                                </ax-pagination>
                            </div>
                        </div>
                    </div>
                </ax-collapse-container>
                @if (hasAdminPermission) {
                    <ax-collapse-container [ax-scroll-spy-item]="'mutations'" [headerText]="'Mutations'" (isCollapsedChange)="openMutationsContainerAndScroll($event)">
                        <div class="tenant-detail__collapse__container__table__contents">
                            @if (!isMutationsLoaded) {
                                <div class="mutation__empty-state__container">
                                    <h5>Mutations have not been fetched yet</h5>
                                    <button [ax-button]="'primary'" (click)="fetchMutations()">
                                        Fetch mutations
                                    </button>
                                </div>
                            } @else {
                                <ax-table
                                    [isLoading]="isMutationsLoading"
                                    [columns]="mutationTableColumns"
                                    [data]="mutations"
                                    [loaderText]="'Fetching mutations'"
                                    [noDataText]="'This tenant has no mutations'">
                                </ax-table>
                                <div class="tenant-detail__collapse__container__table__contents__footer">
                                    @if (hasNewMutationsToFetch) {
                                        <button [ax-button]="'primary'" (click)="reloadMutations()">
                                            Reload mutations
                                            <i class="fa-regular fa-arrows-rotate"></i>
                                        </button>
                                    }
                                    <div class="paginations">
                                        <ax-pagination
                                            #mutationsPagination
                                            [totalRecordCount]="totalMutaionRecordCount"
                                            [(selectedPage)]="selectedMutationPage"
                                            [(selectedRecordsPerPageOption)]="mutationRecordsPerPage"
                                            (selectedPageChange)="fetchPagedMutations()"
                                            (selectedRecordsPerPageOptionChange)="resetMutationPagination()">
                                        </ax-pagination>
                                    </div>
                                </div>
                            }
                        </div>
                    </ax-collapse-container>
                }
            </div>
        </div>
        <ax-side-panel #contentPanel [orientation]="'right'" (isPanelVisibleChange)="resetValidationState($event)">
            <div class="side-panel__container">
                <div class="side-panel__header">
                    <h3>{{sidePanelHeader}}</h3>
                    <button [ax-button] [icon-only-small]="true" [transparent]="true" (click)="sidePanel.toggle()">
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div class="side-panel__content">
                    @switch (activePanel) {
                        @case ('user') {
                            <div class="side-panel__content__block">
                                <span>User</span>
                                <ax-select
                                    #userTypeaheadSelect
                                    [options]="userOptions"
                                    [(value)]="selectedUserId"
                                    [placeholder]="'Select a user'"
                                    [noOptionsText]="'No users found'"
                                    [ax-validate]="'user_id'"
                                    [ax-validate-group]="'user'"
                                    [ax-validation-rules]="['required']"
                                    [ax-validate-display-property]="'User'"
                                    [isDisabled]="isUpdateState"
                                    [isTypeaeadEnabled]="true">
                                </ax-select>
                                <small class="side-panel__content__block__error" ax-validate-error="user_id"></small>
                            </div>
                            <div class="side-panel__content__block">
                                <span>ABS ID</span>
                                <input
                                    type="number"
                                    ax-input
                                    ax-validate="abs_user_id"
                                    ax-validate-group="user"
                                    required
                                    [(ngModel)]="tenantUserDto.abs_user_id"
                                    [ax-validation-custom-rules]="userValidationRules"
                                    [ax-validate-display-property]="'ABS ID'"
                                    [placeholder]="'ABS ID'">
                                <small class="side-panel__content__block__error" ax-validate-error="abs_user_id"></small>
                            </div>
                        }
                        @case ('resource') {
                            <div class="side-panel__content__block">
                                <span>Resource</span>
                                <ax-select
                                    [options]="resourceOptions"
                                    [(value)]="selectedResourceId"
                                    [placeholder]="'Select a resource'"
                                    [noOptionsText]="'No resources found...'"
                                    [ax-validate]="'resource_id'"
                                    [ax-validate-group]="'resource'"
                                    [ax-validation-rules]="['required']"
                                    [ax-validate-display-property]="'Resource'"
                                    [isDisabled]="isUpdateState">
                                </ax-select>
                                <small class="side-panel__content__block__error" ax-validate-error="resource_id"></small>
                            </div>
                            <div class="side-panel__content__block">
                                <span>Url</span>
                                <input
                                    type="text"
                                    ax-input
                                    placeholder="Url"
                                    required
                                    ax-validate="url"
                                    ax-validate-group="resource"
                                    [ax-validation-custom-rules]="resourceValidationRules"
                                    [(ngModel)]="tenantResourceDto.url"
                                    >
                                <small class="side-panel__content__block__error" ax-validate-error="url"></small>
                            </div>
                        }
                        @case ('version') {
                            <div class="side-panel__content__block">
                                <span>Application version</span>
                                <ax-select
                                    [options]="versionOptions"
                                    [(value)]="selectedVersionId"
                                    [placeholder]="'Select a application version'"
                                    [noOptionsText]="'No versions found'"
                                    [ax-validate]="'version_id'"
                                    [ax-validate-group]="'version'"
                                    [ax-validation-rules]="['required']"
                                    [ax-validate-display-property]="'Application version'">
                                </ax-select>
                                <small class="side-panel__content__block__error" ax-validate-error="version_id"></small>
                            </div>
                        }
                    }
                </div>
                <div class="side-panel__footer">
                    @if (isUpdateState) {
                        <button [ax-button]="'primary'" (click)="updateObject()">Update</button>
                    } @else {
                        <button [ax-button]="'primary'" (click)="saveObject()">Add</button>
                    }
                    <button [ax-button] (click)="sidePanel.toggle()">Cancel</button>
                </div>
            </div>
        </ax-side-panel>
        <ax-side-panel #mutationPanel [orientation]="'right'">
            <div class="side-panel__container">
                <div class="side-panel__header">
                    <h3>Raw tenant mutation data</h3>
                    <button [ax-button] [icon-only-small]="true" [transparent]="true" (click)="mutationSidePanel.toggle()">
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div class="side-panel__content">
                    <pre>
                        {{rawTenantMutationData}}
                    </pre>
                </div>
            </div>
        </ax-side-panel>
        <ax-side-panel #metricsPanel [orientation]="'right'" class="metrics-side-panel">
            <div class="side-panel__container">
                <div class="side-panel__header">
                    <h3>API Resource Metrics</h3>
                    <button [ax-button] [icon-only-small]="true" [transparent]="true" (click)="metricsPanel.toggle()">
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div class="side-panel__content side-panel__content--metrics">
                    <div class="metrics-range-container">
                        <span>Metrics per:</span>
                        <ax-select [options]="metricRangeOptions" [(value)]="selectedMetricRange" (valueChange)="onMetricRangeChange()" [hasClearEnabled]="false"></ax-select>
                    </div>
                    @if (!isMetricsLoading) {
                        @for (tenantApiResource of tenantApiResouceStatuses; track tenantApiResource.id) {
                            <ax-status-metric [title]="tenantApiResource.name" [rangeType]="selectedMetricRange" [metrics]="apiResourceMetricsMap.get(tenantApiResource.name)"></ax-status-metric>
                        }
                    } @else {
                        <ax-loader [loaderText]="'Fetching metrics'"></ax-loader>
                    }
                </div>
            </div>
        </ax-side-panel>
    } @else {
        <ax-loader [isFullPage]="true" [loaderText]="'Loading Tenant'"></ax-loader>
    }
</div>
