import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizeDto } from '../dto/user/authorize-dto';
import { firstValueFrom } from 'rxjs';
import { User } from '../models/user/user';
import { CreateUserDto } from '../dto/user/create-user-dto';
import { UpdateUserDto } from '../dto/user/update-user-dto';
import { UserFilterDto } from '../dto/user/user-filter-dto';
import { PagedResult } from '../models/paged-result';
import { ForgotPasswordDto } from '../dto/user/forgot-password-dto';
import { ResetPasswordDto } from '../dto/user/reset-password-dto';
import { UpdateUserSelfDto } from '../dto/user/update-user-self-dto';
import { UpdatePasswordDto } from '../dto/user/update-password-dto';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private readonly basePath: string = environment.api_endpoint;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly externalHttpClient: HttpClient,
        private readonly httpBackend: HttpBackend
    ) {
        this.externalHttpClient = new HttpClient(httpBackend);
    }

    async authorize(authorizeDto: AuthorizeDto): Promise<string> {
        return firstValueFrom(this.httpClient.post<string>('user/authorize', JSON.stringify(authorizeDto)));
    }

    async me(): Promise<User> {
        return firstValueFrom(this.httpClient.get<User>('user/me'));
    }

    async get(id: number): Promise<User> {
        return firstValueFrom(this.httpClient.get<User>(`user/${id}`));
    }

    async validatePasswordResetToken(token: string): Promise<boolean> {
        return firstValueFrom(this.httpClient.get<boolean>(`user/validatePasswordResetToken/${token}`));
    }

    async filter(userFilterDto: UserFilterDto): Promise<PagedResult<User>> {
        return firstValueFrom(this.httpClient.post<PagedResult<User>>('user/filter', JSON.stringify(userFilterDto)));
    }

    async create(createUserDto: CreateUserDto): Promise<User> {
        return firstValueFrom(this.httpClient.post<User>('user', JSON.stringify(createUserDto)));
    }

    async update(id: number, updateUserDto: UpdateUserDto): Promise<User> {
        return firstValueFrom(this.httpClient.put<User>(`user/${id}`, JSON.stringify(updateUserDto)));
    }

    async setAvatar(file: File): Promise<User> {
        const token: string = localStorage.getItem('access_token');

        const headers: HttpHeaders = new HttpHeaders({ enctype: 'multipart/form-data', Authorization: `Bearer ${token}` });
        const formData: FormData = new FormData();
        formData.append('file', file);

        return firstValueFrom(this.externalHttpClient.post<User>(`${this.basePath}/user/avatar`, formData, { headers: headers }));
    }

    async setAvatarForUser(id: number, file: File): Promise<User> {
        const token: string = localStorage.getItem('access_token');

        const headers: HttpHeaders = new HttpHeaders({ enctype: 'multipart/form-data', Authorization: `Bearer ${token}` });
        const formData: FormData = new FormData();
        formData.append('file', file);

        return firstValueFrom(this.externalHttpClient.post<User>(`${this.basePath}/user/${id}/avatar`, formData, { headers: headers }));
    }

    async sendPasswordResetLink(forgotPasswordDto: ForgotPasswordDto): Promise<void> {
        return firstValueFrom(this.httpClient.post<void>('user/forgotPassword', JSON.stringify(forgotPasswordDto)));
    }

    async resetPassword(resetPasswordDto: ResetPasswordDto): Promise<void> {
        return firstValueFrom(this.httpClient.post<void>('user/resetpassword', JSON.stringify(resetPasswordDto)));
    }

    async updateSelf(updateUserSelfDto: UpdateUserSelfDto): Promise<User> {
        return firstValueFrom(this.httpClient.post<User>('user/me', JSON.stringify(updateUserSelfDto)));
    }

    async updatePassword(updatePasswordDto: UpdatePasswordDto): Promise<void> {
        return firstValueFrom(this.httpClient.post<void>('user/me/password', JSON.stringify(updatePasswordDto)));
    }

    async delete(id: number): Promise<void> {
        firstValueFrom(this.httpClient.delete<void>(`user/${id}`));
    }

    async deleteAvatar(): Promise<User> {
        return firstValueFrom(this.httpClient.put<User>('user/removeAvatar', null));
    }

    async deleteAvatarForUser(id: number): Promise<User> {
        return firstValueFrom(this.httpClient.put<User>(`user/${id}/removeAvatar`, null));
    }
}
