/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ViewChildren } from '@angular/core';
import { angularImports } from '../../utilities/global-import';
import { ButtonDirective } from '../../directives/button.directive';
import { ValidateDirective } from '../../validator/directives/validate.directive';
import { ValidateErrorDirective } from '../../validator/directives/validate-error.directive';
import { ValidationHost } from '../../validator/validation-host';
import { DialogComponentRef } from '../../components/dialog/models/dialog-component-ref';
import { UserService } from '../../services/user.service';
import { Validator } from '../../validator/validator';
import _ from 'lodash';
import { ToastService } from '../../components/toast/toast.service';
import { UpdatePasswordDto } from '../../dto/user/update-password-dto';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidationErrorMessage } from '../../models/validation-error-message';
import { PasswordInputComponent } from '../../components/password-input/password-input.component';

@Component({
    standalone: true,
    selector: 'ax-change-password-dialog',
    templateUrl: './update-password-dialog.component.html',
    styleUrl: './update-password-dialog.component.scss',
    imports: [angularImports, ButtonDirective, ValidateDirective, ValidateErrorDirective, PasswordInputComponent],
})
export class UpdatePasswordDialogComponent implements ValidationHost {
    protected password: string = '';
    protected passwordRepeat: string = '';

    private validator: Validator;

    @ViewChildren(ValidateDirective) private readonly validateDirectives: ValidateDirective[];
    @ViewChildren(ValidateErrorDirective) private readonly validateErrorDirectives: ValidateErrorDirective[];

    constructor(
        private readonly dialogComponentRef: DialogComponentRef<UpdatePasswordDialogComponent>,
        private readonly userService: UserService,
        private readonly toastService: ToastService
    ) {
        this.validator = new Validator(this);
    }

    getValidationItems(): ValidateDirective[] {
        return this.validateDirectives;
    }

    getValidationErrorItems(): ValidateErrorDirective[] {
        return this.validateErrorDirectives;
    }

    protected async updatePassword(): Promise<void> {
        this.validator.resetValidationStates();

        let isValid: boolean = this.validator.validate();

        // We need to manually check passowrd
        if (!_.isEqual(this.password, this.passwordRepeat)) {
            isValid = false;
            this.validator.setInvalidState('password', 'Passwords do not match');
            this.validator.setInvalidState('password_repeat', 'Passwords do not match');
        }

        if (isValid) {
            const updatePasswordDto: UpdatePasswordDto = {
                password: this.password,
            };

            try {
                await this.userService.updatePassword(updatePasswordDto);
                this.dialogComponentRef.closeDialog(true);
            } catch (err) {
                const httpError: HttpErrorResponse = err as HttpErrorResponse;
                const errorMessages: ValidationErrorMessage[] = httpError.error as ValidationErrorMessage[];

                if (!_.isNil(errorMessages) && !_.isEmpty(errorMessages) && _.isArray(errorMessages)) {
                    errorMessages.forEach((errorMessage: ValidationErrorMessage) => {
                        // Since the validator does not yet have the capability to have matching keys we invalidate the password repeat property manually for now.
                        if (errorMessage.property_name === 'password') {
                            this.validator.setInvalidState('password_repeat', errorMessage.error_message);
                        }

                        this.validator.setInvalidState(errorMessage.property_name, errorMessage.error_message);
                        this.toastService.danger('Validation error', 'Some fields did not pass validation checks');
                    });
                } else {
                    this.toastService.danger('Error', errorMessages as unknown as string);
                }
            }
        } else {
            this.toastService.danger('Validation error', 'Some fields did not pass validation checks');
        }
    }

    protected cancel(): void {
        this.dialogComponentRef.closeDialog(false);
    }
}
