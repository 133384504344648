@if (!isLoading) {
    <div class="user-detail-page__container">
        <div class="user-detail__header">
            <div class="user-detail__header__name-and-avatar">
                <ax-avatar [imageUrl]="user.avatar_url" [name]="getFullName()" [isEditable]="true" (editClicked)="openEditAvatarDialog()"></ax-avatar>
                <h3>{{getFullName()}}</h3>
            </div>
            <div class="user-detail__header__actions">
                <button [ax-button]="'primary'" (click)="openChangePasswordDialog()">
                    Change password
                    <i class="fa-regular fa-rotate"></i>
                </button>
                <button [ax-button]="'primary'" (click)="update()" [disabled]="!hasChanges()">
                    Update
                    <i class="fa-solid fa-save"></i>
                </button>
            </div>
        </div>
        <div class="user-detail__content">
            <div class="user-detail__content__information__block">
                <div class="user-detail__content__information__block__header">
                    <h5>General Information</h5>
                </div>
                <div class="input__block">
                    <span>Firstname*</span>
                    <input ax-input type="text" [ax-validate]="'firstname'" [required]="true" minlength="3" maxlength="20" placeholder="John" [(ngModel)]="user.firstname">
                    <small class="input__block__error__message" [ax-validate-error]="'firstname'"></small>
                </div>
                <div class="input__block">
                    <span>Lastname*</span>
                    <input ax-input type="text" [ax-validate]="'lastname'" [required]="true" minlength="3" maxlength="30" placeholder="Doe" [(ngModel)]="user.lastname">
                    <small class="input__block__error__message" [ax-validate-error]="'lastname'"></small>
                </div>
                <div class="input__block">
                    <span>Email address*</span>
                    <input
                        ax-input
                        type="email"
                        [ax-validate]="'email_address'"
                        [ax-validate-display-property]="'email address'"
                        [required]="true"
                        placeholder="someone@axerrio.com"
                        [(ngModel)]="user.email_address">
                    <small class="input__block__error__message" [ax-validate-error]="'email_address'"></small>
                </div>
                <small class="validation__information__text">Fields marked with * are required</small>
            </div>
            <div class="user-detail__content__notifications__block">
                <div class="user-detail__content__notifications__block__header">
                    <h5>Notifications</h5>
                </div>
                <div class="user-detail__content__notifications__block__content">
                    @for (notificationSetting of notificationSettings; track notificationSetting.key) {
                        <div class="notification__block">
                            <div class="notification__block__title">
                                <span class="notification__block__title__text">{{notificationSetting.display_key}}</span>
                                <ax-toggle-button [isChecked]="hasNotificationSet(notificationSetting.key)" (isCheckedChange)="setNotificationSetting(notificationSetting.key, $event)"></ax-toggle-button>
                            </div>
                            <small [innerHTML]="notificationSetting.description"></small>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
} @else {
    <ax-loader [loaderText]="'Loading user'" [isFullPage]="true"></ax-loader>
}