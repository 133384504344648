import { Component, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PageTemplateComponent } from './templates/page-template/page-template.component';
import { ContextMenuService } from './menus/context-menu.service';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { StateService } from './state/state.service';
import _ from 'lodash';
import { BaseContextMenuComponent } from './menus/base-context-menu/base-context-menu.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { SearchContextMenuComponent } from './menus/search-context-menu/search-context-menu.component';
import { CommonModule } from '@angular/common';

@Component({
    standalone: true,
    selector: 'ax-app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    imports: [CommonModule, RouterOutlet, PageTemplateComponent],
})
export class AppComponent {
    @ViewChild(PageTemplateComponent) pageTemplateComponent: PageTemplateComponent;

    constructor(
        private readonly stateService: StateService,
        private readonly contextMenuService: ContextMenuService
    ) {
        window.addEventListener('keydown', async (event: KeyboardEvent) => {
            switch (event.key) {
                case 'Escape': {
                    const topLevelComponent: any | null = this.stateService.getTopLayerComponentState();

                    if (!_.isNil(topLevelComponent)) {
                        if (topLevelComponent instanceof BaseContextMenuComponent) {
                            this.contextMenuService.closeAllMenus();
                        }

                        if (topLevelComponent instanceof SidePanelComponent) {
                            const sidePanel: SidePanelComponent = topLevelComponent as SidePanelComponent;
                            sidePanel.toggle();
                        }

                        if (topLevelComponent instanceof DialogComponent) {
                            const dialog: DialogComponent = topLevelComponent as DialogComponent;
                            if (dialog.getCloseOnClickOutside()) {
                                dialog.closeDialog();
                            }
                        }
                    }
                    const focusElement: HTMLElement = document.querySelector(':focus');

                    if (!_.isNil(focusElement)) {
                        focusElement.blur();
                    }

                    break;
                }
                case '/': {
                    const targetElement: HTMLElement = event.target as HTMLElement;

                    // If we have focus in an input we don't do anything and let the user type their thing in peace
                    if (targetElement.nodeName.toLowerCase() === 'input') {
                        break;
                    }

                    // Not sure if we want to close all context menu's and side panels, might be a annoying.
                    const topLevelComponent: any | null = this.stateService.getTopLayerComponentState();

                    if (!_.isNil(topLevelComponent)) {
                        if (topLevelComponent instanceof SidePanelComponent) {
                            const sidePanel: SidePanelComponent = topLevelComponent as SidePanelComponent;
                            sidePanel.toggle();
                        }

                        if (topLevelComponent instanceof DialogComponent) {
                            const dialog: DialogComponent = topLevelComponent as DialogComponent;
                            if (dialog.getCloseOnClickOutside()) {
                                dialog.closeDialog();
                            }
                        }

                        if (topLevelComponent instanceof BaseContextMenuComponent) {
                            const childComponent: SearchContextMenuComponent = topLevelComponent.getChildComponent<SearchContextMenuComponent>();
                            if (childComponent !== undefined && childComponent instanceof SearchContextMenuComponent === false) {
                                this.contextMenuService.closeAllMenus();
                            } else {
                                break;
                            }
                        }
                    }

                    this.pageTemplateComponent.toggleSearchMenu(event);

                    break;
                }
            }
        });
    }
}
