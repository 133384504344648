import { Component, EventEmitter, Input, Output } from '@angular/core';
import _ from 'lodash';
import { angularImports } from '../../utilities/global-import';

@Component({
    selector: 'ax-avatar',
    templateUrl: './avatar.component.html',
    styleUrl: './avatar.component.scss',
    standalone: true,
    imports: [angularImports],
})
export class AvatarComponent {
    @Input() public imageUrl: string | undefined;
    @Input() public name: string | undefined;
    @Input() public size: 'default' | 'small' | 'large' = 'default';
    @Input() public isEditable: boolean = false;
    @Output() public editClicked: EventEmitter<void> = new EventEmitter<void>();

    protected hasImage(): boolean {
        if (!_.isNil(this.imageUrl) && !_.isEmpty(this.imageUrl)) {
            return true;
        }

        return false;
    }

    protected getInitials(): string {
        if (!_.isNil(this.name) && !_.isEmpty(this.name.trim())) {
            let initials: string = '';

            const splittedName: string[] = this.name.split(' ');

            if (splittedName.length === 2) {
                initials = `${this.getFirstCharacterOfString(splittedName[0])}${this.getFirstCharacterOfString(splittedName[1])}`;
            } else if (splittedName.length > 2) {
                initials = `${this.getFirstCharacterOfString(splittedName[0])}${this.getFirstCharacterOfString(splittedName[2])}`;
            } else if (splittedName.length === 1) {
                initials = this.getFirstCharacterOfString(splittedName[0]);
            } else {
                initials = '?';
            }

            return initials;
        }

        return '?';
    }

    protected getContainerClasses(): string {
        let classList: string = '';

        if (this.size === 'small') {
            classList = `${classList} ax-avatar__container--small`;
        }

        if (this.size === 'large') {
            classList = `${classList} ax-avatar__container--large`;
        }

        if (this.isEditable) {
            classList = `${classList} ax-avatar__container--editable`;
        }

        return classList;
    }

    protected getImageClasses(): string {
        let classList: string = '';

        if (this.size === 'small') {
            classList = `${classList} ax-avatar__image--small`;
        }

        if (this.size === 'large') {
            classList = `${classList} ax-avatar__image--large`;
        }

        if (this.isEditable) {
            classList = `${classList} ax-avatar__image--editable`;
        }

        return classList;
    }

    private getFirstCharacterOfString(input: string): string {
        if (_.isNil(input) || _.isEmpty(input)) {
            return '';
        }

        const splittedInput: string[] = input.split('');

        return splittedInput[0].toUpperCase();
    }
}
