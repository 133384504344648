import dayjs from 'dayjs';

export interface StatusMetric {
    time: dayjs.Dayjs;
    status: StatusMetricState;
    status_text: string;
}

export enum StatusMetricState {
    Down = 0,
    Maintenance = 1,
    Running = 2,
    Unavailable = 3,
}
