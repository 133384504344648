export enum TableColumnOptionKey {
    DisplayBooleanAsIcon = 'DisplayBooleanAsIcon',
    BooleanDisplayTrueValue = 'BooleanDisplayTrueValue',
    BooleanDisplayFalseValue = 'BooleanDisplayFalseValue',
    StatusText = 'StatusText',
    StatusNumber = 'Statusnumber',
    CountContext = 'CountContext',
    PropertyPointerAvatarUrl = 'PropertyPointerAvatarUrl',
    PropertyPointerFirstname = 'PropertyPointerFirstname',
    PropertyPointerLastname = 'PropertyPointerLastname',
    ActionDisplayValue = 'ActionDisplayValue',
    ActionNoDataTextValue = 'ActionNoDataTextValue',
    ActionCallback = 'ActionCallback',
}
