/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, Injector, OnInit, StaticProvider, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import _ from 'lodash';
import { DIALOG_DATA, DialogComponentRef } from './models/dialog-component-ref';
import { DialogDirective } from '../../directives/dialog.directive';
import { StateService } from '../../state/state.service';
import { Toolkit } from '../../utilities/toolkit';

@Component({
    standalone: true,
    selector: 'ax-dialog',
    templateUrl: './dialog.component.html',
    styleUrl: './dialog.component.scss',
    imports: [DialogDirective],
})
export class DialogComponent implements OnInit {
    @ViewChild(DialogDirective) private readonly dialogDirective: DialogDirective;
    @ViewChild('containerElement') private readonly containerElement: ElementRef;

    public afterClose: Observable<any>;

    private closeOnClickOutside: boolean = true;
    private _afterClose: Subject<any>;
    private readonly stateKey: string;

    constructor(
        private readonly viewRef: ViewContainerRef,
        private readonly stateService: StateService
    ) {
        this.stateKey = Toolkit.generateId(10);
    }

    ngOnInit(): void {
        this.viewRef.element.nativeElement.addEventListener('click', (event: any) => {
            const containerElementContext: number = this.containerElement.nativeElement.__ngContext__;
            const targetElementContext: number | undefined = event.target.__ngContext__;

            if (targetElementContext !== undefined && containerElementContext === targetElementContext && this.closeOnClickOutside) this.closeDialog();
        });
    }

    createDialog<T>(dialogElement: Type<T>, data?: any): DialogComponentRef<T> {
        this.resetValuesToDefault();
        this.viewRef.element.nativeElement.style.display = 'flex';
        this.viewRef.element.nativeElement.classList.add('opening');
        _.delay(() => {
            this.viewRef.element.nativeElement.classList.remove('opening');
            this.stateService.saveComponentState(this.stateKey, this);
        }, 200);

        this.setUpAfterClose();
        const dialogComponentRef: DialogComponentRef<T> = new DialogComponentRef<T>(this);

        this.dialogDirective.viewRef.createComponent<T>(dialogElement, {
            injector: this.createInjector(data, dialogComponentRef),
        });

        return dialogComponentRef;
    }

    closeDialog(data?: any | null): void {
        this.viewRef.element.nativeElement.classList.add('closing');
        _.delay(() => {
            this.viewRef.element.nativeElement.classList.remove('closing');
            this.viewRef.element.nativeElement.style.display = 'none';
            this.stateService.removeComponentState(this.stateKey);
        }, 200);
        this.dialogDirective.viewRef.clear();
        this._afterClose.next(data);
    }

    setCloseOnClickOutside(value: boolean): void {
        this.closeOnClickOutside = value;
    }

    getCloseOnClickOutside(): boolean {
        return this.closeOnClickOutside;
    }

    private setUpAfterClose(): void {
        this._afterClose = new Subject();
        this.afterClose = this._afterClose.asObservable();
    }

    private createInjector<T>(data: any, componentRef: DialogComponentRef<T>): Injector {
        const providers: StaticProvider[] = [
            { provide: DIALOG_DATA, useValue: data },
            { provide: DialogComponentRef, useValue: componentRef },
        ];

        return Injector.create({ providers: providers });
    }

    private resetValuesToDefault(): void {
        this.closeOnClickOutside = true;
    }
}
