import { AfterViewInit, Component, ComponentRef, ElementRef, EventEmitter, forwardRef, Input, IterableChanges, IterableDiffer, IterableDiffers, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { angularImports } from '../../utilities/global-import';
import { ContextMenuService } from '../../menus/context-menu.service';
import { SelectOptionsMenuComponent } from '../../menus/select-options-menu/select-options-menu.component';
import { SelectOption } from '../select/interfaces/select-option';
import { SelectOptionsMenuData } from '../../menus/select-options-menu/interfaces/select-options-menu-data';
import { BaseContextMenuComponent } from '../../menus/base-context-menu/base-context-menu.component';
import _ from 'lodash';
import { ValidationContext } from '../../validator/validation-context';
import { Toolkit } from '../../utilities/toolkit';
import { InputDirective } from '../../directives/input.directive';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Component({
    standalone: true,
    selector: 'ax-select',
    templateUrl: './select.component.html',
    styleUrl: './select.component.scss',
    imports: [angularImports, InputDirective],
    providers: [{ provide: ValidationContext, useExisting: forwardRef(() => this) }],
})
export class SelectComponent extends ValidationContext implements OnInit, AfterViewInit, OnChanges {
    @Input() public value: string;
    @Input() public options: SelectOption[] = [];
    @Input() public hasClearEnabled: boolean = true;
    @Input() public placeholder: string = 'placeholder';
    @Input() public tabIndex: number = 0;
    @Input() public isValid: boolean = true;
    @Input() public isDisabled: boolean = false;
    @Input() public noOptionsText: string = 'No options available';
    @Input() public isInline: boolean = false;
    @Input() public isTypeaeadEnabled: boolean = false;
    @Input() public searchCallback: (value: string) => Promise<SelectOption[]>;
    @Output() public valueChange: EventEmitter<string> = new EventEmitter<string>();

    protected displayValue: string = '';
    protected hasValueSelected: boolean = false;
    protected isCollapsed: boolean = true;

    protected searchValue: string = '';

    private menuKey: string;
    private selectOptionsContainer: ComponentRef<BaseContextMenuComponent> | undefined;
    private searchValueChanged: Subject<string> = new Subject<string>();
    private originalOptions: SelectOption[] = [];
    private internalOptions: SelectOption[] = [];
    private retainFocus: boolean = false;
    private iteratableDiffer: IterableDiffer<SelectOption>;

    @ViewChild('containerElement') private readonly containerElement: ElementRef<HTMLDivElement>;
    @ViewChild('searchInput') private readonly searchInput: ElementRef<HTMLInputElement>;

    constructor(
        private readonly hostContainer: ElementRef,
        private readonly contextMenuService: ContextMenuService,
        private readonly iteratableDiffers: IterableDiffers
    ) {
        super();
        this.menuKey = `select-options-menu-${Toolkit.generateId(10)}`;
        this.iteratableDiffer = iteratableDiffers.find(this.options).create(null);

        this.searchValueChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(async (value: string) => {
            this.internalOptions.forEach((option: SelectOption) => {
                option.is_selected = false;
            });

            this.hasValueSelected = false;
            this.value = '';

            this.valueChange.emit(this.value);

            if (_.isEmpty(value)) {
                this.internalOptions = this.originalOptions;
            } else {
                if (!_.isNil(this.searchCallback) && _.isFunction(this.searchCallback)) {
                    this.internalOptions = await this.searchCallback(value);
                } else {
                    const filterClone: SelectOption[] = _.cloneDeep(this.originalOptions);
                    this.internalOptions = filterClone.filter((option: SelectOption) => option.display_value.toLowerCase().includes(value.toLowerCase()));
                }
            }

            if (this.isCollapsed) {
                this.toggleCollpase(null);
            } else {
                this.selectOptionsContainer.instance.getChildComponent<SelectOptionsMenuComponent>().updateOptions(this.internalOptions);
            }
        });
    }

    ngOnInit(): void {
        if (_.isArray(this.options)) {
            this.internalOptions = _.cloneDeep(this.options);
        }

        this.setSelectedOption(false);
    }

    ngAfterViewInit(): void {
        this.containerElement.nativeElement.style.width = this.hostContainer.nativeElement.style.width;
    }

    ngOnChanges(changes: SimpleChanges): void {
        const change: SimpleChange | undefined | null = changes['value'];

        if (!_.isNil(change)) {
            if (!change.isFirstChange()) {
                this.setSelectedOption(true);
            }
        }

        const optionChanges: IterableChanges<SelectOption> = this.iteratableDiffer.diff(this.options);

        if (!_.isNil(optionChanges)) {
            this.internalOptions = _.cloneDeep(this.options);
        }
    }

    override getValue<T>(): T {
        return this.value as T;
    }

    override setIsInvalidState(): void {
        this.isValid = false;
    }

    override setIsValidState(): void {
        this.isValid = true;
    }

    resetTypeahead(): void {
        if (this.isTypeaeadEnabled) {
            this.searchValue = '';
            this.internalOptions = this.originalOptions;
            this.searchInput.nativeElement.value = '';
        }
    }

    updateSelectedState(): void {
        this.setSelectedOption(true);
    }

    protected containerClick(event: Event): void {
        if (this.isTypeaeadEnabled) {
            event.stopPropagation();
            return;
        } else {
            this.toggleCollpase(event);
        }
    }

    protected carretToggleClick(event: Event): void {
        event.stopPropagation();

        if (this.isCollapsed) {
            this.toggleCollpase(event);
        } else {
            this.contextMenuService.closeAllMenus();
        }
    }

    protected clear(event: Event): void {
        event.stopPropagation();

        this.contextMenuService.closeAllMenus();

        this.hasValueSelected = false;
        this.displayValue = '';
        this.value = '';

        if (this.isTypeaeadEnabled) {
            this.searchValue = '';
            this.internalOptions = this.originalOptions;
        }

        this.valueChange.emit(this.value);

        this.internalOptions.forEach((option: SelectOption) => {
            option.is_selected = false;
        });
    }

    protected setContainerClasses(): string {
        let classList: string = '';

        if (this.isInline) {
            classList = `${classList} ax-select__container--inline`;
        }

        if (!this.isValid) {
            classList = `${classList} ax-select__container--invalid`;
        }

        if (this.isDisabled) {
            classList = `${classList} ax-select__container--disabled`;
        }

        if (this.retainFocus) {
            classList = `${classList} ax-select__container--focus`;
        }

        return classList;
    }

    protected onSearchInputChange(value: string): void {
        this.searchValueChanged.next(value);
    }

    private toggleCollpase(event: Event): void {
        if (!_.isNil(event)) {
            event.stopPropagation();
        }

        this.contextMenuService.closeAllMenus();

        if (this.isCollapsed) {
            const optionData: SelectOptionsMenuData = {
                options: this.internalOptions,
                container_width: this.containerElement.nativeElement.offsetWidth,
                no_options_text: this.noOptionsText,
            };

            let position: 'top' | 'bottom' | 'left' | 'right' = 'bottom';

            const rect: DOMRect = this.containerElement.nativeElement.getBoundingClientRect();

            if (window.innerHeight < rect.bottom + 120) {
                position = 'top';
            }

            this.selectOptionsContainer = this.contextMenuService.toggleContextMenu(this.menuKey, SelectOptionsMenuComponent, optionData, this.containerElement.nativeElement, {
                position: position,
                elementSpacing: 4,
            });

            // Only triggered after selecting a value
            this.selectOptionsContainer.instance.afterClose.subscribe((selectedValue: SelectOption) => {
                this.internalOptions.forEach((option: SelectOption) => {
                    option.is_selected = false;
                });

                selectedValue.is_selected = true;
                this.displayValue = selectedValue.display_value;
                this.hasValueSelected = true;
                this.value = selectedValue.value;

                if (this.isTypeaeadEnabled) {
                    this.searchValue = this.displayValue;
                }

                this.valueChange.emit(this.value);

                this.containerElement.nativeElement.focus();
            });

            this.selectOptionsContainer.instance.onDispose.subscribe(() => {
                this.isCollapsed = true;
                this.retainFocus = false;
            });

            this.isCollapsed = false;

            if (!this.isInline) {
                this.retainFocus = true;
            }
        }

        if (this.isCollapsed) {
            this.selectOptionsContainer = undefined;
        }
    }

    private setSelectedOption(skipClone: boolean): void {
        if (this.isTypeaeadEnabled && !skipClone) {
            this.originalOptions = _.cloneDeep(this.internalOptions);
        }

        this.internalOptions.forEach((option: SelectOption) => {
            option.is_selected = false;
        });

        if (!_.isNil(this.value) && !_.isEmpty(this.value)) {
            const selectOption: SelectOption | undefined = this.internalOptions.find((option: SelectOption) => option.value === this.value);

            if (!_.isNil(selectOption)) {
                selectOption.is_selected = true;
                this.displayValue = selectOption.display_value;
                this.hasValueSelected = true;

                if (this.isTypeaeadEnabled) {
                    this.searchValue = selectOption.display_value;
                }
            } else {
                this.displayValue = '';
                this.hasValueSelected = false;

                if (this.isTypeaeadEnabled) {
                    this.searchValue = '';
                }
            }
        } else {
            this.displayValue = '';
            this.hasValueSelected = false;

            if (this.isTypeaeadEnabled) {
                this.searchValue = '';
            }
        }
    }
}
