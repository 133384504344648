import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TenantList } from '../models/tenant/tenant-list';
import { firstValueFrom } from 'rxjs';
import { TenantFilterDto } from '../dto/tenant/tenant-filter-dto';
import { Tenant } from '../models/tenant/tenant';
import { PagedResult } from '../models/paged-result';
import { TenantDto } from '../dto/tenant/tenant-dto';
import { TenantUserDto } from '../dto/tenant/tenant-user-dto';
import { TenantResourceDto } from '../dto/tenant/tenant-resource-dto';
import { TenantVersionDto } from '../dto/tenant/tenant-version-dto';
import { MutationFilterDto } from '../dto/mutation/mutation-filter-dto';
import { Mutation } from '../models/mutation/mutation';

@Injectable({
    providedIn: 'root',
})
export class TenantService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly externalHttpClient: HttpClient,
        private readonly httpBackend: HttpBackend
    ) {
        this.externalHttpClient = new HttpClient(httpBackend);
    }

    async get(id: number): Promise<Tenant> {
        return firstValueFrom(this.httpClient.get<Tenant>(`tenant/${id}`));
    }

    async filter(tenantFilterDto: TenantFilterDto): Promise<PagedResult<TenantList>> {
        return firstValueFrom(this.httpClient.post<PagedResult<TenantList>>('tenant/filter', JSON.stringify(tenantFilterDto)));
    }

    async filterMutations(id: number, mutationFilterDto: MutationFilterDto): Promise<PagedResult<Mutation>> {
        return firstValueFrom(this.httpClient.post<PagedResult<Mutation>>(`tenant/${id}/mutations`, JSON.stringify(mutationFilterDto)));
    }

    async create(tenantDto: TenantDto): Promise<Tenant> {
        return firstValueFrom(this.httpClient.post<Tenant>('tenant', JSON.stringify(tenantDto)));
    }

    async update(id: number, tenantDto: TenantDto): Promise<Tenant> {
        return firstValueFrom(this.httpClient.put<Tenant>(`tenant/${id}`, JSON.stringify(tenantDto)));
    }

    async handleTenantUser(id: number, tenantUserDto: TenantUserDto): Promise<Tenant> {
        return firstValueFrom(this.httpClient.put<Tenant>(`tenant/${id}/user`, JSON.stringify(tenantUserDto)));
    }

    async handleTenantResource(id: number, tenantResourceDto: TenantResourceDto): Promise<Tenant> {
        return firstValueFrom(this.httpClient.put<Tenant>(`tenant/${id}/resource`, JSON.stringify(tenantResourceDto)));
    }

    async handleTenantVersion(id: number, tenantVersionDto: TenantVersionDto): Promise<Tenant> {
        return firstValueFrom(this.httpClient.put<Tenant>(`tenant/${id}/version`, JSON.stringify(tenantVersionDto)));
    }

    async setMaintenanceMode(id: number, isMaintenanceMode: boolean): Promise<void> {
        firstValueFrom(this.httpClient.put(`tenant/${id}/maintenance/${isMaintenanceMode}`, null));
    }

    async delete(id: number): Promise<void> {
        firstValueFrom(this.httpClient.delete(`tenant/${id}`));
    }
}
