/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ComponentRef, DoCheck, ElementRef, EventEmitter, HostListener, Input, KeyValueChangeRecord, KeyValueChanges, KeyValueDiffer, KeyValueDiffers, OnInit, Output, ViewChild } from '@angular/core';
import { angularImports } from '../../utilities/global-import';
import { DatePickerType } from '../../menus/date-picker-menu/interfaces/date-picker-type';
import { ContextMenuService } from '../../menus/context-menu.service';
import { DatePickerData } from '../../menus/date-picker-menu/interfaces/date-picker-data';
import { BaseContextMenuComponent } from '../../menus/base-context-menu/base-context-menu.component';
import { DatePickerMenuComponent } from '../../menus/date-picker-menu/date-picker-menu.component';
import { Converters } from '../../utilities/converters';
import _ from 'lodash';
import { Toolkit } from '../../utilities/toolkit';

@Component({
    selector: 'ax-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrl: './date-picker.component.scss',
    standalone: true,
    imports: [angularImports],
})
export class DatePickerComponent implements OnInit, DoCheck {
    @Input() public date: Date;
    @Input() public firstDateValue: string = '';
    @Input() public secondDateValue: string = '';
    @Input() public isDisabled: boolean = false;
    @Input() public isValid: boolean = true;
    @Input() public placeholder: string = 'DD-MM-YYYY';
    @Input() public type: DatePickerType = DatePickerType.Default;
    @Input() public isInline: boolean = false;
    @Input() public tabIndex: number = 0;
    @Output() public dateSelected: EventEmitter<Date[]> = new EventEmitter<Date[]>();

    @ViewChild('datePickerContainer') private readonly container: ElementRef;

    public hasSelectedValue: boolean = false;

    private selectedDates: Date[] = [];
    private keyValueDiffer: KeyValueDiffer<string, any>;
    private readonly menuKey: string;

    constructor(
        private readonly differs: KeyValueDiffers,
        private readonly contextMenuService: ContextMenuService
    ) {
        this.keyValueDiffer = this.differs.find({}).create();
        this.menuKey = `date-picker-${Toolkit.generateId(10)}`;
    }

    ngOnInit(): void {
        if (!_.isEmpty(this.firstDateValue) || !_.isEmpty(this.secondDateValue)) {
            this.hasSelectedValue = true;
        }
    }

    ngDoCheck(): void {
        const changes: KeyValueChanges<string, any> = this.keyValueDiffer.diff(this);

        if (changes) {
            changes.forEachChangedItem((record: KeyValueChangeRecord<string, any>) => {
                if (record.key === 'firstDateValue') {
                    if (_.isEmpty(record.currentValue)) {
                        this.hasSelectedValue = false;
                    } else {
                        this.hasSelectedValue = true;
                    }
                }
            });
        }
    }

    protected setContainerClasses(): string {
        let classList: string = '';

        if (this.isInline) {
            classList = `ax-date-picker__container--inline`;
        }

        return classList;
    }

    protected firstDateChanged(dateString: string): void {
        const parsedDate: Date = Converters.toDateObject(dateString);

        if (!_.isNaN(parsedDate.getTime())) {
            this.selectedDates = [];
            this.firstDateValue = dateString;
            this.selectedDates.push(parsedDate);
            this.dateSelected.emit(this.selectedDates);
        }
    }

    protected toggle(event: Event): void {
        event.stopPropagation();

        const configData: DatePickerData = {
            type: this.type,
            first_date_value: this.toValidDateInput(this.firstDateValue),
            second_date_value: this.toValidDateInput(this.secondDateValue),
        };

        const menu: ComponentRef<BaseContextMenuComponent> = this.contextMenuService.toggleContextMenu(this.menuKey, DatePickerMenuComponent, configData, this.container.nativeElement, {
            position: 'bottom',
            elementSpacing: 4,
        });

        menu.instance.afterClose.subscribe((data: string[]) => {
            if (configData.type === DatePickerType.Default) {
                this.selectedDates = [];
                this.firstDateValue = data[0];
                this.selectedDates.push(Converters.toDateObject(this.firstDateValue));
            } else {
                this.selectedDates = [];

                this.firstDateValue = data[0];
                this.secondDateValue = data[1];
            }

            if (!_.isEmpty(data)) {
                this.hasSelectedValue = true;
            }

            this.dateSelected.emit(this.selectedDates);
        });
    }

    protected clear(event: Event): void {
        event.stopPropagation();
        this.firstDateValue = '';
        this.secondDateValue = '';

        this.dateSelected.emit([]);
    }

    private toValidDateInput(input: string): string {
        const parsedDate: Date = Converters.toDateObject(input);

        if (!_.isNaN(parsedDate.getTime())) {
            return Converters.toDate(parsedDate.toString());
        } else {
            return '';
        }
    }
}
