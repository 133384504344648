import { ValidationRule } from './validation-rule';

export class EmailValidationRule implements ValidationRule {
    public readonly key: string = 'email';
    public readonly errorMessage: string = '';

    private readonly expression: RegExp;

    constructor(expression: RegExp) {
        this.expression = expression;
        this.errorMessage = `is not a valid email`;
    }

    validateRule<T>(value: T): boolean {
        const val: string = value as string;
        return this.expression.test(val);
    }
}
