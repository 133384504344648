<div class="ax-page-template__container">
    @if (isAuthenticated()) {
        <div class="ax-page-template__nav-bar">
            <div class="ax-page-template__nav-bar__brand">
                <button [ax-button]="'primary'" [icon-only]="true" (click)="toggleNavigationMenu($event)">
                    <i class="fa-regular fa-bars"></i>
                </button>
                <div class="ax-page-template__nav-bar__brand__container" [routerLink]="'/'">
                    <img src="/assets/images/logo.svg" alt="logo">
                    <h4 class="ax-page-template__nav-bar__brand__container__text">Axerrio Internal</h4>
                </div>
            </div>
            @if (isUserContextSet) {
                <div #searchElement class="ax-page-template__nav-bar__search"  (click)="toggleSearchMenu($event)">
                    <ax-search [placeholder]="'Type / to search'" [(value)]="searchValue" [isMainSearch]="true" (valueChange)="onSearch($event)"></ax-search>
                </div>
                <div #userContextElement class="ax-page-template__nav-bar__user" (click)="toggleUsercontextMenu($event, userContextElement)">
                    <button [ax-button]="'primary'" [icon-only]="true" class="ax-page-template__nav-bar__user__navigation-button" (click)="toggleNotificationPanel($event)">
                        @if (getUnreadNotificationCount() > 0){
                            <div class="navigation-button__counter">
                                <small class="navigation-button__counter__text">{{getUnreadNotificationCount() > 9 ? '9+' : getUnreadNotificationCount()}}</small>
                            </div>
                        }
                        <i class="fa-regular fa-bell"></i>
                    </button>
                    <ax-avatar [imageUrl]="avatarUrl" [name]="userName"></ax-avatar>
                    <span class="ax-page-template__nav-bar__user__name">{{userName}}</span>
                    <i class="fa-solid fa-caret-down"></i>
                </div>
            }
        </div>
    }
    <div class="ax-page-template__content">
        <router-outlet></router-outlet>
    </div>
    <ax-side-panel #navigationPanel>
        <div class="ax-navigation-menu__container">
            <div class="ax-navigation-menu__header">
                <img src="/assets/images/logo.svg" alt="logo" class="ax-navigation-menu__header__logo" (click)="handleNavigation('/')" />
                <button ax-button [icon-only]="true" [transparent]="true" (click)="toggleNavigationMenu($event)">
                    <i class="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div class="ax-navigation-menu__items">
                @for (item of navigationItems; track item.route) {
                    <div class="ax-navigation-menu__items__item" (click)="handleNavigation(item.route)">
                        <i class="ax-navigation-menu__items__item__icon" [ngClass]="item.icon_class"></i>
                        <span>{{ item.title }}</span>
                    </div>
                }
            </div>
        </div>
    </ax-side-panel>
    <ax-side-panel #notificationPanel [orientation]="'right'" class="notifications-side-panel">
        <div class="ax-notification-menu__container">
            <div class="ax-notification-menu__header">
                <h3 class="ax-notification-menu__header__title">Notifications</h3>
                <button ax-button [icon-only]="true" [transparent]="true" (click)="toggleNotificationPanel($event)">
                    <i class="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div class="ax-notification-menu__content" [ngClass]="notifications.length === 0 ? 'ax-notification-menu__content--empty' : ''">
                @for (notification of notifications; track notification.id) {
                    <div class="ax-notification">
                        <div class="ax-notification__header">
                            <h6 class="ax-notification__header__title">{{notification.title}}</h6>
                            @if (notification.read_at === null) {
                                <div class="ax-notification__header__mark_as_read" [ax-tooltip]="'Mark as read'" [position]="'left'" [showDelay]="250" (click)="markNotificationAsRead(notification)"></div>
                            }
                        </div>
                        <div class="-ax-notification__content" [innerHTML]="constructNotificationMessage(notification)"></div>
                        <div class="ax-notification__triggerd_by">
                            <ax-avatar [imageUrl]="notification.triggered_by_avatar_url" [name]="notification.triggered_by_name" [size]="'small'"></ax-avatar>
                            <span class="ax-notification__triggerd_by__name">{{notification.triggered_by_name}} - {{notification.created_at.toString() | timeago}}</span>
                        </div>
                    </div>
                }
                @empty {
                    <div class="ax-notification-menu__content__empty">
                        <div class="ax-notification-menu__content__empty__icon__holder">
                            <i class="fa-duotone fa-solid fa-bells fa-xl"></i>
                        </div>
                        <span>You are all caught up</span>
                        <span>Once something happens we'll display it here</span>
                    </div>
                }
            </div>
            @if (notifications.length > 0) {
                <div class="ax-notification-menu__footer">
                    <button [ax-button]="'primary'" (click)="markAllNotificationsAsRead()">Mark all as read</button>
                    <button [ax-button]="'danger'" (click)="deleteAllNotifications()">Delete all notifications</button>
                </div>
            }
        </div>
    </ax-side-panel>
</div>
