<div class="ax-date-picker-menu__container">
    <div class="ax-date-picker-menu__navigation">
        @switch (visibleSection) {
            @case ('dates') {
                <div class="ax-date-picker-menu__navigation__current-month" (click)="setVisibleSection('years')">
                    <span>{{displayMonths[displayedMonth]}} {{displayedYear}}</span>
                    <i class="fa-solid fa-caret-down ax-date-picker-menu__navigation__current-month__caret"></i>
                </div>
            }

            @case ('months') {
                <div class="ax-date-picker-menu__navigation__months" (click)="setVisibleSection('dates')">
                    <span>{{displayedYear}}</span>
                    <i class="fa-solid fa-caret-up ax-date-picker-menu__navigation__months__caret"></i>
                </div>
            }
            @case ('years') {
                <div class="ax-date-picker-menu__navigation__year-range" (click)="setVisibleSection('dates')">
                    <span>{{years[0]}} - {{years[23]}}</span>
                    <i class="fa-solid fa-caret-up ax-date-picker-menu__navigation__year-range__caret"></i>
                </div>
            }
            @default {}
        }
        <div class="ax-date-picker-menu__navigation__arrows">
            <button [ax-button]="'primary'" [icon-only]="true" [transparent]="true" (click)="handlePrevious($event)">
                <i class="fa-light fa-angle-left"></i>
            </button>
            <button [ax-button]="'primary'" [icon-only]="true" [transparent]="true" (click)="handleNext($event)">
                <i class="fa-light fa-angle-right"></i>
            </button>
        </div>
    </div>
    @switch (visibleSection) {
        @case ('dates') {
            <div class="ax-date-picker-menu__dates__container">
                <div class="ax-date-picker-menu__dates__labels">
                    <span class="ax-date-picker-menu__dates__labels__label">Mon</span>
                    <span class="ax-date-picker-menu__dates__labels__label">Tue</span>
                    <span class="ax-date-picker-menu__dates__labels__label">Wed</span>
                    <span class="ax-date-picker-menu__dates__labels__label">Thu</span>
                    <span class="ax-date-picker-menu__dates__labels__label">Fri</span>
                    <span class="ax-date-picker-menu__dates__labels__label">Sat</span>
                    <span class="ax-date-picker-menu__dates__labels__label">Sun</span>
                </div>
                <div class="ax-date-picker-menu__dates__days">
                    @for (day of days; track day) {
                        <span
                            class="ax-date-picker-menu__dates__days__day"
                            [ngClass]="setDayClasses(day)"
                            (mouseenter)="setHoveredDate(day)"
                            (mouseleave)="clearHoveredDate()"
                            (click)="selectDay($event, day)">
                                {{day}}
                        </span>
                    }
                </div>
            </div>
        }
        @case ('months') {
            <div class="ax-date-picker-menu__months">
                @for (month of months; track month) {
                    <span class="ax-date-picker-menu__months__month" [ngClass]="setMonthClasses(month)" (click)="selectMonth(month)">{{displayMonths[month]}}</span>
                }
            </div>
        }
        @case ('years') {
            <div class="ax-date-picker-menu__years">
                @for (year of years; track year) {
                    <span class="ax-date-picker-menu__years__year" [ngClass]="setYearClasses(year)" (click)="selectYear(year)">{{year}}</span>
                }
            </div>
        }
        @default {}
    }
</div>