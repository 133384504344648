import { Directive, Input, ViewContainerRef } from '@angular/core';
import _ from 'lodash';

@Directive({
    standalone: true,
    selector: '[ax-tooltip]',
})
export class TooltipDirective {
    @Input('ax-tooltip') public text: string = '';
    @Input() public position: 'top' | 'right' | 'bottom' | 'left' = 'right';
    @Input() public showDelay: number = 1000;
    @Input() public hideDelay: number = 0;
    @Input() public spacing: number = 8;

    private tooltip: HTMLElement = null;
    private isHovered: boolean = false;

    constructor(private readonly viewRef: ViewContainerRef) {
        this.viewRef.element.nativeElement.addEventListener('mouseenter', () => {
            this.isHovered = true;

            _.delay(() => {
                if (this.isHovered) {
                    this.createTooltip();
                }
            }, this.showDelay);
        });

        this.viewRef.element.nativeElement.addEventListener('mouseleave', () => {
            this.isHovered = false;

            _.delay(() => {
                if (!this.isHovered) {
                    this.deleteTooltip();
                }
            }, this.hideDelay);
        });
    }

    deleteTooltip(): void {
        if (this.tooltip !== null) {
            this.tooltip.classList.add('ax__tooltip--closing');

            _.delay(() => {
                document.body.removeChild(this.tooltip);
                this.tooltip = null;
            }, 200);
        }
    }

    private createTooltip(): void {
        if (this.tooltip === null) {
            this.tooltip = document.createElement('small');
            this.tooltip.classList.add('ax__tooltip');
            this.tooltip.classList.add('ax__tooltip--opening');
            this.tooltip.innerHTML = this.text;

            this.setPositionStyle();
            document.body.appendChild(this.tooltip);

            _.delay(() => {
                this.tooltip.classList.remove('ax__tooltip--opening');
            }, 200);
        }
    }

    private setPositionStyle(): void {
        const rect: DOMRect = this.viewRef.element.nativeElement.getBoundingClientRect();

        switch (this.position) {
            case 'top':
                this.tooltip.style.bottom = `${window.innerHeight - (rect.top - this.spacing)}px`;
                this.tooltip.style.left = `${rect.left}px`;
                break;

            case 'right':
                this.tooltip.style.top = `${rect.top}px`;
                this.tooltip.style.left = `${rect.right + this.spacing}px`;
                break;

            case 'bottom':
                this.tooltip.style.top = `${rect.bottom + this.spacing}px`;
                this.tooltip.style.left = `${rect.left}px`;
                break;

            case 'left':
                this.tooltip.style.top = `${rect.top}px`;
                this.tooltip.style.right = `${window.innerWidth - (rect.left - this.spacing)}px`;
                break;
        }
    }
}
