<div class="ax-user-context-menu__container">
    <div class="user-menu-item" (click)="goToMyAccount($event)">
        <i class="fa-regular fa-address-card user-menu-item__icon"></i>
        <span>My Account</span>
    </div>
    <div class="user-menu-item" (click)="switchTheme($event)">
        @switch (theme) {
            @case('dark') {
                <i class="fa-regular fa-sun-bright user-menu-item__icon"></i>
            }

            @case ('') {
                <i class="fa-regular fa-moon-stars user-menu-item__icon"></i>
            }

            @default {
                <i class="fa-regular fa-moon-stars user-menu-item__icon"></i>
            }
        }
        <span>Switch Theme</span>
    </div>
    <div class="user-menu-item" (click)="logout($event)">
        <i class="fa-regular fa-person-from-portal user-menu-item__icon"></i>
        <span>Logout</span>
    </div>
</div>
