import { Component, OnInit, ViewChildren } from '@angular/core';
import { angularImports, pipeImports } from '../../../utilities/global-import';
import { CanDeactivate } from '../../../middleware/can-deactivate.guard';
import { DialogComponentRef } from '../../../components/dialog/models/dialog-component-ref';
import { ConfirmationDialogComponent } from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { DialogService } from '../../../components/dialog/dialog.service';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/user/user';
import _ from 'lodash';
import { AvatarComponent } from '../../../components/avatar/avatar.component';
import { InputDirective } from '../../../directives/input.directive';
import { ButtonDirective } from '../../../directives/button.directive';
import { ValidateDirective } from '../../../validator/directives/validate.directive';
import { ValidateErrorDirective } from '../../../validator/directives/validate-error.directive';
import { LoaderComponent } from '../../../components/loader/loader.component';
import { ToggleButtonComponent } from '../../../components/toggle-button/toggle-button.component';
import { ValidationHost } from '../../../validator/validation-host';
import { Validator } from '../../../validator/validator';
import { UpdateUserSelfDto } from '../../../dto/user/update-user-self-dto';
import { ToastService } from '../../../components/toast/toast.service';
import { AuthorizationService } from '../../../services/authorization.service';
import { EditAvatarDialogComponent } from '../../../dialogs/edit-avatar-dialog/edit-avatar-dialog.component';
import { UpdatePasswordDialogComponent } from '../../../dialogs/update-password-dialog/update-password-dialog.component';
import { Router } from '@angular/router';
import { Toolkit } from '../../../utilities/toolkit';
import { NotificationSetting } from '../../../models/user/notification-setting';
import { NotificationSettingService } from '../../../services/notification-setting.service';

@Component({
    standalone: true,
    selector: 'ax-my-account',
    templateUrl: './my-account-page.component.html',
    styleUrl: './my-account-page.component.scss',
    imports: [angularImports, AvatarComponent, InputDirective, ButtonDirective, pipeImports, ValidateDirective, ValidateErrorDirective, LoaderComponent, ToggleButtonComponent],
})
export class MyAccountPageComponent implements OnInit, ValidationHost, CanDeactivate {
    protected isLoading: boolean = false;
    protected user: User;

    protected notificationSettings: NotificationSetting[] = [];

    private userClone: User;
    private validator: Validator;

    @ViewChildren(ValidateDirective) private readonly validateDirectives: ValidateDirective[];
    @ViewChildren(ValidateErrorDirective) private readonly validateErrorDirectives: ValidateErrorDirective[];

    constructor(
        private readonly dialogService: DialogService,
        private readonly userService: UserService,
        private readonly toastService: ToastService,
        private readonly authorizationService: AuthorizationService,
        private readonly router: Router,
        private readonly notificationSettingService: NotificationSettingService
    ) {}

    async ngOnInit(): Promise<void> {
        this.isLoading = true;

        try {
            this.user = await this.userService.me();
            this.notificationSettings = await this.notificationSettingService.get();
            this.userClone = _.cloneDeep(this.user);
        } catch {
            this.toastService.danger('Error', 'Unable to load user information');
        } finally {
            this.validator = new Validator(this);
            _.delay(() => {
                this.isLoading = false;
            }, 250);
        }
    }

    getValidationItems(): ValidateDirective[] {
        return this.validateDirectives;
    }

    getValidationErrorItems(): ValidateErrorDirective[] {
        return this.validateErrorDirectives;
    }

    canDeactivate(): Promise<boolean> {
        if (this.hasChanges()) {
            const dialogComponentRef: DialogComponentRef<ConfirmationDialogComponent> = this.dialogService.createDialog(ConfirmationDialogComponent, {
                title: 'Unsaved changes',
                text: `You have unsaved changes, do you want to discard these changes?`,
                confirmation_button_text: 'Discard',
                cancel_button_text: 'Cancel',
                button_type: 'danger',
            });

            return new Promise<boolean>((resolve) => {
                dialogComponentRef.afterClose.subscribe((hasConfirmation: boolean) => {
                    resolve(hasConfirmation);
                });
            });
        } else {
            return Promise.resolve(true);
        }
    }

    protected async update(): Promise<void> {
        this.validator.resetValidationStates();

        const isValid: boolean = this.validator.validate();

        if (isValid) {
            try {
                const updateUserSelfDto: UpdateUserSelfDto = {
                    email_address: this.user.email_address,
                    firstname: this.user.firstname,
                    lastname: this.user.lastname,
                    notification_settings: this.user.notification_settings,
                };

                this.user = await this.userService.updateSelf(updateUserSelfDto);

                if (!_.isNil(this.user)) {
                    this.toastService.success('Success', `User ${this.user.firstname} ${this.user.lastname} updated`);
                    this.userClone = _.cloneDeep(this.user);
                    await this.authorizationService.reloadUser();
                } else {
                    this.toastService.danger('Error', 'Error while updating user');
                }
            } catch (err) {
                Toolkit.handleApiValidationErrors(err, this.validator, this.toastService);
            }
        } else {
            this.toastService.danger('Validation error', 'Some fields did not pass validation checks');
        }
    }

    protected hasChanges(): boolean {
        return !_.isEqual(this.user, this.userClone);
    }

    protected getFullName(): string {
        return `${this.user.firstname} ${this.user.lastname}`;
    }

    protected openEditAvatarDialog(): void {
        const dialogComponentRef: DialogComponentRef<EditAvatarDialogComponent> = this.dialogService.createDialog(EditAvatarDialogComponent, {
            image_url: this.user.avatar_url,
            full_name: this.getFullName(),
            user_id: this.user.id,
            is_admin_upload: false,
        });

        dialogComponentRef.afterClose.subscribe(async () => {
            this.user = await this.userService.me();
            this.userClone = _.cloneDeep(this.user);
            await this.authorizationService.reloadUser();
        });
    }

    protected openChangePasswordDialog(): void {
        const dialogComponentRef: DialogComponentRef<UpdatePasswordDialogComponent> = this.dialogService.createDialog(UpdatePasswordDialogComponent);

        dialogComponentRef.afterClose.subscribe((isUpdated: boolean) => {
            if (isUpdated) {
                this.authorizationService.handleLogout();
                this.router.navigateByUrl('/login');
                this.toastService.success('Success', 'Password changed');
            }
        });
    }

    protected hasNotificationSet(notificationKey: string): boolean {
        return this.user.notification_settings.includes(notificationKey);
    }

    protected setNotificationSetting(notificationSettingKey: string, isNotificationSettingSet: boolean): void {
        if (isNotificationSettingSet) {
            this.user.notification_settings.push(notificationSettingKey);
        } else {
            const notificationSettingIndex: number = this.user.notification_settings.indexOf(notificationSettingKey);

            if (notificationSettingIndex > -1) {
                this.user.notification_settings.splice(notificationSettingIndex, 1);
            }
        }
    }
}
