import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { angularImports } from '../../utilities/global-import';
import { ButtonDirective } from '../../directives/button.directive';
import _ from 'lodash';
import { SplitButtonOption } from './interfaces/split-button-options';
import { Toolkit } from '../../utilities/toolkit';
import { ContextMenuService } from '../../menus/context-menu.service';
import { SplitButtonContextMenuComponent } from '../../menus/split-button-context-menu/split-button-context-menu.component';

@Component({
    standalone: true,
    selector: 'ax-split-button',
    templateUrl: './split-button.component.html',
    styleUrl: './split-button.component.scss',
    imports: [angularImports, ButtonDirective],
})
export class SplitButtonComponent {
    @Input() primaryButtonText: string = 'Button';
    @Input() primaryButtonIconClass: string | null = null;
    @Input() primaryButtonCallback: (event: Event) => void;
    @Input() splitOptions: SplitButtonOption[] = [];

    @ViewChild('splitButtonContainer') private readonly splitButtonContainer: ElementRef<HTMLDivElement>;

    private readonly menuKey: string;

    constructor(private readonly contextMenuService: ContextMenuService) {
        this.menuKey = `split-button-${Toolkit.generateId(10)}`;
    }

    protected hasIconClassSet(): boolean {
        return !_.isNil(this.primaryButtonIconClass) && !_.isEmpty(this.primaryButtonIconClass);
    }

    protected handlePrimaryButtonClick(event: Event): void {
        if (_.isFunction(this.primaryButtonCallback)) {
            this.primaryButtonCallback(event);
        }
    }

    protected toggleSplitContextMenu(event: Event): void {
        event.stopPropagation();

        let position: 'top' | 'bottom' | 'left' | 'right' | 'bottom-right' | 'top-right' = 'bottom-right';

        const rect: DOMRect = this.splitButtonContainer.nativeElement.getBoundingClientRect();

        if (window.innerHeight < rect.bottom + 120) {
            position = 'top-right';
        }

        this.contextMenuService.toggleContextMenu(this.menuKey, SplitButtonContextMenuComponent, this.splitOptions, this.splitButtonContainer.nativeElement, {
            position: position,
            elementSpacing: 4,
        });
    }

    getContainerElement(): HTMLElement {
        return this.splitButtonContainer.nativeElement;
    }
}
