import { Component, Input } from '@angular/core';
import { angularImports } from '../../../../utilities/global-import';

@Component({
    selector: 'ax-table-column-count',
    templateUrl: './table-column-count.component.html',
    styleUrl: './table-column-count.component.scss',
    standalone: true,
    imports: [angularImports],
})
export class TableColumnCountComponent {
    @Input() public value: number = 0;
    @Input() public context: string = 'Entity';

    protected getText(): string {
        if (this.value === 0) {
            return `No ${this.getContextText()}`;
        } else {
            return `${this.value} ${this.getContextText()}`;
        }
    }

    protected hasValue(): boolean {
        return this.value > 0;
    }

    private getContextText(): string {
        if (this.value > 1 || this.value === 0) {
            return `${this.context}s`;
        }

        return this.context;
    }
}
