<div class="ax-status-metric__container">
    <h6 class="ax-status-metric__title">{{title}}</h6>
    <div class="ax-status-metric__block__container">
        @for (block of metricBlocks; track block) {
            <div class="ax-status-metric__block" [ngClass]="setBlockClasses(block)" [ax-tooltip]="createTooltipText(block)" [showDelay]="10" [position]="'bottom'" [spacing]="2"></div>
        }
    </div>
    <div class="ax-status-metric__footer">
        <span class="ax-status-metric__footer__label">{{startLabel}}</span>
        <div class="ax-status-metric__footer__divider"></div>
        <span class="ax-status-metric__footer__label">{{calculateUptimePercentage()}} % uptime</span>
        <div class="ax-status-metric__footer__divider"></div>
        <span class="ax-status-metric__footer__label">{{endLabel}}</span>
    </div>
</div>