/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, ComponentRef, ElementRef, EnvironmentInjector, HostListener, inject, Injector, StaticProvider, Type, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import _ from 'lodash';
import { ContextMenuDirective } from '../context-menu-directive';
import { CONTEXT_MENU_DATA } from '../interfaces/contex-menu-config';
import { StateService } from '../../state/state.service';
import { Toolkit } from '../../utilities/toolkit';

@Component({
    standalone: true,
    selector: 'ax-base-context-menu',
    templateUrl: './base-context-menu.component.html',
    styleUrl: './base-context-menu.component.scss',
    imports: [ContextMenuDirective],
})
export class BaseContextMenuComponent implements AfterViewInit {
    public readonly onDispose: Observable<any>;
    public readonly afterClose: Observable<any>;

    private readonly stateService: StateService;
    private readonly stateKey: string;

    private _onDisplose: Subject<void> = new Subject<void>();
    private _afterClose: Subject<any> = new Subject<any>();
    private clickedWithinElement: boolean = false;
    private childComponent: ComponentRef<any>;

    @ViewChild('menuContainer') private readonly menuContainer: ElementRef;
    @ViewChild(ContextMenuDirective) private readonly contextMenuDirective: ContextMenuDirective;

    constructor(private readonly environmentInjector: EnvironmentInjector) {
        this.onDispose = this._onDisplose.asObservable();
        this.afterClose = this._afterClose.asObservable();

        this.stateService = inject(StateService);
        this.stateKey = Toolkit.generateId(10);
    }

    @HostListener('document:click')
    clickOutside(): void {
        if (!this.clickedWithinElement) {
            this.dispose();
        }

        this.clickedWithinElement = false;
    }

    ngAfterViewInit(): void {
        this.menuContainer.nativeElement.addEventListener('click', () => {
            this.clickedWithinElement = true;
        });

        this.menuContainer.nativeElement.classList.add('ax-base-context-menu__container--opening');

        _.delay(() => {
            this.menuContainer.nativeElement.classList.remove('ax-base-context-menu__container--opening');
        }, 200);

        this.stateService.saveComponentState(this.stateKey, this);
    }

    createMenuContent<T>(element: Type<T>, data?: any): void {
        this.childComponent = this.contextMenuDirective.viewRef.createComponent<T>(element, {
            environmentInjector: this.environmentInjector,
            injector: this.createInjector(data),
        });
    }

    handleDestroy(): void {
        this.menuContainer.nativeElement.classList.add('ax-base-context-menu__container--closing');

        _.delay(() => {
            this.menuContainer.nativeElement.classList.remove('ax-base-context-menu__container--closing');
        }, 350);
    }

    dispose(): void {
        this.stateService.removeComponentState(this.stateKey);
        this._onDisplose.next();
    }

    close(data: any = null): void {
        this._afterClose.next(data);
        this.dispose();
    }

    getChildComponent<T>(): T {
        return this.childComponent.instance as T;
    }

    private createInjector(data: any): Injector {
        const providers: StaticProvider[] = [
            { provide: CONTEXT_MENU_DATA, useValue: data },
            { provide: BaseContextMenuComponent, useValue: this },
        ];

        return Injector.create({ providers: providers });
    }
}
