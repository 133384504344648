import { Component, Input } from '@angular/core';
import { angularImports } from '../../../../utilities/global-import';

@Component({
    selector: 'ax-table-column-status',
    templateUrl: './table-column-status.component.html',
    styleUrl: './table-column-status.component.scss',
    standalone: true,
    imports: [angularImports],
})
export class TableColumnStatusComponent {
    @Input() public status: number = 0;
    @Input() public statusText: string = 'nothing';
}
