import { Component, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { commonViewImports } from '../../../utilities/global-import';
import { TableComponent } from '../../../components/table/table.component';
import { TableColumnType } from '../../../components/table/enums/table-column-property-type';
import { TableColumn } from '../../../components/table/interfaces/table-column';
import { ButtonDirective } from '../../../directives/button.directive';
import _ from 'lodash';
import { TableColumnOptionKey } from '../../../components/table/enums/table-column-option-key';
import { TenantList } from '../../../models/tenant/tenant-list';
import { TenantService } from '../../../services/tenant.service';
import { SortDirection } from '../../../components/table/enums/sort-direction';
import { SidePanelComponent } from '../../../components/side-panel/side-panel.component';
import { SelectComponent } from '../../../components/select/select.component';
import { SelectOption } from '../../../components/select/interfaces/select-option';
import { DatePickerComponent } from '../../../components/date-picker/date-picker.component';
import { Converters } from '../../../utilities/converters';
import { PagedResult } from '../../../models/paged-result';
import { ToastService } from '../../../components/toast/toast.service';
import { StateService } from '../../../state/state.service';
import { StateStoreOptions } from '../../../state/models/state-store-options';
import { StateObject } from '../../../state/models/state-object';
import { TenantFilterDto } from '../../../dto/tenant/tenant-filter-dto';
import { FilterDto } from '../../../dto/filter-dto';
import { AuthorizationService } from '../../../services/authorization.service';
import { TenantDto } from '../../../dto/tenant/tenant-dto';
import { ValidationHost } from '../../../validator/validation-host';
import { ValidateErrorDirective } from '../../../validator/directives/validate-error.directive';
import { ValidateDirective } from '../../../validator/directives/validate.directive';
import { Validator } from '../../../validator/validator';
import { Tenant } from '../../../models/tenant/tenant';
import { Router } from '@angular/router';
import { Toolkit } from '../../../utilities/toolkit';

@Component({
    standalone: true,
    selector: 'ax-tenant-overview-page',
    templateUrl: './tenant-overview-page.component.html',
    styleUrl: './tenant-overview-page.component.scss',
    imports: [commonViewImports, TableComponent, ButtonDirective, SidePanelComponent, SelectComponent, DatePickerComponent],
})
export class TenantOverviewPageComponent implements ValidationHost, OnInit, OnDestroy {
    @ViewChild('filterSidePanel') private readonly filterPanel: SidePanelComponent;
    @ViewChild('tenantCreateSidePanel') private readonly tenantCreateSidePanel: SidePanelComponent;
    @ViewChild(TableComponent) private readonly tableComponent: TableComponent;

    @ViewChildren(ValidateDirective) private readonly validateDirectives: ValidateDirective[];
    @ViewChildren(ValidateErrorDirective) private readonly validateErrorDirectives: ValidateErrorDirective[];

    protected hasCreatePermission: boolean = false;

    protected isLoading: boolean = true;
    protected tableData: TenantList[] = [];
    protected activeFilterCount: number = 0;
    protected totalRecordCount: number = 0;
    protected tableScrollPosition: number = 0;
    protected skipSaveState: boolean = false;

    protected tenantDto: TenantDto = {
        name: '',
        code: '',
    };

    protected tableColums: TableColumn[] = [
        {
            caption: 'Name',
            property_name: 'name',
            type: TableColumnType.Default,
            is_sortable: true,
            route_url: 'tenant',
            route_property_name: 'id',
        },
        {
            caption: 'Code',
            property_name: 'code',
            type: TableColumnType.Default,
            is_sortable: true,
        },
        {
            caption: 'Status',
            property_name: 'status',
            type: TableColumnType.Status,
            options: [
                {
                    key: TableColumnOptionKey.StatusNumber,
                    value: 'state',
                },
                {
                    key: TableColumnOptionKey.StatusText,
                    value: 'status_text',
                },
            ],
        },
        {
            caption: 'Resources',
            property_name: 'resource_count',
            type: TableColumnType.Count,
            options: [
                {
                    key: TableColumnOptionKey.CountContext,
                    value: 'Resource',
                },
            ],
        },
        {
            caption: 'Users',
            property_name: 'user_count',
            type: TableColumnType.Count,
            options: [
                {
                    key: TableColumnOptionKey.CountContext,
                    value: 'User',
                },
            ],
        },
        {
            caption: 'Created',
            property_name: 'created_at',
            type: TableColumnType.TimeAgo,
            is_sortable: true,
        },
        {
            caption: 'Modified',
            property_name: 'modified_at',
            type: TableColumnType.TimeAgo,
            is_sortable: true,
        },
    ];

    protected propertyOptions: SelectOption[] = [
        {
            display_value: 'Name',
            value: 'name',
        },
        {
            display_value: 'Code',
            value: 'code',
        },
        {
            display_value: 'Status',
            value: 'state',
        },
        {
            display_value: 'Created',
            value: 'created_at',
        },
        {
            display_value: 'Modified',
            value: 'modified_at',
        },
    ];

    protected tenantFilterDto: TenantFilterDto = {
        sort_property: null,
        sort_order: null,
        skip: 0,
        take: 50,
        filters: [],
    };

    protected stateOptions: SelectOption[] = [
        {
            display_value: 'Ready',
            value: '0',
        },
        {
            display_value: 'Warning',
            value: '1',
        },
        {
            display_value: 'Error',
            value: '2',
        },
    ];

    private dateConditions: SelectOption[] = [
        {
            display_value: 'Greater than',
            value: 'greater_than',
        },
        {
            display_value: 'Greater than or equals',
            value: 'greater_than_equals',
        },
        {
            display_value: 'Equals',
            value: 'equals',
        },
        {
            display_value: 'Not equals',
            value: 'not_equals',
        },
        {
            display_value: 'Less than',
            value: 'less_than',
        },
        {
            display_value: 'Less than or equals',
            value: 'less_than_equals',
        },
    ];

    private stringTypeConditionOptions: SelectOption[] = [
        {
            display_value: 'Starts with',
            value: 'starts_with',
        },
        {
            display_value: 'Ends with',
            value: 'ends_with',
        },
        {
            display_value: 'Contains',
            value: 'contains',
        },
        {
            display_value: 'Not contains',
            value: 'not_contains',
        },
    ];

    private equalsConditionOptions: SelectOption[] = [
        {
            display_value: 'Equals',
            value: 'equals',
        },
        {
            display_value: 'Not equals',
            value: 'not_equals',
        },
    ];

    private stateStoreOptions: StateStoreOptions = new StateStoreOptions(1, 5, true);
    private hasMoreData: boolean = false;
    private validator: Validator;

    constructor(
        private readonly tenantService: TenantService,
        private readonly toastService: ToastService,
        private readonly stateService: StateService,
        private readonly authorizationService: AuthorizationService,
        private readonly router: Router
    ) {}

    async ngOnInit(): Promise<void> {
        const state: StateObject | null = this.stateService.getObjectState('tenant-overview');

        if (!_.isNil(state)) {
            this.isLoading = true;

            this.tableColums = state.getValue('table-columns');
            this.tableData = state.getValue('table-data');
            this.totalRecordCount = state.getValue('record-count');
            this.hasMoreData = state.getValue('has-more-data');
            this.tenantFilterDto = state.getValue('dto');
            this.tableScrollPosition = state.getValue('scroll-position');
            this.activeFilterCount = this.tenantFilterDto.filters.length;

            _.delay(() => {
                this.isLoading = false;
            }, 100);
        } else {
            try {
                this.isLoading = true;
                const result: PagedResult<TenantList> = await this.tenantService.filter(this.tenantFilterDto);

                this.tableData = result.data;
                this.totalRecordCount = result.total_count;
                this.hasMoreData = result.has_more_data;
            } catch {
                this.toastService.danger('Fetch error', 'Unable to fetch tenants');
            } finally {
                _.delay(() => {
                    this.isLoading = false;
                }, 100);

                this.validator = new Validator(this);
            }
        }

        this.hasCreatePermission = await this.authorizationService.hasPermission('Tenants.Write');
    }

    ngOnDestroy(): void {
        if (!this.skipSaveState) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const stateItems: Map<string, any> = new Map<string, any>([
                ['table-data', this.tableData],
                ['record-count', this.totalRecordCount],
                ['has-more-data', this.hasMoreData],
                ['dto', this.tenantFilterDto],
                ['scroll-position', this.tableScrollPosition],
                ['table-columns', this.tableColums],
            ]);

            this.stateService.saveObjectState('tenant-overview', stateItems, this.stateStoreOptions);
        }
    }

    getValidationItems(): ValidateDirective[] {
        return this.validateDirectives;
    }

    getValidationErrorItems(): ValidateErrorDirective[] {
        return this.validateErrorDirectives;
    }

    protected async onSort(column: TableColumn): Promise<void> {
        switch (column.sort_direction) {
            case SortDirection.Ascending:
                this.tenantFilterDto.sort_order = 'asc';
                this.tenantFilterDto.sort_property = column.property_name;
                break;

            case SortDirection.Descending:
                this.tenantFilterDto.sort_order = 'desc';
                this.tenantFilterDto.sort_property = column.property_name;
                break;

            default:
                this.tenantFilterDto.sort_order = null;
                this.tenantFilterDto.sort_property = null;
                break;
        }

        try {
            this.clearInvalidFilters();
            this.tenantFilterDto.skip = 0;

            this.isLoading = true;
            const result: PagedResult<TenantList> = await this.tenantService.filter(this.tenantFilterDto);
            this.hasMoreData = result.has_more_data;
            this.tableData = result.data;
        } catch {
            this.toastService.danger('Fetch error', 'Unable to fetch tenants');
        } finally {
            _.delay(() => {
                this.isLoading = false;
            }, 100);

            this.tableComponent.scrollToTop();
        }
    }

    protected async onScroll(): Promise<void> {
        if (this.hasMoreData) {
            try {
                this.clearInvalidFilters();

                this.tenantFilterDto.skip += this.tenantFilterDto.take;
                const result: PagedResult<TenantList> = await this.tenantService.filter(this.tenantFilterDto);

                this.hasMoreData = result.has_more_data;
                this.tableData = this.tableData.concat(result.data);
            } catch {
                this.toastService.danger('Fetch error', 'Unable to fetch tenants');
            }
        }
    }

    protected async applyFilters(): Promise<void> {
        this.filterPanel.toggle();

        this.clearInvalidFilters();

        this.tenantFilterDto.skip = 0;

        try {
            this.isLoading = true;
            this.activeFilterCount = this.tenantFilterDto.filters.length;

            const result: PagedResult<TenantList> = await this.tenantService.filter(this.tenantFilterDto);
            this.totalRecordCount = result.total_count;
            this.hasMoreData = result.has_more_data;
            this.tableData = result.data;
        } catch {
            this.toastService.danger('Fetch error', 'Unable to fetch tenants');
        } finally {
            _.delay(() => {
                this.isLoading = false;
            }, 100);

            this.tableComponent.scrollToTop();
        }
    }

    protected async createTenant(): Promise<void> {
        const isValid: boolean = this.validator.validate();

        if (isValid) {
            try {
                const tenant: Tenant = await this.tenantService.create(this.tenantDto);

                if (!_.isNil(tenant)) {
                    this.router.navigateByUrl(`/tenant/${tenant.id}`);
                    this.toastService.success('Tenant Created', `Tenant ${tenant.name} created`);
                    this.skipSaveState = true;
                }
            } catch (err) {
                Toolkit.handleApiValidationErrors(err, this.validator, this.toastService);
            }
        } else {
            this.toastService.danger('Validation error', 'Some fields did not pass validation checks');
        }
    }

    protected hasValidFilters(): boolean {
        if (this.tenantFilterDto.filters.length === 0) {
            return true;
        } else {
            let isValid: boolean = true;

            this.tenantFilterDto.filters.forEach((filter: FilterDto) => {
                if (_.isEmpty(filter.property_name) || _.isEmpty(filter.condition) || _.isEmpty(filter.value)) {
                    isValid = false;
                }
            });

            return isValid;
        }
    }

    protected toggleFilterPanel(): void {
        this.filterPanel.toggle();
    }

    protected setValueType(propertyName: string, filter: FilterDto): void {
        switch (propertyName) {
            case 'name':
                filter.value_type = 'string';
                break;

            case 'code':
                filter.value_type = 'string';
                break;

            case 'state':
                filter.value_type = 'state_enum';
                break;

            case 'created_at':
                filter.value_type = 'date';
                break;

            case 'modified_at':
                filter.value_type = 'date';
                break;

            default:
                filter.value_type = '';
                break;
        }

        filter.condition = '';
        filter.value = '';
    }

    protected getPropertyOptions(filter: FilterDto): SelectOption[] {
        const options: SelectOption[] = [];

        this.propertyOptions.forEach((option: SelectOption) => {
            const existingOption: FilterDto | undefined = this.tenantFilterDto.filters.find((x: FilterDto) => x.property_name === option.value);

            if (existingOption === undefined) {
                options.push(option);
            } else if (filter.property_name === option.value) {
                options.push(option);
            }
        });

        return options;
    }

    protected getConditionOptions(filter: FilterDto): SelectOption[] {
        switch (filter.value_type) {
            case 'string':
                return this.stringTypeConditionOptions.concat(this.equalsConditionOptions);

            case 'state_enum':
                return this.equalsConditionOptions;

            case 'date':
                return this.dateConditions;

            default:
                return [];
        }
    }

    protected hasValidPropertyNameSet(filter: FilterDto): boolean {
        return !_.isNil(filter.property_name) && !_.isEmpty(filter.property_name);
    }

    protected hasValidConditionSet(filter: FilterDto): boolean {
        return !_.isNil(filter.condition) && !_.isEmpty(filter.condition);
    }

    protected addFilterRow(): void {
        this.tenantFilterDto.filters.push({
            property_name: '',
            condition: '',
            value: '',
            value_type: '',
        });
    }

    protected removeFitler(index: number): void {
        this.tenantFilterDto.filters.splice(index, 1);
    }

    protected reset(): void {
        this.tenantFilterDto.filters = [];
    }

    protected dateChanged(dates: Date[], filter: FilterDto): void {
        if (!_.isEmpty(dates)) {
            filter.value = Converters.toDate(dates[0].toString(), 'YYYY-MM-DD HH:mm:ss');
        } else {
            filter.value = '';
        }
    }

    protected displayDate(value: string): string {
        if (!_.isEmpty(value)) {
            return Converters.toDate(value);
        } else {
            return '';
        }
    }

    protected getRecordCountText(): string {
        if (this.totalRecordCount > 1) {
            return `${this.totalRecordCount} records`;
        } else if (this.totalRecordCount === 0) {
            return '';
        } else {
            return `${this.totalRecordCount} record`;
        }
    }

    protected toggleTenantCreatePanel(): void {
        this.tenantCreateSidePanel.toggle();
    }

    protected resetValidationState(): void {
        this.validator.resetValidationStates();
    }

    private clearInvalidFilters(): void {
        const filterCopy: FilterDto[] = _.cloneDeep(this.tenantFilterDto.filters);

        filterCopy.forEach((filter: FilterDto) => {
            const foundFilter: FilterDto | undefined = this.tenantFilterDto.filters.find((x: FilterDto) => x.property_name === filter.property_name);

            if (foundFilter !== undefined) {
                if (_.isEmpty(foundFilter.property_name) || _.isEmpty(foundFilter.condition) || _.isEmpty(foundFilter.value)) {
                    const index: number = this.tenantFilterDto.filters.indexOf(foundFilter);

                    if (index > -1) {
                        this.tenantFilterDto.filters.splice(index, 1);
                    }
                }
            }
        });
    }
}
