import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { BaseContextMenuComponent } from '../base-context-menu/base-context-menu.component';
import { CONTEXT_MENU_DATA } from '../interfaces/contex-menu-config';
import { SplitButtonOption } from '../../components/split-button/interfaces/split-button-options';
import _ from 'lodash';
import { angularImports } from '../../utilities/global-import';

@Component({
    standalone: true,
    selector: 'ax-split-button-context-menu',
    templateUrl: './split-button-context-menu.component.html',
    styleUrl: './split-button-context-menu.component.scss',
    imports: [angularImports],
})
export class SplitButtonContextMenuComponent implements OnInit {
    protected options: SplitButtonOption[] = [];

    constructor(
        @Inject(BaseContextMenuComponent) private readonly baseContextMenu: BaseContextMenuComponent,
        @Inject(CONTEXT_MENU_DATA) private readonly data: SplitButtonOption[]
    ) {}

    ngOnInit(): void {
        this.options = this.data;
    }

    protected hasIconClassSet(splitButtonOption: SplitButtonOption): boolean {
        return !_.isNil(splitButtonOption.icon_class) && !_.isEmpty(splitButtonOption.icon_class);
    }

    protected handleCallback(splitButtonOption: SplitButtonOption): void {
        if (_.isFunction(splitButtonOption.callback)) {
            splitButtonOption.callback();
            this.baseContextMenu.close();
        }
    }
}
