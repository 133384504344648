/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable } from '@angular/core';
import { StateObject } from './models/state-object';
import _ from 'lodash';
import { StateStoreOptions } from './models/state-store-options';
import { StateStorageLocation } from './models/state-storage-location';
import { STATE_CONFIG, StateConfig } from './interfaces/state-config';
import { BaseContextMenuComponent } from '../menus/base-context-menu/base-context-menu.component';
import { SidePanelComponent } from '../components/side-panel/side-panel.component';
import { ComponentState } from './models/component-state';
import { DialogComponent } from '../components/dialog/dialog.component';

@Injectable({
    providedIn: 'root',
})
export class StateService {
    private readonly stateObjectMap: Map<string, StateObject> = new Map<string, StateObject>();
    private readonly componentStates: Map<string, ComponentState> = new Map<string, ComponentState>();
    private readonly stateStorageLocation: StateStorageLocation;
    private readonly sessionStorageKeyPrefix: string = 'ax-state-storage';

    constructor(@Inject(STATE_CONFIG) stateConfig: StateConfig) {
        this.stateStorageLocation = stateConfig.stateStorageLocation;
    }

    saveObjectState(key: string, stateItems: Map<string, any>, options?: StateStoreOptions): void {
        const keyExists: boolean = this.stateObjectMap.has(key);

        if (keyExists) {
            const stateObject: StateObject | null = this.getObjectState(key);

            // In case of the very very very rare scenario that we want to update the state while it is being evicted we re-create the state.
            // Meaning that also the sliding expiration (if set) will be reset to it's initial value.
            if (_.isNil(stateObject)) {
                this.createAndSaveState(key, stateItems, options);
            } else {
                stateObject.update(stateItems);
            }
        } else {
            this.createAndSaveState(key, stateItems, options);
        }
    }

    getObjectState(key: string): StateObject | null {
        const stateObject: StateObject | undefined = this.stateObjectMap.get(key);

        if (_.isUndefined(stateObject)) {
            return null;
        }

        return stateObject;
    }

    invalidateObjectState(key: string): void {
        const keyExists: boolean = this.stateObjectMap.has(key);

        if (keyExists) {
            this.stateObjectMap.delete(key);
        }
    }

    saveComponentState(key: string, component: any): void {
        let layer: number = 0;

        if (component instanceof SidePanelComponent) {
            layer = 0;
        }

        if (component instanceof BaseContextMenuComponent) {
            layer = 1;
        }

        if (component instanceof DialogComponent) {
            layer = 2;
        }

        const componentStateObject: ComponentState = new ComponentState(component, layer);
        this.componentStates.set(key, componentStateObject);
    }

    getTopLayerComponentState(): any | null {
        const sortedComponentStates: ComponentState[] = [...this.componentStates.values()].sort((a: ComponentState, b: ComponentState) => b.layer - a.layer);

        if (!_.isEmpty(sortedComponentStates)) {
            if (sortedComponentStates.length > 1) {
                const lastAddedComponent: ComponentState = sortedComponentStates.sort((a: ComponentState, b: ComponentState) => b.insertedAt.getTime() - a.insertedAt.getTime())[0];
                return lastAddedComponent.component;
            }

            return sortedComponentStates[0].component;
        } else {
            return null;
        }
    }

    removeComponentState(key: string): void {
        this.componentStates.delete(key);
    }

    private createAndSaveState(key: string, stateItems: Map<string, any>, options?: StateStoreOptions): void {
        let storeOptions: StateStoreOptions;

        if (_.isNil(options)) {
            storeOptions = new StateStoreOptions(5, 10, true);
        } else {
            storeOptions = options;
        }

        const stateObject: StateObject = new StateObject(key, stateItems, storeOptions.expirationInMinutes, storeOptions.absoluteExpirationInMinutes, storeOptions.isSlidingExpirationEnabled);

        this.stateObjectMap.set(key, stateObject);

        stateObject.onEviction.subscribe((key: string) => {
            this.stateObjectMap.delete(key);
        });

        switch (this.stateStorageLocation) {
            case StateStorageLocation.Internal:
                // We don't need to do anything since the Map is already being internally held, meaning on page refresh the state is lost
                break;

            case StateStorageLocation.Session:
                this.saveStateToSessionStorage();
                break;

            case StateStorageLocation.Persistent:
                throw new Error('Not implemented yet');

            default:
                throw new Error(`${this.stateStorageLocation} is not implemented`);
        }
    }

    private saveStateToSessionStorage(): void {
        // This is broken, and i need to fix this somehow.
        this.stateObjectMap.forEach((value: StateObject, key: string) => {
            const sessionStorageKey: string = `${this.sessionStorageKeyPrefix}-${key}`;

            sessionStorage.setItem(sessionStorageKey, JSON.stringify(value));
        });
    }
}
