import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResourceList } from '../models/api-resource/api-resource-list';
import { firstValueFrom } from 'rxjs';
import { CreateApiResourceDto } from '../dto/api-resource/create-api-resource-dto';
import { ApiResource } from '../models/api-resource/api-resource';

@Injectable({
    providedIn: 'root',
})
export class ApiResourceService {
    constructor(private readonly httpClient: HttpClient) {}

    async get(): Promise<ApiResourceList[]> {
        return firstValueFrom(this.httpClient.get<ApiResourceList[]>('apiresource'));
    }

    async create(createApiResourceDto: CreateApiResourceDto): Promise<ApiResource> {
        return firstValueFrom(this.httpClient.post<ApiResource>('apiresource', JSON.stringify(createApiResourceDto)));
    }

    async delete(id: number): Promise<void> {
        firstValueFrom(this.httpClient.delete<void>(`apiresource/${id}`));
    }
}
