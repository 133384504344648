<div class="ax-search__container" [ngClass]="isMainSearch ? 'ax-search__container--main-search' : ''">
    <i class="fa-light fa-magnifying-glass ax-search__icon"></i>
    <input
        #inputElement
        ax-input
        type="text"
        [ngClass]="isMainSearch ? 'main-search__input': ''"
        [placeholder]="placeholder"
        [ngModel]="value"
        (ngModelChange)="setSearchValue($event)">
    @if (hasValue()) {
        <i class="fa-solid fa-circle-xmark ax-search__clear" (click)="clear($event)"></i>
    }
</div>
