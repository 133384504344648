import { ApplicationRef, ComponentRef, createComponent, EnvironmentInjector, Inject, Injectable, Injector, StaticProvider } from '@angular/core';
import { ToastComponent } from './toast.component';
import * as _ from 'lodash';
import { TOAST_DATA, ToastData } from './interfaces/toast-data';
import { TOAST_CONFIG, ToastConfig, ToastGlobalConfig } from './interfaces/toast-config';
import { Toolkit } from '../../utilities/toolkit';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private readonly toastReferences: ComponentRef<ToastComponent>[] = [];
    private toastContainer: HTMLDivElement;

    private feedbackToast: HTMLElement;
    private elementOffsetTop: number = 0;
    private elementOffsetLeft: number = 0;
    private elementHeight: number = 0;
    private elementWidth: number = 0;

    constructor(
        @Inject(TOAST_CONFIG) private readonly globalConfig: ToastGlobalConfig,
        private readonly applicationRef: ApplicationRef,
        private readonly injector: EnvironmentInjector
    ) {}

    success(title?: string, message?: string, override: Partial<ToastConfig> = {}): ToastComponent {
        return this.open('success', title, message, override);
    }

    danger(title?: string, message?: string, override: Partial<ToastConfig> = {}): ToastComponent {
        return this.open('danger', title, message, override);
    }

    warning(title?: string, message?: string, override: Partial<ToastConfig> = {}): ToastComponent {
        return this.open('warning', title, message, override);
    }

    info(title?: string, message?: string, override: Partial<ToastConfig> = {}): ToastComponent {
        return this.open('info', title, message, override);
    }

    feedback(text: string, element: HTMLElement, position: 'top' | 'bottom' | 'left' | 'right' = 'right'): void {
        this.feedbackToast = document.createElement('small');
        this.feedbackToast.classList.add('ax-feedback-toast');
        this.feedbackToast.innerHTML = text;

        this.setPositionStyle(element, position);
        document.body.appendChild(this.feedbackToast);

        _.delay(() => {
            this.feedbackToast.classList.remove('ax-feedback-toast--opening');
        }, 200);

        _.delay(() => {
            this.feedbackToast.classList.add('ax-feedback-toast--closing');

            _.delay(() => {
                document.body.removeChild(this.feedbackToast);
                this.resetOffsets();
                this.feedbackToast = null;
            }, 200);
        }, 1000);
    }

    remove(toastId: string): void {
        const toastRef: ComponentRef<ToastComponent> = this.toastReferences.find((x: ComponentRef<ToastComponent>) => x.instance.id === toastId);

        this.removeAndDestroy(toastRef);
    }

    private removeAndDestroy(toastRef: ComponentRef<ToastComponent>): void {
        if (toastRef !== undefined && toastRef !== null) {
            const index: number = this.toastReferences.indexOf(toastRef);

            if (index > -1) {
                this.toastReferences.splice(index, 1);

                toastRef.instance.handleDestory();
                _.delay(() => {
                    toastRef.destroy();
                    if (this.toastReferences.length === 0) this.destroyContainer();
                }, 300);
            }
        }
    }

    private open(type: string, title?: string, message?: string, override: Partial<ToastConfig> = {}): ToastComponent {
        this.createContainerIfNotExists();

        if (this.globalConfig.prevent_duplicates) {
            const duplicateToast: ToastComponent = this.getDuplicate(title, message);

            if (duplicateToast !== undefined && duplicateToast !== null) return duplicateToast;
        }

        const config: ToastGlobalConfig = this.buildConfig(override);
        const toastData: ToastData = {
            id: Toolkit.generateId(10),
            title: title,
            message: message,
            type: type,
            has_close_button: config.has_close_button,
            click_to_dispose: config.click_to_dispose,
        };

        const ref: ComponentRef<ToastComponent> = createComponent(ToastComponent, {
            environmentInjector: this.injector,
            elementInjector: this.createInjector(toastData),
        });

        if (this.globalConfig.max_opened > 0 && this.toastReferences.length === this.globalConfig.max_opened) {
            const firstRef: ComponentRef<ToastComponent> = this.toastReferences[0];
            this.removeAndDestroy(firstRef);

            _.delay(() => {
                this.toastContainer.appendChild(ref.location.nativeElement);
                this.applicationRef.attachView(ref.hostView);
            }, 300);
        } else {
            this.toastContainer.appendChild(ref.location.nativeElement);
            this.applicationRef.attachView(ref.hostView);
        }

        ref.instance.onDispose.subscribe(() => {
            this.removeAndDestroy(ref);
        });

        if (!config.disable_timeout)
            _.delay(() => {
                this.removeAndDestroy(ref);
            }, config.delay);

        this.toastReferences.push(ref);

        return ref.instance;
    }

    private createContainerIfNotExists(): void {
        if (this.toastContainer === undefined || this.toastContainer === null) {
            this.toastContainer = document.createElement('div');
            this.toastContainer.classList.add('ax-toast__component__container');

            const positionClass = `ax-toast__component__container--${this.globalConfig.position}`;

            this.toastContainer.classList.add(positionClass);

            document.body.appendChild(this.toastContainer);
        }
    }

    private destroyContainer(): void {
        _.delay(() => {
            if (this.toastContainer !== undefined && this.toastContainer !== null) {
                document.body.removeChild(this.toastContainer);
                this.toastContainer = null;
            }
        }, 400);
    }

    private createInjector(data: ToastData): Injector {
        const providers: StaticProvider[] = [{ provide: TOAST_DATA, useValue: data }];

        return Injector.create({ providers: providers });
    }

    private buildConfig(override: Partial<ToastConfig> = {}): ToastGlobalConfig {
        return { ...this.globalConfig, ...override };
    }

    private getDuplicate(title?: string, message?: string): ToastComponent {
        const toast: ComponentRef<ToastComponent> = this.toastReferences.find((x: ComponentRef<ToastComponent>) => x.instance.title === title && x.instance.message === message);

        if (toast !== null && toast !== undefined) return toast.instance;

        return null;
    }

    private setAbsoluteOffsets(element: HTMLElement): void {
        if (element.offsetParent !== undefined && element.offsetParent !== null) {
            this.elementOffsetTop += element.offsetTop;
            this.elementOffsetLeft += element.offsetLeft;
            this.setAbsoluteOffsets(element.offsetParent as HTMLElement);
        }
    }

    private setPositionStyle(element: HTMLElement, position: 'top' | 'bottom' | 'left' | 'right'): void {
        this.setAbsoluteOffsets(element);
        this.elementHeight = element.offsetHeight;
        this.elementWidth = element.offsetWidth;

        if (position === 'top') {
            const position: number = window.innerHeight - this.elementOffsetTop + 8;
            this.feedbackToast.style.left = `${this.elementOffsetLeft}px`;
            this.feedbackToast.style.bottom = `${position}px`;
        }

        if (position === 'bottom') {
            const position: number = this.elementOffsetTop + this.elementHeight + 8;
            this.feedbackToast.style.left = `${this.elementOffsetLeft}px`;
            this.feedbackToast.style.top = `${position}px`;
        }

        if (position === 'left') {
            const rightPosition: number = window.innerWidth - this.elementOffsetLeft + 8;
            this.feedbackToast.style.top = `${this.elementOffsetTop}px`;
            this.feedbackToast.style.right = `${rightPosition}px`;
        }

        if (position === 'right') {
            const leftPosition: number = this.elementOffsetLeft + this.elementWidth + 8;
            this.feedbackToast.style.top = `${this.elementOffsetTop}px`;
            this.feedbackToast.style.left = `${leftPosition}px`;
        }
    }

    private resetOffsets(): void {
        this.elementOffsetTop = 0;
        this.elementOffsetLeft = 0;
        this.elementHeight = 0;
        this.elementWidth = 0;
    }
}
