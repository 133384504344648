import { APP_INITIALIZER, ApplicationConfig, inject, Provider, isDevMode } from '@angular/core';
import { RouteReuseStrategy, Router, TitleStrategy, provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { PageTitleStrategy } from '../middleware/page-title-strategy';
import { CONTEXT_MENU_CONFIG, ContextMenuGlobalConfig } from '../menus/interfaces/contex-menu-config';
import { HttpErrorResponse, HttpHeaders, HttpInterceptorFn, provideHttpClient, withInterceptors } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { TOAST_CONFIG, ToastGlobalConfig } from '../components/toast/interfaces/toast-config';
import _ from 'lodash';
import { STATE_CONFIG, StateConfig } from '../state/interfaces/state-config';
import { StateStorageLocation } from '../state/models/state-storage-location';
import { AuthorizationService } from '../services/authorization.service';
import { environment } from '../../environments/environment';
import { RouteReuser } from '../middleware/route-reuse-strategy';
import { provideServiceWorker } from '@angular/service-worker';

// Global configurations
export const contextMenuConfig: ContextMenuGlobalConfig = {
    allow_multiple_open: false,
    root_container_class: 'app__container',
    position: 'right',
    elementSpacing: 0,
    menuWidth: 200,
};

export const toastConfig: ToastGlobalConfig = {
    max_opened: 2,
    prevent_duplicates: true,
    disable_timeout: false,
    delay: 5000,
    has_close_button: true,
    click_to_dispose: true,
    position: 'bottom-right',
};

export const stateConfig: StateConfig = {
    stateStorageLocation: StateStorageLocation.Internal,
};

// Providers
export const titleStrategyProvider: Provider = {
    provide: TitleStrategy,
    useClass: PageTitleStrategy,
};

export const appInitializerProvider: Provider = {
    provide: APP_INITIALIZER,
    useFactory: setApplicationThemeAndSettings,
    multi: true,
};

export const contextMenuConfigProvider: Provider = {
    provide: CONTEXT_MENU_CONFIG,
    useValue: contextMenuConfig,
};

export const toastConfigProvider: Provider = {
    provide: TOAST_CONFIG,
    useValue: toastConfig,
};

export const stateConfigProvider: Provider = {
    provide: STATE_CONFIG,
    useValue: stateConfig,
};

export const routeReuseStrategyProvider: Provider = {
    provide: RouteReuseStrategy,
    useClass: RouteReuser,
};

// HTTP interceptor
export const httpInterceptor: HttpInterceptorFn = (req, next) => {
    const authorizationService: AuthorizationService = inject(AuthorizationService);
    const router: Router = inject(Router);
    let token: string | null = authorizationService.getAccessToken();

    if (_.isEmpty(token)) {
        token = '';
    }

    let headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
    });

    req.headers.keys().forEach((headerKey: string) => {
        headers = headers.set(headerKey, req.headers.get(headerKey) ?? '');
    });

    const basePath: string = environment.api_endpoint;

    req = req.clone({ url: `${basePath}/${req.url}`, headers });

    return next(req).pipe(
        catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
                authorizationService.handleLogout();
                router.navigateByUrl('/login');
            }
            return throwError(() => error);
        })
    );
};

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideHttpClient(withInterceptors([httpInterceptor])),
        titleStrategyProvider,
        appInitializerProvider,
        contextMenuConfigProvider,
        toastConfigProvider,
        stateConfigProvider,
        routeReuseStrategyProvider,
        provideServiceWorker('ngsw-worker.js', {
            enabled: true,
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
};

export function setApplicationThemeAndSettings(): () => Promise<void> {
    return (): Promise<void> => {
        return new Promise((resolve) => {
            let themeSetting: string | null = localStorage.getItem('theme');

            if (_.isNil(themeSetting)) {
                if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    themeSetting = 'dark';
                    document.body.classList.add('theme-dark');
                } else {
                    themeSetting = '';
                }
            } else if (themeSetting === 'dark') {
                document.body.classList.add('theme-dark');
            } else {
                themeSetting = '';
            }

            localStorage.setItem('theme', themeSetting);

            resolve();
        });
    };
}
