import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResourceMetric } from '../models/metrics/api-resource-metric';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MetricsService {
    constructor(private readonly httpClient: HttpClient) {}

    async getLatestApiResourceMetric(tenantId: number, apiResourceId: number): Promise<ApiResourceMetric> {
        return firstValueFrom(this.httpClient.get<ApiResourceMetric>(`metrics/tenant/${tenantId}/apiResource/${apiResourceId}/latest`));
    }

    async getApiResourceMetrics(tenantId: number, apiResourceId: number, type: string): Promise<ApiResourceMetric[]> {
        return firstValueFrom(this.httpClient.get<ApiResourceMetric[]>(`metrics/tenant/${tenantId}/apiResource/${apiResourceId}/${type}`));
    }
}
