import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AxerrioAccountsUser } from '../models/user/axerrio-accounts-user';

@Injectable({
    providedIn: 'root',
})
export class AxerrioAccountsService {
    constructor(private readonly httpClient: HttpClient) {}

    async get(): Promise<AxerrioAccountsUser[]> {
        return firstValueFrom(this.httpClient.get<AxerrioAccountsUser[]>('axerrioaccounts'));
    }

    async searchUsers(searchString: string): Promise<AxerrioAccountsUser[]> {
        return firstValueFrom(this.httpClient.get<AxerrioAccountsUser[]>(`axerrioaccounts/search/${searchString}`));
    }
}
