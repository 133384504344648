import { CanActivateFn, Router } from '@angular/router';
import { AuthorizationService } from '../services/authorization.service';
import { inject } from '@angular/core';

export const authorizeGaurd: CanActivateFn = () => {
    const authorizationService: AuthorizationService = inject(AuthorizationService);
    const router: Router = inject(Router);

    if (!authorizationService.isAuthenticated()) {
        router.navigateByUrl('/login');
        return false;
    }

    return true;
};
