@if (!isLoading) {
    <div class="user-detail-page__container">
        <div class="user-detail__header">
            <div class="user-detail__header__name-and-avatar">
                <ax-avatar [imageUrl]="user.avatar_url" [name]="getFullName()" [isEditable]="true" (editClicked)="openEditAvatarDialog()"></ax-avatar>
                <h3>{{headerTitle}}</h3>
            </div>
            <div class="user-detail__header__actions">
                <button [ax-button]="'primary'" [transparent]="true" (click)="backToOverview()">
                    <i class="fa-light fa-arrow-left"></i>
                    Back to overview
                </button>
                @if (isNewUser) {
                    <button [ax-button]="'primary'" (click)="create()">
                        Create
                        <i class="fa-solid fa-save"></i>
                    </button>
                }
                @else {
                    <button [ax-button]="'primary'" (click)="cloneUser()">
                        Clone user
                        <i class="fa-regular fa-clone"></i>
                    </button>
                    <button [ax-button]="'primary'" (click)="update()" [disabled]="!hasChanges()">
                        Update
                        <i class="fa-solid fa-save"></i>
                    </button>
                    <button [ax-button]="'danger'" (click)="delete()">
                        Delete
                        <i class="fa-solid fa-trash-can"></i>
                    </button>
                }
            </div>
        </div>
        <div class="user-detail__content">
            <div class="user-detail__content__information__block">
                <div class="user-detail__content__information__block__header">
                    <h5>General Information</h5>
                </div>
                <div class="input__block">
                    <span>Firstname*</span>
                    <input ax-input type="text" [ax-validate]="'firstname'" [required]="true" minlength="3" maxlength="20" placeholder="John" [(ngModel)]="user.firstname">
                    <small class="input__block__error__message" [ax-validate-error]="'firstname'"></small>
                </div>
                <div class="input__block">
                    <span>Lastname*</span>
                    <input ax-input type="text" [ax-validate]="'lastname'" [required]="true" minlength="3" maxlength="30" placeholder="Doe" [(ngModel)]="user.lastname">
                    <small class="input__block__error__message" [ax-validate-error]="'lastname'"></small>
                </div>
                <div class="input__block">
                    <span>Email address*</span>
                    <input
                        ax-input
                        type="email"
                        [ax-validate]="'email_address'"
                        [ax-validate-display-property]="'email address'"
                        [required]="true"
                        placeholder="someone@axerrio.com"
                        [(ngModel)]="user.email_address">
                    <small class="input__block__error__message" [ax-validate-error]="'email_address'"></small>
                </div>
                <div class="input__block">
                    <span>Is admin</span>
                    <ax-select [options]="adminSelectOptions" [hasClearEnabled]="false" [value]="getIsAdminValue()" (valueChange)="setIsAdminValue($event)"></ax-select>
                </div>
                @if (isNewUser) {
                    <div class="input__block">
                        <span>Password*</span>
                        <ax-password-input [ax-validate]="'password'" [ax-validation-rules]="['required']" [minlength]="8" [maxlength]="50" [(value)]="password"></ax-password-input>
                        <small class="input__block__error__message" [ax-validate-error]="'password'"></small>
                    </div>
                    <div class="input__block">
                        <span>Repeat password*</span>
                        <ax-password-input [ax-validate]="'password_repeat'" [ax-validate-display-property]="'password'" [ax-validation-rules]="['required']" [minlength]="8" [maxlength]="50" [(value)]="passwordRepeat" placeholder="Repeat password"></ax-password-input>
                        <small class="input__block__error__message" [ax-validate-error]="'password_repeat'"></small>
                    </div>
                }
                <small class="validation__information__text">Fields marked with * are required</small>
            </div>
            <div class="user-detail__content__permissions__block">
                <div class="user-detail__content__permissions__block__header">
                    <h5>Permissions</h5>
                </div>
                <div class="user-detail__content__permissions__block__content">
                    @for (permission of permissions; track permission.key) {
                        <div class="permission__block">
                            <div class="permission__block__title">
                                <span class="permission__block__title__text">{{permission.display_key}}</span>
                                <ax-toggle-button [isChecked]="hasPermissionSet(permission.key)" (isCheckedChange)="setPermission(permission.key, $event)"></ax-toggle-button>
                            </div>
                            <small class="permission__block__description" [innerHTML]="permission.description"></small>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
} @else {
    <ax-loader [loaderText]="'Loading user'" [isFullPage]="true"></ax-loader>
}