import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[ax-scroll-spy-item]',
})
export class ScrollSpyItemDirective {
    @Input('ax-scroll-spy-item') public scrollSpyItem: string = '';

    constructor(public readonly elementRef: ElementRef) {}
}
