import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { User } from '../models/user/user';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthorizationService {
    public onAuthorizationSuccess: Subject<void> = new Subject<void>();

    private readonly _onUserReloaded: Subject<void> = new Subject<void>();
    public onUserReloaded: Observable<void> = this._onUserReloaded.asObservable();

    private user: User | undefined;
    private isUserContextSet: boolean = false;

    constructor(private readonly userService: UserService) {}

    async getUser(): Promise<User> {
        if (!this.isUserContextSet) {
            this.user = await this.userService.me();
            this.isUserContextSet = true;
        }

        return this.user;
    }

    async hasPermission(permission: string): Promise<boolean> {
        await this.getUser();

        if (this.user.is_admin) {
            return true;
        }

        const foundPermission: string | undefined = this.user.permissions.find((perm: string) => perm === permission);

        if (!_.isNil(foundPermission) && !_.isEmpty(foundPermission)) {
            return true;
        }

        return false;
    }

    async isAdmin(): Promise<boolean> {
        await this.getUser();

        return this.user.is_admin;
    }

    async reloadUser(): Promise<void> {
        this.user = await this.userService.me();
        this._onUserReloaded.next();
    }

    isAuthenticated(): boolean {
        const token: string | null = this.getAccessToken();

        if (!_.isNil(token) && !_.isEmpty(token)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const decodedToken: any = jwtDecode(token);

            if (decodedToken?.exp > new Date().getTime() / 1000) {
                return true;
            }
        }

        return false;
    }

    handleLogout(): void {
        localStorage.removeItem('access_token');
        this.user = undefined;
        this.isUserContextSet = false;
    }

    getAccessToken(): string | null {
        return localStorage.getItem('access_token');
    }
}
