import { CanActivateFn, Router } from '@angular/router';
import { AuthorizationService } from '../services/authorization.service';
import { inject } from '@angular/core';

export const adminPermissionGaurd: CanActivateFn = async () => {
    const authorizationService: AuthorizationService = inject(AuthorizationService);
    const router: Router = inject(Router);

    const isAdmin: boolean = await authorizationService.isAdmin();

    if (!isAdmin) {
        router.navigateByUrl('/forbidden');
        return false;
    }

    return true;
};
