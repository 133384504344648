export enum TableColumnType {
    Default,
    Boolean,
    DateTime,
    Date,
    Time,
    TimeAgo,
    Status,
    Count,
    NameAndAvatar,
    Url,
    Action,
}
