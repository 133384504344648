import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationVersion } from '../models/application-version/application-version';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApplicationVersionService {
    constructor(private readonly httpClient: HttpClient) {}

    async get(): Promise<ApplicationVersion[]> {
        return firstValueFrom(this.httpClient.get<ApplicationVersion[]>('applicationversion'));
    }
}
