import { Component, OnInit, ViewChildren } from '@angular/core';
import { angularImports } from '../../../utilities/global-import';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { User } from '../../../models/user/user';
import { UserService } from '../../../services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ButtonDirective } from '../../../directives/button.directive';
import _ from 'lodash';
import { InputDirective } from '../../../directives/input.directive';
import { SelectComponent } from '../../../components/select/select.component';
import { SelectOption } from '../../../components/select/interfaces/select-option';
import { PasswordInputComponent } from '../../../components/password-input/password-input.component';
import { PermissionService } from '../../../services/permission.service';
import { Permission } from '../../../models/user/permission';
import { ToggleButtonComponent } from '../../../components/toggle-button/toggle-button.component';
import { ValidationHost } from '../../../validator/validation-host';
import { ValidateErrorDirective } from '../../../validator/directives/validate-error.directive';
import { ValidateDirective } from '../../../validator/directives/validate.directive';
import { Validator } from '../../../validator/validator';
import { CreateUserDto } from '../../../dto/user/create-user-dto';
import { ToastService } from '../../../components/toast/toast.service';
import { ValidationErrorMessage } from '../../../models/validation-error-message';
import { UpdateUserDto } from '../../../dto/user/update-user-dto';
import { DialogComponentRef } from '../../../components/dialog/models/dialog-component-ref';
import { ConfirmationDialogComponent } from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { DialogService } from '../../../components/dialog/dialog.service';
import { CanDeactivate } from '../../../middleware/can-deactivate.guard';
import { StateStoreOptions } from '../../../state/models/state-store-options';
import { StateService } from '../../../state/state.service';
import { StateObject } from '../../../state/models/state-object';
import { AvatarComponent } from '../../../components/avatar/avatar.component';
import { EditAvatarDialogComponent } from '../../../dialogs/edit-avatar-dialog/edit-avatar-dialog.component';
import { AuthorizationService } from '../../../services/authorization.service';
import { LoaderComponent } from '../../../components/loader/loader.component';

@Component({
    standalone: true,
    selector: 'ax-user-detail-page',
    templateUrl: './user-detail-page.component.html',
    styleUrl: './user-detail-page.component.scss',
    imports: [angularImports, ButtonDirective, InputDirective, SelectComponent, ToggleButtonComponent, ValidateDirective, ValidateErrorDirective, PasswordInputComponent, AvatarComponent, LoaderComponent],
})
export class UserDetailPageComponent implements OnInit, ValidationHost, CanDeactivate {
    protected isLoading: boolean = true;
    protected isNewUser: boolean = true;
    protected skipDeactivateCheck: boolean = false;
    protected headerTitle: string = 'Create User';
    protected password: string = '';
    protected passwordRepeat: string = '';

    protected user: User = {
        id: 0,
        avatar_url: '',
        firstname: '',
        lastname: '',
        email_address: '',
        is_admin: false,
        permissions: [],
        notification_settings: [],
        created_at: new Date(),
        modified_at: new Date(),
    };

    protected adminSelectOptions: SelectOption[] = [
        {
            display_value: 'Yes',
            value: 'true',
        },
        {
            display_value: 'No',
            value: 'false',
        },
    ];

    protected permissions: Permission[] = [];

    private userClone: User;
    private passwordClone: string;
    private passwordRepearClone: string;

    private validator: Validator;
    private stateStoreOptions: StateStoreOptions = new StateStoreOptions(1, 1, false);

    @ViewChildren(ValidateDirective) private readonly validateDirectives: ValidateDirective[];
    @ViewChildren(ValidateErrorDirective) private readonly validateErrorDirectives: ValidateErrorDirective[];

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly userService: UserService,
        private readonly permissionService: PermissionService,
        private readonly toastService: ToastService,
        private readonly dialogService: DialogService,
        private readonly stateService: StateService,
        private readonly authorizationService: AuthorizationService
    ) {}

    async ngOnInit(): Promise<void> {
        this.isLoading = true;

        try {
            let userId: number = 0;

            this.activatedRoute.params.subscribe((params: Params) => {
                const parsedUserId: number = Number(params['id']);

                if (_.isNaN(parsedUserId)) {
                    userId = 0;
                } else {
                    userId = parsedUserId;
                }
            });

            if (userId === 0 && this.router.url !== '/user') {
                this.router.navigateByUrl('/user');
                return;
            }

            if (userId !== 0) {
                this.user = await this.userService.get(userId);
                this.isNewUser = false;
                this.headerTitle = `${this.user.firstname} ${this.user.lastname}`;
            }

            this.userClone = _.cloneDeep(this.user);

            if (this.isNewUser) {
                const state: StateObject | null = this.stateService.getObjectState('user-clone');

                if (!_.isNil(state)) {
                    this.user = state.getValue('user_object');
                    this.user.email_address = '';
                    this.user.avatar_url = '';
                    this.user.notification_settings = [];
                    this.stateService.invalidateObjectState('user-clone');
                }

                this.passwordClone = '';
                this.passwordRepearClone = '';
            }

            this.permissions = await this.permissionService.get();
        } catch (err) {
            const httpError: HttpErrorResponse = err as HttpErrorResponse;

            if (httpError.status === 404) {
                this.router.navigateByUrl('/404');
            }
        } finally {
            this.validator = new Validator(this);

            _.delay(() => {
                this.isLoading = false;
            }, 250);
        }
    }

    canDeactivate(): Promise<boolean> {
        if (this.hasChanges() && !this.skipDeactivateCheck) {
            const dialogComponentRef: DialogComponentRef<ConfirmationDialogComponent> = this.dialogService.createDialog(ConfirmationDialogComponent, {
                title: 'Unsaved changes',
                text: `You have unsaved changes, do you want to discard these changes?`,
                confirmation_button_text: 'Discard',
                cancel_button_text: 'Cancel',
                button_type: 'danger',
            });

            return new Promise<boolean>((resolve) => {
                dialogComponentRef.afterClose.subscribe((hasConfirmation: boolean) => {
                    resolve(hasConfirmation);
                });
            });
        } else {
            return Promise.resolve(true);
        }
    }

    getValidationItems(): ValidateDirective[] {
        return this.validateDirectives;
    }

    getValidationErrorItems(): ValidateErrorDirective[] {
        return this.validateErrorDirectives;
    }

    protected async create(): Promise<void> {
        this.validator.resetValidationStates();

        let isValid: boolean = this.validator.validate();

        // We need to manually check passowrd
        if (!_.isEqual(this.password, this.passwordRepeat)) {
            isValid = false;
            this.validator.setInvalidState('password', 'Passwords do not match');
            this.validator.setInvalidState('password_repeat', 'Passwords do not match');
        }

        if (isValid) {
            try {
                const createUserDto: CreateUserDto = {
                    email_address: this.user.email_address,
                    firstname: this.user.firstname,
                    lastname: this.user.lastname,
                    is_admin: this.user.is_admin,
                    password: this.password,
                    permissions: this.user.permissions,
                };

                this.user = await this.userService.create(createUserDto);

                if (!_.isNil(this.user)) {
                    this.skipDeactivateCheck = true;
                    this.router.navigateByUrl(`/user/${this.user.id}`);
                    this.toastService.success('Success', `User ${this.user.firstname} ${this.user.lastname} created`);
                } else {
                    this.toastService.danger('Error', 'Unable to fetch user');
                }
            } catch (err) {
                const httpError: HttpErrorResponse = err as HttpErrorResponse;
                const errorMessages: ValidationErrorMessage[] = httpError.error as ValidationErrorMessage[];

                if (!_.isNil(errorMessages) && !_.isEmpty(errorMessages)) {
                    errorMessages.forEach((errorMessage: ValidationErrorMessage) => {
                        // Since the validator does not yet have the capability to have matching keys we invalidate the password repeat property manually for now.
                        if (errorMessage.property_name === 'password') {
                            this.validator.setInvalidState('password_repeat', errorMessage.error_message);
                        }
                        this.validator.setInvalidState(errorMessage.property_name, errorMessage.error_message);
                    });
                }

                this.toastService.danger('Validation error', 'Some fields did not pass validation checks');
            }
        } else {
            this.toastService.danger('Validation error', 'Some fields did not pass validation checks');
        }
    }

    protected async update(): Promise<void> {
        this.validator.resetValidationStates();

        const isValid: boolean = this.validator.validate();

        if (isValid) {
            try {
                const updateUserDto: UpdateUserDto = {
                    email_address: this.user.email_address,
                    firstname: this.user.firstname,
                    lastname: this.user.lastname,
                    is_admin: this.user.is_admin,
                    permissions: this.user.permissions,
                };

                this.user = await this.userService.update(this.user.id, updateUserDto);

                if (!_.isNil(this.user)) {
                    this.toastService.success('Success', `User ${this.user.firstname} ${this.user.lastname} updated`);
                    this.userClone = _.cloneDeep(this.user);
                    this.headerTitle = `${this.user.firstname} ${this.user.lastname}`;

                    const currentUser: User = await this.authorizationService.getUser();

                    if (currentUser.id === this.user.id) {
                        await this.authorizationService.reloadUser();
                    }
                } else {
                    this.toastService.danger('Error', 'Error while updating user');
                }
            } catch (err) {
                const httpError: HttpErrorResponse = err as HttpErrorResponse;
                const errorMessages: ValidationErrorMessage[] = httpError.error as ValidationErrorMessage[];

                if (!_.isNil(errorMessages) && !_.isEmpty(errorMessages)) {
                    errorMessages.forEach((errorMessage: ValidationErrorMessage) => {
                        // Since the validator does not yet have the capability to have matching keys we invalidate the password repeat property manually for now.
                        if (errorMessage.property_name === 'password') {
                            this.validator.setInvalidState('password_repeat', errorMessage.error_message);
                        }
                        this.validator.setInvalidState(errorMessage.property_name, errorMessage.error_message);
                    });
                }

                this.toastService.danger('Validation error', 'Some fields did not pass validation checks');
            }
        } else {
            this.toastService.danger('Validation error', 'Some fields did not pass validation checks');
        }
    }

    protected async delete(): Promise<void> {
        const dialogComponentRef: DialogComponentRef<ConfirmationDialogComponent> = this.dialogService.createDialog(ConfirmationDialogComponent, {
            title: 'Delete user',
            text: `Are you sure you want to delete <b>${this.user.firstname} ${this.user.lastname}</b>?`,
            confirmation_button_text: 'Delete',
            cancel_button_text: 'Cancel',
            button_type: 'danger',
        });

        dialogComponentRef.afterClose.subscribe(async (hasConfirmation: boolean) => {
            if (hasConfirmation) {
                await this.userService.delete(this.user.id);
                this.skipDeactivateCheck = true;
                this.router.navigateByUrl('/users');
                this.toastService.success('Success', 'User deleted successfully');
            }
        });
    }

    protected hasChanges(): boolean {
        if (!this.isNewUser) {
            return !_.isEqual(this.user, this.userClone);
        } else {
            return !_.isEqual(this.user, this.userClone) || !_.isEqual(this.password, this.passwordClone) || !_.isEqual(this.passwordRepeat, this.passwordRepearClone);
        }
    }

    protected getIsAdminValue(): string {
        return _.toString(this.user.is_admin);
    }

    protected setIsAdminValue(value: string): void {
        this.user.is_admin = JSON.parse(value);
    }

    protected hasPermissionSet(permissionKey: string): boolean {
        return this.user.permissions.includes(permissionKey);
    }

    protected setPermission(permissionKey: string, isPermissionSet: boolean): void {
        if (isPermissionSet) {
            this.user.permissions.push(permissionKey);
        } else {
            const permissionIndex: number = this.user.permissions.indexOf(permissionKey);

            if (permissionIndex > -1) {
                this.user.permissions.splice(permissionIndex, 1);
            }
        }
    }

    protected backToOverview(): void {
        this.router.navigateByUrl('/users');
    }

    protected cloneUser(): void {
        const stateItem: Map<string, User> = new Map<string, User>([['user_object', this.userClone]]);
        this.stateService.saveObjectState('user-clone', stateItem, this.stateStoreOptions);
        this.router.navigateByUrl('/user');
    }

    protected getFullName(): string {
        return `${this.user.firstname} ${this.user.lastname}`;
    }

    protected openEditAvatarDialog(): void {
        const dialogComponentRef: DialogComponentRef<EditAvatarDialogComponent> = this.dialogService.createDialog(EditAvatarDialogComponent, {
            image_url: this.user.avatar_url,
            full_name: this.getFullName(),
            user_id: this.user.id,
            is_admin_upload: true,
        });

        dialogComponentRef.afterClose.subscribe(async () => {
            this.user = await this.userService.get(this.user.id);
            this.userClone = _.cloneDeep(this.user);

            const currentUser: User = await this.authorizationService.getUser();

            if (currentUser.id === this.user.id) {
                await this.authorizationService.reloadUser();
            }
        });
    }
}
