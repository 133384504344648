<div #containerElement class="ax-password-input__container" [ngClass]="setContainerClasses()" [tabIndex]="tabIndex" (focus)="setInputFocus()" (blur)="onFocusLost($event)">
    <input 
        #inputElement
        ax-input
        class="ax-password-input"
        type="{{showPassword ? 'text' : 'password'}}"
        [ngModel]="value"
        minlength="{{minlength}}" 
        maxlength="{{maxlength}}" 
        [placeholder]="placeholder"
        [ngModel]="value"
        (ngModelChange)="onPasswordInputChange($event)"
        (blur)="onFocusLost($event)"
        [tabIndex]="-1">
    <button [ax-button]="'primary'" [icon-only-small]="true" [transparent]="true" tabindex="-1" (click)="toggleVisibility()">
        <i class="fa-regular" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
    </button>
</div>