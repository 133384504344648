import { Component } from '@angular/core';

@Component({
    standalone: true,
    selector: 'ax-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrl: './page-not-found.component.scss',
    imports: [],
})
export class PageNotFoundComponent {}
