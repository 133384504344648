<div #containerElement class="ax-select__container" [ngClass]="setContainerClasses()" (click)="containerClick($event)" [tabIndex]="tabIndex">
    @if (isTypeaeadEnabled) {
        <input
            #searchInput
            type="text"
            ax-input
            class="ax-select__input"
            [placeholder]="placeholder"
            [ngModel]="searchValue"
            (ngModelChange)="onSearchInputChange($event)">
    } @else {
        @if (!hasValueSelected) {
            <span class="ax-select__placeholder">{{placeholder}}</span>
        } @else {
            <span class="ax-select__selected-value">{{displayValue}}</span>
        }
    }
    <div class="ax-select__actions">
        @if (hasClearEnabled && hasValueSelected) {
            <i class="fa-light fa-circle-xmark ax-select__actions__action" (click)="clear($event)"></i>
        }
        <i class="fa-solid fa-caret-down ax-select__actions__action" [ngClass]="!isCollapsed ? 'ax-select__actions__action--reversed' : ''" (click)="carretToggleClick($event)"></i>
    </div>
</div>


