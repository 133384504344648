import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { angularImports } from '../../utilities/global-import';
import { StatusMetric, StatusMetricState } from './interfaces/status-metric';
import { TooltipDirective } from '../../directives/tooltip.directive';
import dayjs from 'dayjs';
import _ from 'lodash';

@Component({
    standalone: true,
    selector: 'ax-status-metric',
    templateUrl: './status-metric.component.html',
    styleUrl: './status-metric.component.scss',
    imports: [angularImports, TooltipDirective],
})
export class StatusMetricComponent implements OnInit, OnChanges {
    @Input() public rangeType: string = 'day';
    @Input() public title: string;
    @Input() public dateFormat: string = 'DD-MM-YYYY';
    @Input() public metrics: StatusMetric[] = [];

    protected metricBlocks: StatusMetric[] = [];
    protected startLabel: string = '';
    protected endLabel: string = '';
    protected uptimeLabel: string = '';

    ngOnInit(): void {
        this.buildMetricComponent();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const change: SimpleChange | undefined | null = changes['rangeType'];

        if (!_.isNil(change)) {
            if (!change.isFirstChange()) {
                this.buildMetricComponent();
            }
        }
    }

    protected setBlockClasses(metricBlock: StatusMetric): string {
        let classList: string = '';

        if (this.rangeType === 'hour') {
            classList = `${classList} ax-status-metric__block--hour`;
        }

        switch (metricBlock.status) {
            case StatusMetricState.Down:
                classList = `${classList} ax-status-metric__block--down`;
                break;

            case StatusMetricState.Maintenance:
                classList = `${classList} ax-status-metric__block--maintenance`;
                break;

            case StatusMetricState.Running:
                break;

            case StatusMetricState.Unavailable:
                classList = `${classList} ax-status-metric__block--unavailable`;
                break;

            default:
                classList = `${classList} ax-status-metric__block--unavailable`;
                break;
        }

        return classList;
    }

    protected createTooltipText(metricBlock: StatusMetric): string {
        let formattedDate: string = '';

        if (this.rangeType === 'day') {
            formattedDate = metricBlock.time.format(this.dateFormat);
        } else if (this.rangeType === 'hour') {
            formattedDate = metricBlock.time.format('HH:mm');
        }

        let innterHtmlString: string = `<span><b>${formattedDate}</b> - ${StatusMetricState[metricBlock.status]}</br></br>`;

        innterHtmlString = `${innterHtmlString} ${metricBlock.status_text}</span>`;

        return innterHtmlString;
    }

    protected calculateUptimePercentage(): string {
        // (total time - downtime) / totaltime * 100
        const totalMetrics: number = this.metrics.length;
        const downtimeMetrics: number = this.metrics.filter((metric: StatusMetric) => metric.status === StatusMetricState.Down || metric.status === StatusMetricState.Maintenance).length;
        const result: number = ((totalMetrics - downtimeMetrics) / totalMetrics) * 100;

        if (_.isNaN(result)) {
            return '0.0';
        }

        return result.toFixed(2);
    }

    private buildMetricComponent(): void {
        let loopAmount = 0;

        if (this.rangeType === 'day') {
            loopAmount = 90;
            this.startLabel = '90 days ago';
            this.endLabel = 'Today';
        } else if (this.rangeType === 'hour') {
            loopAmount = 24;
            this.startLabel = '00:00';
            this.endLabel = '23:00';
        }

        this.metricBlocks = [];

        for (let i: number = 0; i < loopAmount; i++) {
            let dayJsObj: dayjs.Dayjs = dayjs();

            if (this.rangeType === 'day') {
                dayJsObj = dayJsObj.add(-i, 'd');
            } else if (this.rangeType === 'hour') {
                dayJsObj = dayjs(new Date().setHours(23, 0, 0, 0));
                dayJsObj = dayJsObj.add(-i, 'h');
            }

            this.metricBlocks.push({
                time: dayJsObj,
                status: StatusMetricState.Unavailable,
                status_text: `No data available for this ${this.rangeType}`,
            });
        }

        if (!_.isNil(this.metrics) && !_.isEmpty(this.metrics)) {
            this.metrics.forEach((metric: StatusMetric) => {
                let metricBlock: StatusMetric | undefined = undefined;

                if (this.rangeType === 'day') {
                    metricBlock = this.metricBlocks.find((x: StatusMetric) => x.time.month() === metric.time.month() && x.time.date() === metric.time.date());
                } else if (this.rangeType === 'hour') {
                    metricBlock = this.metricBlocks.find((x: StatusMetric) => x.time.hour() === metric.time.hour());
                }

                if (!_.isNil(metricBlock)) {
                    metricBlock.status = metric.status;

                    switch (metric.status) {
                        case StatusMetricState.Down:
                            metricBlock.status_text = metric.status_text;
                            break;

                        case StatusMetricState.Maintenance:
                            metricBlock.status_text = 'Maintenance';
                            break;

                        case StatusMetricState.Running:
                            metricBlock.status_text = 'Operational';
                            break;

                        default:
                            break;
                    }
                }
            });
        }
    }
}
