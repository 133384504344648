<div class="pagination__container">
    <div class="pagination__page-selector__container">
        <div class="page-selector__button" (click)="previous()">
            <span class="page-selector__button__text">Previous</span>
        </div>
        @for (page of pages; track page) {
            <div class="page-selector__button page-selector__button--numeric" [ngClass]="selectedPage === page ? 'page-selector__button--selected' : ''" (click)="selectPage(page)">
                <span class="page-selector__button__text">{{page}}</span>
            </div>
        }
        <div class="page-selector__button" (click)="next()">
            <span class="page-selector__button__text">Next</span>
        </div>
    </div>
    <div class="pagination__page-size-selector__container">
        <ax-select [options]="pageSizeOptions" [hasClearEnabled]="false" [value]="getSelectedRecordsPerPageOption()" (valueChange)="setSelectedRecordsPerPageOption($event)"></ax-select>
        <span>Records per page</span>
    </div>
</div>