<div class="login-page__container">
    <div class="login-page__brand__container">
        <img src="/assets/images/logo.svg" alt="logo" class="logo">
        <h4>Axerrio Business Solutions</h4>
        <small>Internal management tool</small>
    </div>
    <div class="login-page__divider"></div>
    <div class="login-page__login__form__container">
        @if (!isPasswordResetLoading) {
            <h4>{{title}}</h4>
        }
        <div class="login-form">
            @switch (view) {
                @case ('login') {
                    <div class="login-form__input__container">
                        <input
                            ax-input
                            [ax-validate]="'email'"
                            required="true"
                            type="email"
                            placeholder="someone@axerrio.com"
                            [(ngModel)]="authorizationDto.email_address">
                        <small [ax-validate-error]="'email'" class="validation__error__message"></small>
                    </div>
                    <div class="login-form__input__container">
                        <ax-password-input [ax-validate]="'password'" [ax-validation-rules]="['required']" [(value)]="authorizationDto.password"></ax-password-input>
                        <small [ax-validate-error]="'password'" class="validation__error__message"></small>
                    </div>
                    <div class="login-form__remember">
                        <ax-checkbox [(isChecked)]="rememberEmail"></ax-checkbox>
                        <span>Remember me</span>
                    </div>
                    <button [ax-button]="'primary'" (click)="authorize($event)" [disabled]="isLoading">
                        Login
                        @if (isLoading) {
                            <i class="fa-duotone fa-solid fa-spinner fa-spin ax-button__icon"></i>
                        }
                    </button>
                    <small class="login-form__text-link" (click)="switchView('forgot-password')">Forgot password?</small>
                }

                @case ('forgot-password') {
                    <div class="login-form__input__container">
                        <input
                            ax-input
                            [ax-validate]="'email'"
                            required="true"
                            type="email"
                            placeholder="someone@axerrio.com"
                            [(ngModel)]="forgotPasswordDto.email_address">
                        <small [ax-validate-error]="'email'" class="validation__error__message"></small>
                    </div>
                    @if (isPasswordLinkSent) {
                        <div class="login-form__message-box">
                            <div class="login-form__message-box__message">
                                <i class="fa-regular fa-badge-check login-form__invalid-token__message__icon"></i>
                                <small class="login-form__invalid-token__message__text">Check your email for the reset link.</small>
                            </div>
                        </div>
                    }
                    <button [ax-button]="'primary'" (click)="sendPasswordResetLink($event)" [disabled]="isLoading">
                        Send reset link
                        @if (isLoading) {
                            <i class="fa-duotone fa-solid fa-spinner fa-spin ax-button__icon"></i>
                        }
                    </button>
                    <small class="login-form__text-link" (click)="switchView('login')">Login instead?</small>
                }

                @case ('reset-password') {
                    @if (!isPasswordResetLoading) {
                        @if (!isPasswordResetTokenValid) {
                            <div class="login-form__message-box">
                                <div class="login-form__message-box__message login-form__message-box__message--error">
                                    <i class="fa-regular fa-message-exclamation login-form__invalid-token__message__icon"></i>
                                    <small class="login-form__invalid-token__message__text">The provided password reset token is not valid, you can request a new token by clicking the button below.</small>
                                </div>
                                <button [ax-button]="'primary'" (click)="rediretToForgotPassword()">Request a new token</button>
                                <small class="login-form__text-link" (click)="redirectToLogin()">Login instead?</small>
                            </div>
                        } @else {
                            <div class="login-form__input__container">
                                <ax-password-input [tabIndex]="1" [placeholder]="'Password'" [ax-validate]="'password'" [ax-validation-rules]="['required']" [(value)]="resetPasswordDto.password"></ax-password-input>
                                <small [ax-validate-error]="'password'" class="validation__error__message"></small>
                            </div>
                            <div class="login-form__input__container">
                                <ax-password-input [tabIndex]="2" [placeholder]="'Repeat password'" [ax-validate]="'password_repeat'" [ax-validation-rules]="['required']" [(value)]="passwordRepeat"></ax-password-input>
                                <small [ax-validate-error]="'password_repeat'" class="validation__error__message"></small>
                            </div>
                            <button [ax-button]="'primary'" (click)="resetPassword($event)" [disabled]="isLoading">
                                Reset password
                                @if (isLoading) {
                                    <i class="fa-duotone fa-solid fa-spinner fa-spin ax-button__icon"></i>
                                }
                            </button>
                            <small class="login-form__text-link" (click)="redirectToLogin()">Login instead?</small>
                        }
                    } @else {
                        <ax-loader [loaderText]="'Validating reset token'"></ax-loader>
                    }
                }
            }
        </div>
        <button [ax-button]="'primary'" class="theme-switcher" [transparent]="true" (click)="switchTheme($event)">
            @switch (theme) {
                @case('dark') {
                    <i class="fa-regular fa-sun-bright ax-button__icon"></i>
                }
    
                @case ('') {
                    <i class="fa-regular fa-moon-stars ax-button__icon"></i>
                }
    
                @default {
                    <i class="fa-regular fa-moon-stars ax-button__icon"></i>
                }
            }
            @if (theme === 'dark') {
                <span class="ax-button__label">Switch to light theme</span>
            } @else if (theme === '') {
                <span class="ax-button__label">Switch to dark theme</span>
            } @else {
                <span class="ax-button__label">Switch to dark theme</span>
            }
        </button>
    </div>
</div>