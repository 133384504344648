import { HomePageComponent } from '../views/home/home-page.component';
import { PageNotFoundComponent } from '../views/error/page-not-found/page-not-found.component';
import { TenantOverviewPageComponent } from '../views/tenant/overview/tenant-overview-page.component';
import { TenantDetailPageComponent } from '../views/tenant/details/tenant-details-page.component';
import { canDeactivateGaurd } from '../middleware/can-deactivate.guard';
import { Routes } from '@angular/router';
import { authorizeGaurd } from '../middleware/authorize.gaurd';
import { LoginPageComponent } from '../views/login/login-page.component';
import { permissionGuard } from '../middleware/permission.gaurd';
import { UserOverviewPageComponent } from '../views/management/user-overview/user-overview-page.component';
import { UserDetailPageComponent } from '../views/management/user-detail/user-detail-page.component';
import { adminPermissionGaurd } from '../middleware/admin-permission.gaurd';
import { MyAccountPageComponent } from '../views/management/my-account/my-account-page.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'login',
        title: 'Login',
        component: LoginPageComponent,
        pathMatch: 'full',
    },
    {
        path: 'passwordreset/:token',
        title: 'Forgot Password',
        component: LoginPageComponent,
        pathMatch: 'full',
    },
    {
        path: 'home',
        title: 'Home',
        component: HomePageComponent,
        canActivate: [authorizeGaurd],
        pathMatch: 'full',
    },
    {
        path: 'tenants',
        title: 'Tenants',
        component: TenantOverviewPageComponent,
        canActivate: [authorizeGaurd, permissionGuard],
        pathMatch: 'full',
        data: {
            permission: 'Tenants.Read',
        },
    },
    {
        path: 'tenant/:id',
        title: 'Tenant',
        component: TenantDetailPageComponent,
        canActivate: [authorizeGaurd, permissionGuard],
        canDeactivate: [canDeactivateGaurd],
        pathMatch: 'full',
        data: {
            permission: 'Tenants.Read',
        },
    },
    {
        path: 'users',
        title: 'Users',
        component: UserOverviewPageComponent,
        canActivate: [authorizeGaurd, adminPermissionGaurd],
        pathMatch: 'full',
    },
    {
        path: 'user',
        title: 'User',
        component: UserDetailPageComponent,
        canActivate: [authorizeGaurd, adminPermissionGaurd],
        canDeactivate: [canDeactivateGaurd],
        pathMatch: 'full',
    },
    {
        path: 'user/:id',
        title: 'User',
        component: UserDetailPageComponent,
        canActivate: [authorizeGaurd, adminPermissionGaurd],
        canDeactivate: [canDeactivateGaurd],
        pathMatch: 'full',
    },
    {
        path: 'my-account',
        title: 'My Account',
        component: MyAccountPageComponent,
        canActivate: [authorizeGaurd],
        canDeactivate: [canDeactivateGaurd],
        pathMatch: 'full',
    },
    {
        path: '404',
        title: 'Page not found',
        component: PageNotFoundComponent,
    },
    {
        path: '**',
        redirectTo: '404',
    },
];
