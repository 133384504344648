/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { DIALOG_DATA, DialogComponentRef } from '../../components/dialog/models/dialog-component-ref';
import { ButtonDirective } from '../../directives/button.directive';
import { AvatarComponent } from '../../components/avatar/avatar.component';
import _ from 'lodash';
import { angularImports } from '../../utilities/global-import';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user/user';

@Component({
    standalone: true,
    selector: 'ax-edit-avatar-dialog',
    templateUrl: './edit-avatar-dialog.component.html',
    styleUrl: './edit-avatar-dialog.component.scss',
    imports: [angularImports, ButtonDirective, AvatarComponent],
})
export class EditAvatarDialogComponent {
    protected imageUrl: string | null = null;
    protected name: string = '';
    protected file: any;

    private userId: number = 0;
    private isAdminUplaod: boolean = false;

    @ViewChild('fileUploadInput') private readonly fileUploadInput: ElementRef<HTMLInputElement>;

    constructor(
        @Inject(DIALOG_DATA) private readonly data: any,
        private readonly dialogComponentRef: DialogComponentRef<EditAvatarDialogComponent>,
        private readonly userService: UserService
    ) {
        this.imageUrl = data.image_url;
        this.name = data.full_name;
        this.isAdminUplaod = data.is_admin_upload;

        if (!_.isNil(data.user_id)) {
            this.userId = data.user_id;
        }
    }

    closeDialog(): void {
        this.dialogComponentRef.closeDialog();
    }

    hasImageSet(): boolean {
        return !_.isNil(this.imageUrl) && !_.isEmpty(this.imageUrl);
    }

    openFileSelect(): void {
        this.fileUploadInput.nativeElement.click();
    }

    async afterFileSelect(event: any): Promise<void> {
        const file: File = event.target.files[0];

        if (!_.isNil(file)) {
            let user: User;

            if (this.isAdminUplaod) {
                user = await this.userService.setAvatarForUser(this.userId, file);
            } else {
                user = await this.userService.setAvatar(file);
            }

            this.imageUrl = user.avatar_url;
        }
    }

    async removeAvatar(): Promise<void> {
        let user: User;

        if (this.isAdminUplaod) {
            user = await this.userService.deleteAvatarForUser(this.userId);
        } else {
            user = await this.userService.deleteAvatar();
        }

        this.imageUrl = user.avatar_url;
    }
}
