import _ from 'lodash';
import { ValidationHost } from './validation-host';
import { ValidateDirective } from './directives/validate.directive';
import { ValidateErrorDirective } from './directives/validate-error.directive';
import { ValidationRule } from './rules/validation-rule';

/**
 * Validator class used to validate elements that are decoracted with ValidateDirective
 */
export class Validator {
    constructor(private readonly validationHost: ValidationHost) {
        if (_.isNil(validationHost)) {
            throw new Error('Validation host is null or undefined');
        }
    }

    /**
     * Validates all instances of ValidateDirective that was passed by the validation host.
     * Each validate directive will look for matching validate error directives by key and set their state depending on the outcome of the validation rules for that validate directive.
     * @param {boolean} stopOnFirstFailure Stop validation after first failure, default is false.
     * @param {string} validationGroup Key to look for validation directives to validate that has their validationGroup key set to this value, default is undefined.
     * @returns {boolean} Value indicating validation passed or failed.
     */
    validate(stopOnFirstFailure: boolean = false, validationGroup: string = undefined): boolean {
        let hasAllRulesPassed: boolean = false;

        let validationDirectives: ValidateDirective[] = this.validationHost.getValidationItems();
        const validationErrorDirectives: ValidateErrorDirective[] = this.validationHost.getValidationErrorItems();

        if (!_.isNil(validationGroup) && !_.isEmpty(validationGroup)) {
            const filteredDirectives: ValidateDirective[] = validationDirectives.filter((directive: ValidateDirective) => {
                return directive.validationGroup === validationGroup;
            });

            validationDirectives = filteredDirectives;
        }

        if (!_.isNil(validationDirectives)) {
            const duplicateValidateDiretives: ValidateDirective[] = this.findDuplicates(validationDirectives);

            if (!_.isEmpty(duplicateValidateDiretives)) {
                throw new Error('Validation keys must be unique, found duplicates');
            }

            // Setting is valid is true here so the default validation result is false.
            // When we have an array we can iterate over we set the default to true.
            hasAllRulesPassed = true;

            for (const directive of validationDirectives) {
                if (directive.isElementDisabled()) {
                    continue;
                }

                if (!_.isEmpty(directive.validationRules)) {
                    let hasRulesPassed: boolean = true;

                    directive.updateInputElementBindingRules();

                    for (const validationRule of directive.validationRules) {
                        const ruleValidationResult: RuleValidationResult = this.validateRule(validationRule, directive);

                        if (!ruleValidationResult.isValid) {
                            const matchingErrorDirectives: ValidateErrorDirective[] = validationErrorDirectives.filter((x) => x.validationKey === directive.validationKey);

                            if (!_.isEmpty(matchingErrorDirectives))
                                matchingErrorDirectives.forEach((errorDirective: ValidateErrorDirective) => {
                                    errorDirective.handleValidation(false, this.buildErrorMessage(directive.displayPropertyName, ruleValidationResult.errorMessage));
                                });

                            directive.setValidState(false);
                            hasRulesPassed = false;
                            break;
                        }
                    }

                    if (hasRulesPassed) {
                        // (Re)set the matching error directive to be valid and set validate directive to a valid state
                        const matchingErrorDirectives: ValidateErrorDirective[] = validationErrorDirectives.filter((x) => x.validationKey === directive.validationKey);

                        if (!_.isEmpty(matchingErrorDirectives))
                            matchingErrorDirectives.forEach((errorDirective: ValidateErrorDirective) => {
                                errorDirective.handleValidation(true, '');
                            });

                        directive.setValidState(true);
                    } else {
                        hasAllRulesPassed = false;
                    }
                } else {
                    console.warn(`Element ${directive.validationKey} is marked for validation but has no rules to execute, validation will pass`);
                }

                if (stopOnFirstFailure && !hasAllRulesPassed) {
                    break;
                }
            }
        }

        return hasAllRulesPassed;
    }

    /**
     * Resets all validate directives to a valid state.
     * Each validate directive will look for matching validate error directive by key and set their respective states to a valid state.
     */
    resetValidationStates(): void {
        const validationDirectives: ValidateDirective[] = this.validationHost.getValidationItems();
        const validationErrorDirectives: ValidateErrorDirective[] = this.validationHost.getValidationErrorItems();

        if (!_.isNil(validationDirectives))
            validationDirectives.forEach((directive: ValidateDirective) => {
                const matchingErrorDirectives: ValidateErrorDirective[] = validationErrorDirectives.filter((x) => x.validationKey === directive.validationKey);

                if (!_.isEmpty(matchingErrorDirectives))
                    matchingErrorDirectives.forEach((errorDirective: ValidateErrorDirective) => {
                        errorDirective.handleValidation(true, '');
                    });

                directive.setValidState(true);
            });
    }

    setInvalidState(key: string, errorMessage: string): void {
        if (_.isNil(key) || _.isEmpty(key)) {
            throw new Error('Key is required');
        }

        const matchingValidationDirective: ValidateDirective | undefined = this.validationHost.getValidationItems().find((directive: ValidateDirective) => directive.validationKey === key);

        if (_.isNil(matchingValidationDirective)) {
            throw new Error(`Unable to find validation directive by key: ${key}`);
        }

        const matchingValidationErrorDirective: ValidateErrorDirective | undefined = this.validationHost.getValidationErrorItems().find((errorDirective: ValidateErrorDirective) => errorDirective.validationKey === key);

        if (!_.isNil(matchingValidationErrorDirective)) {
            matchingValidationErrorDirective.handleValidation(false, errorMessage);
        }

        matchingValidationDirective.setValidState(false);
    }

    /**
     * Validate a validate directive with a validation rule
     * @param {ValidationRule} validationRule Validation rule to execute
     * @param {ValidateDirective} directive Directive to get value from to validate
     * @returns {RuleValidationResult} Result object containing if the result is valid and potentially an error message
     */
    private validateRule(validationRule: ValidationRule, directive: ValidateDirective): RuleValidationResult {
        let isValid: boolean = true;
        let errorMessage: string = null;

        isValid = validationRule.validateRule(directive.getValue());

        if (!isValid) {
            errorMessage = validationRule.errorMessage;
        }

        return new RuleValidationResult(isValid, errorMessage);
    }

    /**
     * Builds a human readable error message.
     * @param {string} validationDisplayPropertyName Display name of the property
     * @param {string} errorMessage Part of the error message
     * @returns {string} A constructed human-readable error message
     */
    private buildErrorMessage(validationDisplayPropertyName: string, errorMessage: string): string {
        return `${this.convertKeyToReadableString(validationDisplayPropertyName)} ${errorMessage}`;
    }

    /**
     * Converts key property (mostly snake-case keys) into humanized string.
     * @param {string} key The key to convert
     * @returns {string} A converted key value
     */
    private convertKeyToReadableString(key: string): string {
        const cleanKey: string = key.split('_').join(' ');

        return `${cleanKey.charAt(0).toUpperCase()}${cleanKey.substring(1)}`;
    }

    /**
     * Finds and returns duplicate validation directives, matching by key.
     * @param {ValidateDirective[]} validationDirectives The array of ValidateDirectives to check for duplicates
     * @returns {ValidateDirective[]} Validation directives that are duplicate
     */
    private findDuplicates(validationDirectives: ValidateDirective[]): ValidateDirective[] {
        const result: ValidateDirective[] = [];
        const duplicates: ValidateDirective[] = [];

        validationDirectives.forEach((directive: ValidateDirective) => {
            const exisitingDirective: ValidateDirective = result.find((x) => x.validationKey === directive.validationKey);

            if (!_.isNil(exisitingDirective)) {
                const duplicateDirective: ValidateDirective = duplicates.find((x) => x.validationKey === directive.validationKey);

                if (_.isNil(duplicateDirective)) {
                    duplicates.push(directive);
                }
            } else {
                result.push(directive);
            }
        });

        return duplicates;
    }
}

/**
 * Rule validation result class.
 */
export class RuleValidationResult {
    public readonly isValid: boolean;
    public readonly errorMessage: string | undefined | null;

    constructor(isValid: boolean, errorMessage: string | undefined | null) {
        this.isValid = isValid;
        this.errorMessage = errorMessage;
    }
}
