<div class="user-overview-page__container">
    <div class="action-bar">
        <div class="action-bar__title">
            <h3>Users</h3>
            <small>{{getRecordCountText()}}</small>
        </div>
        <div class="action-bar__actions">
            <button [ax-button]="'primary'" [routerLink]="'/user'">
                Create
                <i class="fa-light fa-plus"></i>
            </button>
            <button [ax-button]="'primary'" (click)="toggleFilterPanel($event)">
                @if (activeFilterCount !== 0) {
                    <span class="ax-button__label">Filters ({{activeFilterCount}})</span>
                } @else {
                    <span class="ax-button__label">Filters</span>
                }
                <i class="fa-solid fa-filters"></i>
            </button>
        </div>
    </div>
    <ax-table
        [isLoading]="isLoading"
        [columns]="tableColumns"
        [data]="tableData"
        [loaderText]="'Fetching users'"
        [noDataText]="'No users found'"
        [isInfiniteScrollEnabled]="true"
        (sort)="onSort($event)"
        (scrollTrigger)="onScroll()">
    </ax-table>
    <ax-side-panel [orientation]="'right'">
        <div class="ax-filter-panel__container">
            <div class="filter-panel__header">
                <h3>Filters</h3>
                <button [ax-button] [icon-only-small]="true" [transparent]="true" (click)="toggleFilterPanel($event)">
                    <i class="fa-regular fa-xmark"></i>
                </button>
            </div>
            <div class="filter-panel__content">
                <div class="filter-panel__content__headers">
                    <div class="filter-panel__content__headers__header">
                        <span>Property</span>
                    </div>
                    <div class="filter-panel__content__headers__header">
                        <span>Condition</span>
                    </div>
                    <div class="filter-panel__content__headers__header">
                        <span>Value</span>
                    </div>
                </div>
                @for (filter of userFilterDto.filters; track filter.property_name) {
                    <div class="filter-panel__content__filter-row" [ngClass]="$index === userFilterDto.filters.length -1 ? 'filter-panel__content__filter-row--last' : ''">
                        <div class="filter-panel__content__filter-row__input">
                            <ax-select
                                [isInline]="true"
                                [placeholder]="'Select a property'"
                                [options]="getPropertyOptions(filter)"
                                [(value)]="filter.property_name"
                                (valueChange)="setValueType($event, filter)"
                                [noOptionsText]="'No properties available'">
                            </ax-select>
                        </div>
                        <div class="filter-panel__content__filter-row__input" [ngClass]="hasValidPropertyNameSet(filter) ? '' : 'filter-panel__content__filter-row__input--disabled'">
                            <ax-select
                                [isInline]="true"
                                [placeholder]="'Select a condition'"
                                [options]="getConditionOptions(filter)"
                                [(value)]="filter.condition"
                                [noOptionsText]="'No condition available'">
                            </ax-select>
                        </div>
                        <div class="filter-panel__content__filter-row__input" [ngClass]="hasValidConditionSet(filter) ? '' : 'filter-panel__content__filter-row__input--disabled'">
                            @switch (filter.value_type) {
                                @case ('string') {
                                    <input type="text" placeholder="Enter a value" class="ax-inline-input" [(ngModel)]="filter.value">
                                }

                                @case ('boolean') {
                                    <ax-select [isInline]="true" [placeholder]="'Select a state'" [options]="booleanOptions" [(value)]="filter.value"></ax-select>
                                }

                                @case ('date') {
                                     <ax-date-picker [isInline]="true" [placeholder]="'Select a date'" [firstDateValue]="displayDate(filter.value)" (dateSelected)="dateChanged($event, filter)"></ax-date-picker>
                                }

                                @default {
                                    <input type="text" placeholder="Enter a value" class="ax-inline-input" [(ngModel)]="filter.value">
                                }
                            }
                        </div>
                        <div class="filter-panel__content__filter-row__input filter-panel__content__filter-row__input--remove" (click)="removeFitler($event, $index)">
                            <i class="fa-regular fa-trash-can-xmark"></i>
                        </div>
                    </div>
                }
                @empty {
                    <div class="filter-panel__content-no-filters">
                        <h6>No filters are added</h6>
                    </div>
                }
                <div class="filter-panel__content__actions">
                    <button [ax-button]="'primary'" (click)="addFilterRow($event)" [disabled]="userFilterDto.filters.length === propertyOptions.length">
                        Add filter
                        <i class="fa-light fa-plus"></i>
                    </button>
                </div>
            </div>
            <div class="filter-panel__actions">
                <button [ax-button]="'primary'" (click)="applyFilters($event)" [disabled]="!hasValidFilters()">Apply filters</button>
                <button ax-button (click)="reset($event)">Reset</button>
            </div>
        </div>
    </ax-side-panel>
</div>