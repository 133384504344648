export class StateStoreOptions {
    public readonly expirationInMinutes: number;
    public readonly absoluteExpirationInMinutes: number;
    public readonly isSlidingExpirationEnabled: boolean;

    constructor(expirationInMinutes: number, absoluteExpirationInMinutes: number, isSlidingExpirationEnabled: boolean) {
        this.expirationInMinutes = expirationInMinutes;
        this.absoluteExpirationInMinutes = absoluteExpirationInMinutes;
        this.isSlidingExpirationEnabled = isSlidingExpirationEnabled;
    }
}
