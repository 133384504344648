import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { angularImports } from '../../utilities/global-import';
import { InputDirective } from '../../directives/input.directive';
import { ButtonDirective } from '../../directives/button.directive';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import _ from 'lodash';

@Component({
    standalone: true,
    selector: 'ax-search',
    templateUrl: './search.component.html',
    styleUrl: './search.component.scss',
    imports: [angularImports, InputDirective, ButtonDirective],
})
export class SearchComponent {
    @Input() public placeholder: string = '';
    @Input() public value: string = '';
    @Input() public isMainSearch: boolean = false;

    @Output() public valueChange: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('inputElement') private readonly inputElement: ElementRef<HTMLInputElement>;

    private searchValueChanged: Subject<string> = new Subject<string>();

    constructor() {
        this.searchValueChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value: string) => {
            this.valueChange.emit(value);
        });
    }

    setFocus(): void {
        this.inputElement.nativeElement.focus();
    }

    protected setSearchValue(value: string): void {
        this.searchValueChanged.next(value);
    }

    protected hasValue(): boolean {
        return !_.isNil(this.value) && !_.isEmpty(this.value);
    }

    protected clear(event: Event): void {
        event.stopPropagation();
        this.value = '';
        this.setSearchValue('');
    }
}
