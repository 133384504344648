<div class="ax-avatar__container" [ngClass]="getContainerClasses()">
    @if (hasImage()) {
        <img class="ax-avatar__image" [src]="imageUrl" alt="avatar-image" [ngClass]="getImageClasses()">
    } @else {
        <span class="ax-avatar__initials">{{getInitials()}}</span>
    }

    <div class="ax-avatar__edit__container" (click)="editClicked.next()">
        <i class="fa-light fa-pen"></i>
    </div>
</div>