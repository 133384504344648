<div class="confirmation-dialog__container">
    <div class="confirmation-dialog__header">
        <h4>{{title}}</h4>
    </div>
    <div class="confirmation-dialog__content">
        <span [innerHTML]="text"></span>
    </div>
    <div class="confirmation-dialog__footer">
        <button [ax-button]="buttonType" (click)="confirm()">
            <span class="ax-button__label">{{confirmButtonText}}</span>
        </button>
        <button ax-button (click)="cancel()">
            <span class="ax-button__label">{{cancelButtonText}}</span>
        </button>
    </div>
</div>
